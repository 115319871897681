import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Image, Spinner } from "react-bootstrap";
import {
    HiMinusCircle,
    BsListUl,
    FaPen,
    BsGraphUp,
    BiConversation,
    BsPencilSquare,
} from "react-icons/all";
import { connect, useDispatch, useSelector } from "react-redux";
import Toggle from "../../../components/Bookmark";
import { CategoryList, CategoryBody } from "../../../components/Color";
import Moment from "react-moment";
import "moment-timezone";
import "./forum.scss";
import { getAllForum } from "../../../redux/actions/forumAction";
import { getToken, roleId, userPermissions } from "../../../resources/utils";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { deleteApi } from "../../../services/axios";
import swal from "sweetalert";
import { getDetailPermissionById } from "../../../redux/actions/permissionAction";
import { getAllCategory } from "../../../redux/actions/categoryAction";
import {
    createImageFromInitials,
    getRandomColor,
} from "../../../resources/utilities";
import {
    capitalizeFirstLetter,
    CheckAdmin,
    CheckWriteOnly,
} from "../../../resources/helpers";

const Forum = (props) => {
    const id = props.match.params.id;
    const dispatch = useDispatch();
    const forumList = useSelector((state) => state.forum);
    const categoryList = useSelector((state) => state.category);
    const { categories } = categoryList;
    const { forums, forum } = forumList;
    const [loading, setLoading] = useState(true);
    const [userPerm, setUserPermission] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [forumData, setForumData] = useState([]);

    useEffect(() => {
        dispatch(getAllCategory());
        if (id == "all") {
            dispatch(getAllForum());
        } else {
            dispatch(getAllForum(id));
        }
    }, [dispatch]);

    const createForum = () => {
        props.history.push("/lms/forum/create");
    };

    const handleDelete = (id) => {
        swal({
            title: "Apakah anda yakin?",
            text: "Data yang sudah dihapus tidak dapat dikembalikan!",
            icon: "warning",
            buttons: {
                cancel: "Batal",
                danger: {
                    text: "Hapus",
                    value: true,
                },
            },
            dangerMode: true,
        }).then((value) => {
            if (value) {
                const url = `forum/delete/${id}`;
                const token = "Bearer " + getToken();
                const headers = {
                    headers: {
                        Authorization: token,
                    },
                };

                deleteApi(url, headers)
                    .then((res) => {
                        if (res.status !== "error") {
                            dispatch(getAllForum());
                            swal("Data forum berhasil dihapus!", {
                                icon: "success",
                            });
                        } else {
                            swal("Data forum gagal dihapus!", {
                                icon: "error",
                                buttons: { cancel: "Kembali" },
                            });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    function getLatestActivityForum(data) {
        let sortedDescending = data.sort((a, b) => {
            return b.id - a.id;
        });

        return sortedDescending[0].created_at;
    }

    const filter = (id, index) => {
        const filterForum = forums.filter((val) => val.category_id.id === id);
        if (id === "All") {
            setForumData(forums);
        } else {
            setForumData(filterForum);
        }
        setActiveIndex(index);
    };

    useEffect(() => {
        setTimeout(
            function () {
                setLoading(false);
            }.bind(this),
            3000
        );
    }, []);

    useEffect(() => {
        setUserPermission(userPermissions());
        setForumData(forums);
    }, [forums]);

    const columns = [
        {
            name: "Judul",
            cell: (forum) => (
                <>
                    <Link to={"/lms/forum/detail/" + forum.id}>
                        {capitalizeFirstLetter(forum.title)}
                    </Link>
                </>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: "Penulis",
            cell: (forum) =>
                forum.created_by.name ? forum.created_by.name : "-",
            sortable: true,
            reorder: true,
            width: "130px",
        },
        {
            name: "Kategori",
            cell: (forum) =>
                forum.category_id.title ? forum.category_id.title : "-",
            sortable: true,
            reorder: true,
            width: "120px",
        },
        {
            name: "Status",
            cell: (forum) => (
                <>
                    <span className={`u-status ${forum.status}`}>
                        {forum.status === "active" ? "Aktif" : "Tidak Aktif"}
                    </span>
                </>
            ),
            sortable: true,
            width: "120px",
        },
        {
            width: "80px",
            cell: (forum) => (
                <>
                    <span>
                        <Link
                            className="btn-edit"
                            to={"/lms/forum/edit/" + forum.id}
                        >
                            <FaPen />
                        </Link>{" "}
                        |{" "}
                        <a
                            className="btn-delete"
                            onClick={() => handleDelete(forum.id)}
                        >
                            <HiMinusCircle />
                        </a>
                    </span>
                </>
            ),
            right: true,
        },
    ];

    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div className="top-header margin-b30">
                            <div className="title">
                                <BiConversation />
                                <h3>Forum Diskusi</h3>
                            </div>
                            {CheckWriteOnly(userPerm, "access_write_forum") ? (
                                <button
                                    onClick={createForum}
                                    className="btn-transform"
                                >
                                    <span className="btn-icon">
                                        <BsPencilSquare />
                                    </span>

                                    <span className="btn-text">
                                        Tambah Baru
                                    </span>
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={8}>
                        {CheckAdmin(userPerm, "access_admin_forum") ? (
                            <Card className="min-h95p  padding10 margin-b30">
                                {forumData ? (
                                    <DataTable
                                        columns={columns}
                                        data={forumData}
                                        defaultSortFieldId={1}
                                        pagination
                                        paginationRowsPerPageOptions={[
                                            5, 10, 25, 50, 100,
                                        ]}
                                    />
                                ) : loading ? (
                                    <Spinner
                                        animation="border"
                                        className="p-absolute left50"
                                    />
                                ) : (
                                    <span className="p-absolute left50 transform-l50">
                                        Data tidak ditemukan
                                    </span>
                                )}
                            </Card>
                        ) : (
                            <Row>
                                {forumData.length > 0 ? (
                                    forumData
                                        .sort((a, b) => b.id - a.id)
                                        .map((item, index) => (
                                            <Card
                                                key={index}
                                                className="forum-card clickable"
                                                onClick={() => {
                                                    props.history.push(
                                                        "/lms/forum/detail/" +
                                                            item.id
                                                    );
                                                }}
                                            >
                                                <Card.Body className="h100 max-h100 forum-body">
                                                    <div className="card-body-top avainfo">
                                                        <div className="display-flex">
                                                            <Image
                                                                id="preview"
                                                                alt="profile"
                                                                roundedCircle
                                                                className="w50p h50p padding0 object-fit-cover object-position-center relative"
                                                                width="50"
                                                                height="50"
                                                                src={createImageFromInitials(
                                                                    500,
                                                                    item
                                                                        .created_by
                                                                        .name
                                                                        ? item
                                                                              .created_by
                                                                              .name
                                                                        : "Unknown",
                                                                    getRandomColor()
                                                                )}
                                                            />
                                                            <div className="padding-lr5">
                                                                <span className="display-block avaname">
                                                                    {item
                                                                        .created_by
                                                                        .name
                                                                        ? item
                                                                              .created_by
                                                                              .name
                                                                        : "Unknown"}
                                                                </span>
                                                                <span className="avadate">
                                                                    <Moment
                                                                        locale="id"
                                                                        format="dddd, D MMMM YYYY"
                                                                        withTitle
                                                                    >
                                                                        {
                                                                            item.created_at
                                                                        }
                                                                    </Moment>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="forum-title padding-tb10 min-h95p">
                                                        <span className="text-label12">
                                                            Pembahasan
                                                        </span>
                                                        <h6>{item.title}</h6>
                                                    </div>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <span
                                                                className="text-label12"
                                                                style={{
                                                                    color: getRandomColor(),
                                                                }}
                                                            >
                                                                {
                                                                    item
                                                                        .category_id
                                                                        .title
                                                                }
                                                            </span>
                                                        </Col>
                                                        <Col>
                                                            <span className="text-label12">
                                                                Responden
                                                            </span>
                                                            <div className="relative w100 h30p">
                                                                {item.details
                                                                    .data
                                                                    .length > 0
                                                                    ? item.details.data.map(
                                                                          (
                                                                              val,
                                                                              i
                                                                          ) =>
                                                                              i <
                                                                              3 ? (
                                                                                  <Image
                                                                                      key={
                                                                                          i
                                                                                      }
                                                                                      id="preview"
                                                                                      alt="profile"
                                                                                      roundedCircle
                                                                                      className={
                                                                                          i ===
                                                                                          0
                                                                                              ? "z-index3 w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                                                                              : i ===
                                                                                                1
                                                                                              ? "z-index2 left10p w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                                                                              : i ===
                                                                                                2
                                                                                              ? "z-index1 left20p w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                                                                              : "" +
                                                                                                ""
                                                                                      }
                                                                                      width="30"
                                                                                      height="30"
                                                                                      src={createImageFromInitials(
                                                                                          500,
                                                                                          item
                                                                                              .created_by
                                                                                              .name
                                                                                              ? item
                                                                                                    .created_by
                                                                                                    .name
                                                                                              : "Unknown",
                                                                                          getRandomColor()
                                                                                      )}
                                                                                  />
                                                                              ) : (
                                                                                  <span className="p-absolute left50p top50 transform-t50 padding5 font-size12">
                                                                                      +
                                                                                      {item
                                                                                          .details
                                                                                          .data
                                                                                          .length -
                                                                                          3}
                                                                                  </span>
                                                                              )
                                                                      )
                                                                    : "0"}
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <span className="text-label12">
                                                                Aktivitas
                                                            </span>
                                                            <h6 className="font-size12">
                                                                {item.details
                                                                    .data
                                                                    .length >
                                                                0 ? (
                                                                    <Moment
                                                                        locale="id"
                                                                        fromNow
                                                                    >
                                                                        {getLatestActivityForum(
                                                                            item
                                                                                .details
                                                                                .data
                                                                        )}
                                                                    </Moment>
                                                                ) : (
                                                                    <Moment
                                                                        locale="id"
                                                                        fromNow
                                                                    >
                                                                        {
                                                                            item.created_at
                                                                        }
                                                                    </Moment>
                                                                )}
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))
                                ) : loading ? (
                                    <Col
                                        lg={12}
                                        className="learn-col cursor pointer relative"
                                    >
                                        <span className="p-absolute left50 transform-l50">
                                            <Spinner animation="border" />
                                        </span>
                                    </Col>
                                ) : (
                                    <Col
                                        lg={12}
                                        className="learn-col cursor pointer relative"
                                    >
                                        <span className="p-absolute left50 transform-l50">
                                            Data tidak ditemukan
                                        </span>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </Col>
                    <Col xs={12} lg={4}>
                        <aside>
                            <div className="side-block">
                                <div className="category-block margin-b30">
                                    <span className="title-block">
                                        <BsListUl /> Kategori
                                    </span>

                                    <div className="category-item">
                                        <CategoryList
                                            className={activeIndex === 0}
                                            click={() => filter("All", 0)}
                                            name="Semua"
                                        />
                                        {categories
                                            ? categories.map((val, i) => (
                                                  <CategoryList
                                                      className={
                                                          activeIndex === i + 1
                                                      }
                                                      click={() =>
                                                          filter(val.id, i + 1)
                                                      }
                                                      key={i}
                                                      name={val.title}
                                                  />
                                              ))
                                            : ""}
                                    </div>
                                </div>
                                <div className="hr"></div>
                                <div className="top-block margin-t30">
                                    <span className="title-block">
                                        <BsGraphUp /> Forum Terpopuler
                                    </span>
                                    <div className="top-list">
                                        {forums ? (
                                            forums
                                                .slice(0, 5)
                                                .map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="top-item clickable"
                                                        onClick={() => {
                                                            props.history.push(
                                                                "/lms/forum/detail/" +
                                                                    item.id
                                                            );
                                                        }}
                                                    >
                                                        <div className="title">
                                                            {item.title}
                                                        </div>
                                                        <div className="response">
                                                            By{" "}
                                                            {
                                                                item.created_by
                                                                    .name
                                                            }
                                                            {/* <MdModeComment /> 87 */}
                                                        </div>
                                                    </div>
                                                ))
                                        ) : loading ? (
                                            <Col
                                                lg={12}
                                                className="learn-col cursor pointer relative"
                                            >
                                                <span className="p-absolute left50 transform-l50">
                                                    <Spinner animation="border" />
                                                </span>
                                            </Col>
                                        ) : (
                                            <Col
                                                lg={12}
                                                className="learn-col cursor pointer relative"
                                            >
                                                <span className="p-absolute left50 transform-l50">
                                                    Data tidak ditemukan
                                                </span>
                                            </Col>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Forum;
