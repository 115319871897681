import {
    createComment,
    deleteComment,
    getCommentDetail,
    getComments,
    getCommentsByPostId,
} from "../../resources/comment";
import {
    CREATE_COMMENT,
    DELETE_COMMENT,
    ERROR_COMMENT,
    GET_COMMENT,
    GET_COMMENT_CHILD,
    GET_COMMENT_DETAIL,
} from "../types";

export const getAllComment = () => async (dispatch) => {
    try {
        const res = await getComments();
        dispatch({
            type: GET_COMMENT,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_COMMENT,
            payload: console.log(e),
        });
    }
};

export const getCommentByPost = (id) => async (dispatch) => {
    try {
        const res = await getCommentsByPostId(id);
        dispatch({
            type: GET_COMMENT,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_COMMENT,
            payload: console.log(e),
        });
    }
};
export const getCommentChild = (id) => async (dispatch) => {
    try {
        const res = await getCommentsByPostId(id);
        dispatch({
            type: GET_COMMENT_CHILD,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_COMMENT,
            payload: console.log(e),
        });
    }
};
export const getCommentByID = (id) => async (dispatch) => {
    try {
        const res = await getCommentDetail(id);
        dispatch({
            type: GET_COMMENT_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_COMMENT,
            payload: console.log(e),
        });
    }
};

export const addComment = (data) => async (dispatch) => {
    try {
        const res = await createComment(data);
        dispatch({
            type: CREATE_COMMENT,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_COMMENT,
            payload: false,
        });
    }
};

export const deleteItem = (id) => async (dispatch) => {
    const res = await deleteComment(id);
    dispatch({
        type: DELETE_COMMENT,
        payload: res,
    });
};

export const clearComment = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_COMMENT_DETAIL,
            payload: false,
        });
    } catch (e) {
        dispatch({
            type: ERROR_COMMENT,
            payload: console.log(e),
        });
    }
};
