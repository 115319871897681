import React, {
  Component,
  isValidElement,
  useEffect,
  useRef,
  useState
} from "react";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import { BiChevronLeft, BiChevronRight, BiUser } from "react-icons/bi";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import { CompareDateNow, FormatDate } from "../resources/helpers";
import {
  createImageFromInitials,
  getRandomColor
} from "../resources/utilities";

const Content = ({ data, type, sort, count, props }) => {
  const history = useHistory();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);

  function getLatestActivityForum(data) {
    let sortedDescending = data.sort((a, b) => {
      return b.id - a.id;
    });

    return sortedDescending[0].created_at;
  }

  const { onMouseDown } = useDraggableScroll(ref, {
    direction: "horizontal"
  });
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  useEffect(() => {
    setTimeout(
      function () {
        setLoading(false);
      }.bind(this),
      2000
    );
  }, []);
  return (
    <>
      <div className='item-slide' ref={ref} onMouseDown={onMouseDown}>
        {data ? (
          data.length > 0 ? (
            type === "course" ? (
              data.slice(0, count).map((val, i) => (
                <Col
                  key={i}
                  md={6}
                  lg={4}
                  className='learn-col'
                  onClick={() => {
                    props.history.push("/lms/learn/detail/" + val.id);
                  }}>
                  <Card className='learn-card'>
                    {val.image !== "" ? (
                      <Card.Img
                        className='learn-card-image'
                        variant='top'
                        src={val.image}
                      />
                    ) : (
                      <Card.Img
                        className='learn-card-image'
                        variant='top'
                        src={
                          require(`../assets/images/courses${i + 1}.svg`)
                            .default
                        }
                      />
                    )}
                    <Card.Body>
                      <div className='card-body-top'>
                        {"Author: " + val.created_by.name}
                      </div>
                      <div className='card-body-title'>
                        {val.title.length > 15
                          ? val.title.substring(0, 40) + "..."
                          : val.title}
                        <br />
                      </div>
                      <div className='card-body-desc'>
                        {CompareDateNow(val.closed_date) ? (
                          <span className={`u-status active`}>Open</span>
                        ) : (
                          <span className={`u-status not-active`}>Closed</span>
                        )}
                      </div>
                      <div className='card-body-bottom'>
                        <div className='user'>
                          <BiUser />
                          <span>4</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : type === "forum" ? (
              data.slice(0, count).map((val, i) => (
                <Col key={i} md={6} lg={6} className='learn-col'>
                  <Card
                    className='learn-card clickable'
                    onClick={() => {
                      history.push("/lms/forum/detail/" + val.id);
                    }}>
                    <Card.Body className='h100 max-h100'>
                      <div className='card-body-top'>
                        <div className='display-flex'>
                          <Image
                            src={createImageFromInitials(
                              500,
                              val.created_by.name
                                ? val.created_by.name
                                : "Unknown",
                              getRandomColor()
                            )}
                            alt='profile'
                            roundedCircle
                            className='w50p h50p padding0 object-fit-cover object-position-center relative'
                            width='50'
                            height='50'
                          />
                          <div className='padding5'>
                            <span className='display-block'>
                              {val.created_by.name
                                ? val.created_by.name
                                : "Unknown"}
                            </span>
                            <span>
                              <Moment
                                locale='id'
                                format='dddd, D MMMM YYYY'
                                withTitle>
                                {val.created_at}
                              </Moment>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='card-body-title padding-tb10 min-h95p'>
                        <span className='text-label12'>Pembahasan</span>
                        <h6>
                          {val.title.length > 40
                            ? val.title.substring(0, 40)
                            : val.title}
                        </h6>
                      </div>
                      <Row>
                        <Col sm={12}>
                          <span
                            className='text-label12'
                            style={{
                              color: getRandomColor()
                            }}>
                            {val.category_id.title}
                          </span>
                        </Col>
                        <Col>
                          <span className='text-label12'>Responden</span>
                          <div className='relative w100 h30p'>
                            {val.details.data.length > 0
                              ? val.details.data.map((item, i) =>
                                  i < 3 ? (
                                    <Image
                                      key={i}
                                      id='preview'
                                      alt='profile'
                                      roundedCircle
                                      className={
                                        i === 0
                                          ? "z-index3 w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                          : i === 1
                                          ? "z-index2 left10p w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                          : i === 2
                                          ? "z-index1 left20p w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                          : "" + ""
                                      }
                                      width='30'
                                      height='30'
                                      src={createImageFromInitials(
                                        500,
                                        item.created_by.name
                                          ? item.created_by.name
                                          : "Unknown",
                                        getRandomColor()
                                      )}
                                    />
                                  ) : (
                                    <span className='p-absolute left50p top50 transform-t50 padding5 font-size12'>
                                      +{val.details.data.length - 3}
                                    </span>
                                  )
                                )
                              : "0"}
                          </div>
                        </Col>
                        <Col>
                          <span className='text-label12'>Aktivitas</span>
                          <h6 className='font-size12'>
                            {val.details.data.length > 0 ? (
                              <Moment locale='id' fromNow>
                                {getLatestActivityForum(val.details.data)}
                              </Moment>
                            ) : (
                              <Moment locale='id' fromNow>
                                {val.created_at}
                              </Moment>
                            )}
                          </h6>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              data.slice(0, count).map((val, i) => (
                <Col key={i} md={6} lg={6} className='learn-col'>
                  <Card
                    className='learn-card clickable'
                    onClick={() => {
                      history.push("/lms/forum/detail/" + val.id);
                    }}>
                    <Card.Body className='h100 max-h100'>
                      <div className='card-body-top'>
                        <div className='display-flex'>
                          <Image
                            src={createImageFromInitials(
                              100,
                              val.created_by.name,
                              getRandomColor()
                            )}
                            alt='profile'
                            roundedCircle
                            className='w50p h50p padding0 object-fit-cover object-position-center relative'
                            width='50'
                            height='50'
                          />
                          <div className='padding5'>
                            <span className='display-block'>
                              {val.created_by.name}
                            </span>
                            <span>{FormatDate(val.created_at, "/", 2)}</span>
                          </div>
                        </div>
                      </div>
                      <div className='card-body-title padding-tb10 min-h95p'>
                        <span className='text-label12'>Pembahasan</span>
                        <h6>
                          {val.title.length > 40
                            ? val.title.substring(0, 40)
                            : val.title}
                        </h6>
                      </div>
                      <Row>
                        <Col sm={12}>
                          <span
                            className='text-label12'
                            style={{
                              color: getRandomColor()
                            }}>
                            {val.category_id.title}
                          </span>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )
          ) : loading ? (
            <Spinner animation='border' className='p-absolute left50' />
          ) : (
            <Col>
              Belum memiliki{" "}
              {type === "course" ? "kursus" : type === "forum" ? "diskusi" : ""}
            </Col>
          )
        ) : (
          ""
        )}
      </div>
      <div className='btn-group right'>
        <button className='btn-circle' onClick={() => scroll(-100)}>
          <BiChevronLeft className='font-size35r' />
        </button>
        <button className='btn-circle' onClick={() => scroll(+100)}>
          <BiChevronRight className='font-size35r' />
        </button>
      </div>
    </>
  );
};
export default Content;
