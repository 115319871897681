import React, { useState } from "react";
import {
    Col,
    Container,
    Row,
    Image,
    Card,
    ListGroup,
    Spinner,
} from "react-bootstrap";
import {
    FaEdit,
    BiUser,
    FaPen,
    BiUserCircle,
    BsClockHistory,
    BsHeartFill,
} from "react-icons/all";
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userDetail } from "../../../redux/actions/userActions";
import {
    getRandomColor,
    createImageFromInitials,
} from "../../../resources/utilities";
import { roleId, userId, userPermissions } from "../../../resources/utils";
import { getDetailPermissionById } from "../../../redux/actions/permissionAction";
import { getAllForum } from "../../../redux/actions/forumAction";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import {
    capitalizeFirstLetter,
    CheckReadWrite,
    CheckWriteOnly,
    CompareDateNow,
    FormatDate,
} from "../../../resources/helpers";
import { getLogUser } from "../../../redux/actions/logAction";
import Content from "../../../components/Content";

const Profile = (props) => {
    const dispatch = useDispatch();
    const getUserDetail = useSelector((state) => state.users);
    const { user, userCourses, userForums, joinCourse, joinForum, wishlist } =
        getUserDetail;
    const permissionList = useSelector((state) => state.permissions);
    const forumList = useSelector((state) => state.forum);
    const { forums } = forumList;
    const logList = useSelector((state) => state.log);
    const { logs } = logList;
    const { permissionByRole } = permissionList;
    const [courses, setCourses] = useState([]);
    const [joinCourses, setJoinCourse] = useState([]);
    const [forum, setForum] = useState([]);
    const [joinForums, setJoinForum] = useState([]);
    const [wishlists, setWishlist] = useState([]);
    const [isUser, setIsUser] = useState(false);
    const [userPerm, setUserPermission] = useState([]);

    const allCourse = () => {
        props.history.push("/lms/profile/activity/course");
    };

    const allForum = () => {
        props.history.push("/lms/profile/activity/forum");
    };
    const joinedForum = () => {
        props.history.push("/lms/profile/activity/joinedforum");
    };

    const goCourse = () => {
        props.history.push("/lms/learn/create");
    };

    const goForum = () => {
        props.history.push("/lms/forum/create");
    };
    const goWishlist = () => {
        props.history.push("/lms/profile/activity/wishlist");
    };

    const goEditProfile = () => {
        props.history.push("/lms/user/edit/" + userId());
    };

    const handleClick = () => {};

    function getLatestActivityForum(data) {
        let sortedDescending = data.sort((a, b) => {
            return b.id - a.id;
        });

        return sortedDescending[0].created_at;
    }

    useEffect(() => {
        dispatch(userDetail(userId()));
        dispatch(getDetailPermissionById(roleId()));
        dispatch(getAllForum());
        dispatch(getLogUser());
    }, [dispatch]);

    useEffect(() => {
        setCourses(userCourses);
        setJoinCourse(joinCourse);
        setForum(userForums);
        setJoinForum(joinForum);
        setWishlist(wishlist);
        setUserPermission(userPermissions());
        console.log(joinCourse);
    }, [userCourses, userForums, joinCourse, joinForum, wishlist]);

    return (
        <div className="profile">
            <Container fluid>
                <div className="margin-t30 title margin-b20">
                    <BiUserCircle />
                    <h3>Profil</h3>
                </div>
                <div className="profile-header">
                    <Row>
                        <Col lg={8} xs={12}>
                            <Card className="padding10 margin-b30">
                                {user ? (
                                    <Row>
                                        <Col lg={5} xs={12}>
                                            <div className="img-circle-container">
                                                <img
                                                    id="preview"
                                                    src={createImageFromInitials(
                                                        500,
                                                        user.name,
                                                        getRandomColor()
                                                    )}
                                                    alt="profile-pic"
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={7}
                                            xs={12}
                                            className="relative"
                                        >
                                            <div className="p-detail display-flex center-horizontal center-vertical w100 h100">
                                                <div className="w100 profile">
                                                    <div className="title">
                                                        <h3>{user.name}</h3>
                                                        <span
                                                            className={
                                                                user.role_id
                                                                    ? "label" +
                                                                      user.role_id
                                                                    : "label3"
                                                            }
                                                        >
                                                            {user.role_name}
                                                        </span>
                                                    </div>
                                                    <p>{user.email}</p>
                                                    <div className="">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <h5 className="font-size25r">
                                                                    {user
                                                                        .join_course
                                                                        .data
                                                                        ? user
                                                                              .join_course
                                                                              .data
                                                                              .length
                                                                        : 0}
                                                                </h5>
                                                                <span>
                                                                    Kursus
                                                                </span>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <h5 className="font-size25r">
                                                                    {user
                                                                        .join_forum
                                                                        .data
                                                                        ? user
                                                                              .join_forum
                                                                              .data
                                                                              .length
                                                                        : 0}
                                                                </h5>
                                                                <span>
                                                                    Diskusi
                                                                </span>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <h5 className="font-size25r">
                                                                    0
                                                                </h5>
                                                                <span>
                                                                    {" "}
                                                                    Download
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}
                                <div className="p-body margin-b30">
                                    <div className="border-r30 card-course padding30">
                                        <div className="card-title margin-b20 display-flex space-between">
                                            <h5>
                                                {CheckReadWrite(
                                                    userPerm,
                                                    "access_read_course",
                                                    "access_write_course"
                                                )
                                                    ? "Kursus Saya"
                                                    : "Kursus yang diikuti"}
                                            </h5>
                                            <a onClick={allCourse}>
                                                Lihat Semua
                                            </a>
                                        </div>
                                        <div className="card-body padding0">
                                            {CheckReadWrite(
                                                userPerm,
                                                "access_read_course",
                                                "access_write_course"
                                            ) ? (
                                                <Content
                                                    count="4"
                                                    data={courses}
                                                    type="course"
                                                    props={props}
                                                />
                                            ) : (
                                                <Content
                                                    count="4"
                                                    data={joinCourses}
                                                    type="course"
                                                    props={props}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-body margin-b30">
                                    <div className="border-r30 card-forum padding30">
                                        <div className="card-title display-flex space-between margin-b20">
                                            <h5>Diskusi Saya</h5>
                                            <a onClick={allForum}>
                                                Lihat Semua
                                            </a>
                                        </div>
                                        <Row>
                                            <Content
                                                data={forum}
                                                type="forum"
                                                count="8"
                                                sort={getLatestActivityForum}
                                            />
                                        </Row>
                                    </div>
                                </div>
                                <div className="p-body margin-b30">
                                    <div className="border-r30 card-forum padding30">
                                        <div className="card-title display-flex space-between margin-b20">
                                            <h5>Diskusi yang diikuti</h5>
                                            <a onClick={joinedForum}>
                                                Lihat Semua
                                            </a>
                                        </div>
                                        <Row>
                                            <Content
                                                data={joinForums}
                                                type="forum"
                                                sort={getLatestActivityForum}
                                                count="8"
                                            />
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={4} xs={12}>
                            <div className="side-block">
                                <div className="margin-b30 button-group">
                                    {CheckWriteOnly(
                                        userPermissions(),
                                        "access_user"
                                    ) ? (
                                        ""
                                    ) : (
                                        <button
                                            onClick={goEditProfile}
                                            className="btn-secondary"
                                        >
                                            <FaPen /> Ubah Data Profil
                                        </button>
                                    )}
                                    {userPerm.map((data, i) =>
                                        data === "access_write_forum" ? (
                                            <button
                                                key={i}
                                                onClick={goForum}
                                                className="btn-secondary"
                                            >
                                                <FaEdit /> Buka Diskusi Baru
                                            </button>
                                        ) : data === "access_write_course" ? (
                                            <button
                                                onClick={goCourse}
                                                className="btn-secondary primary"
                                            >
                                                <FaEdit /> Buka Kursus Baru
                                            </button>
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>
                                <div className="margin-t30 card-title border-r20">
                                    <span className="title-block">
                                        <BsHeartFill /> Kursus yang disimpan
                                    </span>
                                    <ListGroup>
                                        {wishlists.length > 0 ? (
                                            wishlists
                                                .slice(0, 3)
                                                .map((v, i) => (
                                                    <ListGroup.Item key={i}>
                                                        <span className="padding-lr10">
                                                            {capitalizeFirstLetter(
                                                                v.title.length >
                                                                    40
                                                                    ? v.title.substring(
                                                                          0,
                                                                          40
                                                                      )
                                                                    : v.title
                                                            )}
                                                        </span>
                                                    </ListGroup.Item>
                                                ))
                                        ) : (
                                            <ListGroup.Item>
                                                Belum mengikuti diskusi
                                            </ListGroup.Item>
                                        )}
                                        <button
                                            onClick={goWishlist}
                                            className="margin-t10 btn-clear btn"
                                        >
                                            Lihat Semua
                                        </button>
                                    </ListGroup>
                                </div>
                                <Link to={"/lms/log"}>
                                    <div className="margin-t30 card-title border-r20">
                                        <span className="title-block">
                                            <BsClockHistory /> Aktivitas
                                        </span>
                                    </div>
                                    <ListGroup>
                                        {logs ? (
                                            logs.slice(0, 5).map((val, i) => (
                                                <ListGroup.Item key={i}>
                                                    <div className="padding-lr10">
                                                        <span className="text-label12">
                                                            <Moment
                                                                locale="id"
                                                                fromNow
                                                            >
                                                                {val.created_at}
                                                            </Moment>
                                                        </span>
                                                        <br />
                                                        {val.value}
                                                    </div>
                                                </ListGroup.Item>
                                            ))
                                        ) : (
                                            <Spinner
                                                animation="border"
                                                className="p-absolute left50"
                                            />
                                        )}
                                    </ListGroup>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-footer margin-b30"></div>
            </Container>
        </div>
    );
};

export default Profile;
