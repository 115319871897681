import React from "react";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import "./contact.scss";
import {
    RiPhoneFill,
    IoMail,
    SiGooglemessages,
    IoPerson,
    FiMapPin,
    BiPhone,
    FiMail,
    AiOutlineLoading3Quarters,
} from "react-icons/all";
import { useState } from "react";
import { postApi } from "../../resources/utilities";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getContactSettings } from "../../redux/actions/settingsAction";

const Contact = () => {
    const dispatch = useDispatch();
    const settingList = useSelector((state) => state.settings);
    const { contact } = settingList;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorName, setErrorName] = useState("");
    const [name, setName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [email, setEmail] = useState("");
    const [errorPhoneNo, setErrorPhoneNo] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const sendEmail = () => {
        var url = `front/email/send`;

        const data = {
            name: name,
            email: email,
            phoneNo: phoneNo,
            message: message,
        };

        if (!validation()) {
            setLoading(true);
            postApi(url, data, []).then((data) => {
                setLoading(false);
                reset();
                setSuccess(true);
            });
        }

        setTimeout(() => setSuccess(false), 3000);
    };

    const reset = () => {
        setName("");
        setEmail("");
        setPhoneNo("");
        setMessage("");
    };

    const validation = () => {
        if (name === "") {
            setErrorName("Nama harus diisi.");
        }
        if (email === "") {
            setErrorEmail("Email harus diisi.");
        }
        if (phoneNo === "") {
            setErrorPhoneNo("No Telepon harus diisi.");
        }
        if (message === "") {
            setErrorMessage("Pesan harus diisi.");
        }

        if (
            errorName !== "" ||
            errorEmail !== "" ||
            errorPhoneNo !== "" ||
            errorMessage !== ""
        ) {
            return true;
        }

        return false;
    };

    function getFormattedPhoneNum(input) {
        let output = "(";
        let codeChecker = input.charAt(2);

        if (codeChecker !== "8") {
            input.replace(
                /^\D*(\d{0,4})\D*(\d{0,3})\D*(\d{0,5})/,
                function (match, g1, g2, g3) {
                    if (g1.charAt(0) === "6") {
                        var sub = g1.substring(2, g1.length);
                        g1 = "0" + sub;
                        if (g1.length) {
                            output += g1;
                            if (g1.length === 3) {
                                output += ")";
                                if (g2.length) {
                                    output += " " + g2;
                                    if (g2.length === 3) {
                                        output += " - ";
                                        if (g3.length) {
                                            output += g3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            );
        }
        return output;
    }

    useEffect(() => {
        dispatch(getContactSettings());
    }, [dispatch]);

    useEffect(() => {
        setData(contact);
    }, [contact]);

    return (
        <>
            <Container className="padding-tb30">
                <h1 className="text-center">
                    {data
                        ? data.map((val, i) =>
                              val.key === "title" ? val.value : ""
                          )
                        : ""}
                </h1>
                <p className="text-center">
                    {data
                        ? data.map((val, i) =>
                              val.key === "contact-description" ? val.value : ""
                          )
                        : ""}
                </p>
            </Container>
            <Container>
                <Row className="padding-tb30">
                    <Col sm={12} md={4} className="text-center">
                        <div className="relative">
                            {data
                                ? data.map((val, i) =>
                                      val.key === "address-url" ? (
                                          <a
                                              key={i}
                                              href={val.value}
                                              target="_blank"
                                              rel="noreferrer"
                                          >
                                              <span className="icon-top font-size30">
                                                  <FiMapPin className="map-animation" />
                                              </span>
                                              {data.map((val, i) =>
                                                  val.key === "address"
                                                      ? val.value
                                                      : ""
                                              )}
                                          </a>
                                      ) : (
                                          ""
                                      )
                                  )
                                : ""}
                        </div>
                    </Col>
                    <Col sm={12} md={4} className="text-center">
                        {data
                            ? data.map((val, i) =>
                                  val.key === "phone" ? (
                                      <a key={i} href={"tel:" + val.value}>
                                          <span className="icon-top font-size30">
                                              <BiPhone className="phone-animation" />
                                          </span>
                                          {getFormattedPhoneNum(val.value)}
                                      </a>
                                  ) : (
                                      ""
                                  )
                              )
                            : ""}
                    </Col>
                    <Col sm={12} md={4} className="text-center">
                        {data
                            ? data.map((val, i) =>
                                  val.key === "email" ? (
                                      <a key={i} href={"mailto:" + val.value}>
                                          <span className="icon-top font-size30">
                                              <FiMail className="mail-animation" />
                                          </span>
                                          {val.value}
                                      </a>
                                  ) : (
                                      ""
                                  )
                              )
                            : ""}
                    </Col>
                </Row>
            </Container>
            <div className="relative w100 margin-t30">
                <div className="contact-maps sm">
                    {data
                        ? data.map((val, i) =>
                              val.key === "map" ? (
                                  <div
                                      key={i}
                                      dangerouslySetInnerHTML={{
                                          __html: val.value,
                                      }}
                                  />
                              ) : (
                                  ""
                              )
                          )
                        : ""}
                </div>
            </div>
            <Container className="margin-b50 margin-t30">
                <div className="relative w100">
                    <div className="contact-maps lg">
                        {data
                            ? data.map((val, i) =>
                                  val.key === "map" ? (
                                      <div
                                          key={i}
                                          dangerouslySetInnerHTML={{
                                              __html: val.value,
                                          }}
                                      />
                                  ) : (
                                      ""
                                  )
                              )
                            : ""}
                    </div>
                    <div className="">
                        <Card className="padding-tb30">
                            <Form onSubmit={sendEmail}>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="text"
                                                className="input-form"
                                                placeholder="John Doe"
                                                name="name"
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrorName("");
                                                    setSuccess(false);
                                                }}
                                                value={name}
                                            />
                                            <label className="input-label">
                                                Nama
                                            </label>
                                            <IoPerson className="icon-btnl left" />
                                        </div>
                                    </div>
                                    {errorName && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{errorName}
                                            </Form.Text>
                                        </>
                                    )}
                                </div>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="input-form"
                                                placeholder="email@mail.id"
                                                name="email"
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrorEmail("");
                                                    setSuccess(false);
                                                }}
                                                value={email}
                                            />
                                            <label className="input-label">
                                                Email
                                            </label>
                                            <IoMail className="icon-btnl left" />
                                        </div>
                                    </div>
                                    {errorEmail && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{errorEmail}
                                            </Form.Text>
                                        </>
                                    )}
                                </div>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="tel"
                                                autoComplete="off"
                                                className="input-form input-phone"
                                                placeholder="081290900001"
                                                name="phoneNo"
                                                onChange={(e) => {
                                                    setPhoneNo(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrorPhoneNo("");
                                                    setSuccess(false);
                                                }}
                                                value={phoneNo}
                                            />
                                            <label className="input-label">
                                                No Telepon
                                            </label>
                                            <RiPhoneFill className="icon-btnl left" />
                                        </div>
                                    </div>
                                    {errorPhoneNo && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{errorPhoneNo}
                                            </Form.Text>
                                        </>
                                    )}
                                </div>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <textarea
                                                className="input-form input-textarea"
                                                placeholder="Ketik pesan anda disini"
                                                rows="4"
                                                name="message"
                                                onChange={(e) => {
                                                    setMessage(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrorMessage("");
                                                    setSuccess(false);
                                                }}
                                                value={message}
                                            />
                                            <label className="input-label">
                                                Pesan
                                            </label>
                                            <SiGooglemessages className="icon-btnl left textarea" />
                                        </div>
                                    </div>
                                    {errorMessage && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{errorMessage}
                                            </Form.Text>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <a
                                        onClick={sendEmail}
                                        className="btn-primary-lg btn-w100 text-center"
                                    >
                                        {loading ? (
                                            <div>
                                                <AiOutlineLoading3Quarters className="loading-spin" />
                                            </div>
                                        ) : (
                                            "Kirim"
                                        )}
                                    </a>
                                </div>
                                {success && (
                                    <div className="p-absolute left50 transform-l50">
                                        <Form.Text className="text-success">
                                            Pesan anda telah dikirim.
                                        </Form.Text>
                                    </div>
                                )}
                            </Form>
                        </Card>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Contact;
