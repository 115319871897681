import {
    GET_BERANDA_SETTINGS,
    GET_CONTACT_SETTINGS,
    GET_GENERAL_SETTINGS,
    GET_PROGRAM_SETTINGS,
    GET_SETTINGS,
    GET_TENTANG_SETTINGS,
} from "../types";

const initialState = {
    all: false,
    general: false,
    beranda: false,
    tentang: false,
    program: false,
    contact: false,
    loading: false,
};

export const settingsReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SETTINGS:
            return {
                ...state,
                all: payload,
            };
        case GET_GENERAL_SETTINGS:
            return {
                ...state,
                general: payload,
            };
        case GET_BERANDA_SETTINGS:
            return {
                ...state,
                beranda: payload,
            };
        case GET_TENTANG_SETTINGS:
            return {
                ...state,
                tentang: payload,
            };
        case GET_PROGRAM_SETTINGS:
            return {
                ...state,
                program: payload,
            };
        case GET_CONTACT_SETTINGS:
            return {
                ...state,
                contact: payload,
            };
        default:
            return state;
    }
};
