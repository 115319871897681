import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BiChevronLeft, BsPlus } from "react-icons/all";
import { connect } from "react-redux";
import { getToken, userPermissions } from "../../../resources/utils";
import { postApi } from "../../../services/axios";
import { getAllRoles } from "../../../redux/actions/roleAction";
import "./user.scss";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../../redux/actions/userActions";
import swal from "sweetalert";
import { createUser } from "../../../resources/user";
import { CREATE_USER, USERS_ERROR } from "../../../redux/types";
import { Link } from "react-router-dom";
import { CheckWriteOnly } from "../../../resources/helpers";

const AddUser = (props) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [error, setError] = useState("");
    const [isUser, setIsUser] = useState(false);
    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorRole, setErrorRole] = useState("");
    const getRoles = useSelector((state) => state.roles);
    const { roles } = getRoles;

    const getResponse = useSelector((state) => state.users);
    const { responseUser } = getResponse;

    const goBack = () => {
        props.history.goBack();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);

        try {
            const res = await createUser(data);

            dispatch({
                type: CREATE_USER,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                if (res.status_code === 422) {
                    if (res.data.name) {
                        setErrorName("Nama harus diisi.");
                    }

                    if (res.data.email) {
                        setErrorEmail("Email harus diisi.");
                    }

                    if (res.data.role_id) {
                        setErrorRole("Peran harus dipilih.");
                    }
                } else {
                    setError(
                        "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                    );
                }
            } else {
                swal({
                    title: "Sukses!",
                    text: "Data pengguna sudah disimpan!",
                    icon: "success",
                    button: "Kembali!",
                }).then(() => {
                    props.history.goBack();
                });
            }
        } catch (e) {
            dispatch({
                type: USERS_ERROR,
                payload: false,
            });
        }
    };

    useEffect(() => {
        dispatch(getAllRoles());
    }, [dispatch]);

    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsPlus />
                        <h3>Tambah Pengguna</h3>
                    </div>
                </div>
            </Container>
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <br />
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Masukkan nama..."
                                        />
                                        {errorName && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorName}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    Username
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="username"
                                                    placeholder="Masukkan username..."
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>
                                                    Kata Sandi
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    placeholder="Masukkan kata sandi..."
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    No Handphone
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Masukkan no handphone..."
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    placeholder="Masukkan email..."
                                                />
                                                {errorEmail && (
                                                    <>
                                                        <Form.Text className="text-alert">
                                                            *{errorEmail}
                                                        </Form.Text>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <hr className="tiny" />
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Asal Sekolah</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="asal_sekolah"
                                            placeholder="Masukkan asal sekolah..."
                                        />
                                        {errorName && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorName}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Mata Pelajaran</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="mata_pelajaran"
                                            placeholder="Masukkan mata pelajaran yang diampu..."
                                        />
                                        {errorName && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorName}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Kelas</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="kelas"
                                            placeholder="Masukkan kelas..."
                                        />
                                        {errorName && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorName}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                        </>
                                    )}
                                    <br />

                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>

                                    <Link
                                        to={"/lms/user"}
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Peran</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            defaultValue="Pilih..."
                                            name="role_id"
                                        >
                                            <option disabled>Pilih...</option>
                                            {roles
                                                ? roles.map((roles) => (
                                                      <option value={roles.id}>
                                                          {roles.title}
                                                      </option>
                                                  ))
                                                : ""}
                                        </Form.Select>
                                        {errorRole && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorRole}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            defaultValue="Pilih..."
                                            name="status"
                                        >
                                            <option disabled>Pilih...</option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default AddUser;
