import axios from "axios";

export function convertSlugToUrl(slug, parameters) {
    let url = slug;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });
    return url;
}

var baseURL = "https://api.kelaslenterakuark.id/api/v1/";

export const getAPI = (url) => {
    return axios
        .get(baseURL + url)
        .then((res) => {
            const data = res.data;
            return data;
        })
        .catch((err) => console.log(err));
};

export const postApi = (url, data, headers) => {
    return axios
        .post(baseURL + url, data, headers)
        .then((res) => {
            const data = res.data;
            return data;
        })
        .catch((err) => console.log(err));
};

export const putApi = (url, data, headers) => {
    return axios
        .put(baseURL + url, data, headers)
        .then((res) => {
            const data = res.data;
            return data;
        })
        .catch((err) => console.log(err));
};

// image random
export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    name = getInitials(name);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = canvas.height = size;

    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, size, size);

    context.fillStyle = `${color}50`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = color;
    context.textBaseline = "middle";
    context.textAlign = "center";
    context.font = `${size / 2}px Poppins`;
    context.fillText(name, size / 2, size / 2);

    return canvas.toDataURL();
};

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};

export const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
