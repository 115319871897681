import {
    CREATE_USER,
    GET_USERS,
    GET_USERS_DETAIL,
    REMOVE_SELECTED_USERS,
    GET_USERS_PROFILE,
    USERS_ERROR,
    UPDATE_USER,
    GET_UNVERIFIED_USER,
} from "../types";
import {
    createUser,
    deleteUser,
    getUnverifiedUser,
    getUserDetail,
    getUserProfile,
    getUsers,
    updateUser,
} from "../../resources/user";
import { push } from "react-router-redux";

export const getAllUsers = () => async (dispatch) => {
    try {
        const res = await getUsers();
        dispatch({
            type: GET_USERS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getUnverifiedCount = () => async (dispatch) => {
    try {
        const res = await getUnverifiedUser();
        console.log(res.total);
        dispatch({
            type: GET_UNVERIFIED_USER,
            payload: res.total,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getProfile = () => async (dispatch) => {
    try {
        const res = await getUserProfile();
        dispatch({
            type: GET_USERS_PROFILE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: console.log(e),
        });
    }
};

export const userDetail = (id) => async (dispatch) => {
    try {
        const res = await getUserDetail(id);
        await dispatch({
            type: GET_USERS_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: console.log(e),
        });
    }
};

export const removeUser = (id) => async (dispatch) => {
    try {
        const res = await deleteUser(id);
        dispatch({
            type: REMOVE_SELECTED_USERS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: console.log(e),
        });
    }
};

export const addUser = (data) => async (dispatch) => {
    try {
        const res = await createUser(data);
        dispatch({
            type: CREATE_USER,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: false,
        });
    }
};

export const editUser = (data, id) => async (dispatch) => {
    try {
        const res = await updateUser(data, id);
        dispatch({
            type: UPDATE_USER,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: false,
        });
    }
};

export const clearUser = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_USERS_DETAIL,
            payload: false,
        });

        dispatch({
            type: UPDATE_USER,
            payload: false,
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: console.log(e),
        });
    }
};
