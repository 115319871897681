import {
    GET_PERMISSION,
    GET_PERMISSION_DETAIL,
    GET_PERMISSION_DETAIL_ROLE_ID,
    GET_PERMISSION_GROUP,
} from "../types";

const initialState = {
    permissions: false,
    group: false,
    permission: false,
    permissionByRole: false,
    loading: true,
};

export const permissionReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PERMISSION:
            return {
                ...state,
                permissions: payload,
                loading: false,
            };
        case GET_PERMISSION_GROUP:
            return {
                ...state,
                group: payload,
                loading: false,
            };
        case GET_PERMISSION_DETAIL:
            return {
                ...state,
                permission: payload,
                loading: false,
            };
        case GET_PERMISSION_DETAIL_ROLE_ID:
            return {
                ...state,
                permissionByRole: payload,
                loading: false,
            };
        default:
            return state;
    }
};
