import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BiChevronLeft, BsPlus } from "react-icons/all";
import Tags from "../../../components/Tags";
import { updateForum } from "../../../resources/forum";
import { getToken } from "../../../resources/utils";
import "./forum.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
    EDIT_FORUM,
    FORUM_ERROR,
    GET_FORUM_DETAIL,
} from "../../../redux/types";
import { getForumsID } from "../../../redux/actions/forumAction";
import { TextEditor } from "../../../components/TextEditor";
import { Link } from "react-router-dom";
import { getAllCategory } from "../../../redux/actions/categoryAction";

const EditForum = (props) => {
    const dispatch = useDispatch();
    const forumDetail = useSelector((state) => state.forum);
    const { forum } = forumDetail;
    const categoryList = useSelector((state) => state.category);
    const { categories } = categoryList;
    const [forumData, setForumData] = useState([]);
    const [desc, setDesc] = useState("");
    const [error, setError] = useState("");
    const [errorStatus, setErrorStatus] = useState("");
    const [errorCategory, setErrorCategory] = useState(null);
    const [cate, setCate] = useState([]);

    const handleChange = (event) => {
        setErrorCategory(null);
        setForumData({ ...forumData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            title: forumData.title,
            description: desc,
            status: forumData.status,
            category_id: forumData.category_id,
        };

        try {
            const res = await updateForum(data, props.match.params.id);

            dispatch({
                type: EDIT_FORUM,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                setError(
                    "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                );
            } else {
                swal({
                    title: "Sukses!",
                    text: "Data forum berhasil diubah!",
                    icon: "success",
                    button: "Kembali!",
                }).then(() => {
                    dispatch({
                        type: GET_FORUM_DETAIL,
                        payload: false,
                    });
                    props.history.goBack();
                });
            }
        } catch (e) {
            dispatch({
                type: FORUM_ERROR,
                payload: false,
            });
        }
    };

    useEffect(() => {
        dispatch(getAllCategory());
        dispatch(getForumsID(props.match.params.id));
    }, [dispatch]);

    useEffect(() => {
        setCate(categories);
        setForumData(forum);
    }, [forum, categories]);

    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsPlus />
                        <h3>Ubah Diskusi</h3>
                    </div>
                </div>
            </Container>
            <div>
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Judul Topik</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            placeholder="Masukkan judul topik anda..."
                                            onChange={handleChange}
                                            value={forumData.title}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Deskripsi</Form.Label>

                                        <TextEditor
                                            handleChange={setDesc}
                                            initialContent={
                                                forumData.description
                                            }
                                        />
                                    </Form.Group>

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}

                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>
                                    <Link
                                        to="/lms/forum"
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="card-form">
                                    <Form.Group>
                                        <Form.Label>Kategori</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="category_id"
                                            value={
                                                forumData.category_id
                                                    ? forumData.category_id.id
                                                    : ""
                                            }
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>
                                                Pilih kategori
                                            </option>
                                            {cate
                                                ? cate.map((cat, i) => (
                                                      <option
                                                          key={i}
                                                          defaultValue={cat.id}
                                                          value={cat.id}
                                                      >
                                                          {cat.title}
                                                      </option>
                                                  ))
                                                : ""}
                                        </Form.Select>

                                        {errorCategory && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorCategory}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            name="status"
                                            size="sm"
                                            defaultValue="active"
                                            onChange={handleChange}
                                            value={forumData.status || "active"}
                                        >
                                            <option disabled>Pilih...</option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                        </Form.Select>
                                        {errorStatus && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorStatus}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default EditForum;
