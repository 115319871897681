import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BiChevronLeft, BsKey } from "react-icons/all";
import { useDispatch } from "react-redux";
import { userId } from "../../../resources/utils";
import { Link } from "react-router-dom";
import { changePassword } from "../../../resources/user";
import {
    UPDATE_USER,
    USERS_ERROR,
    GET_USERS_DETAIL,
} from "../../../redux/types";
import swal from "sweetalert";

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState([]);
    const [error, setError] = useState("");

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            password: userData.password,
        };

        if (userData.password === userData.password2) {
            try {
                const res = await changePassword(data, userId());

                dispatch({
                    type: UPDATE_USER,
                    payload: res,
                });

                if (res.status !== "success" || res === undefined) {
                    setError(
                        "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                    );
                } else {
                    swal({
                        title: "Sukses!",
                        text: "Password berhasil diubah!",
                        icon: "success",
                        button: "Kembali!",
                    }).then(() => {
                        dispatch({
                            type: GET_USERS_DETAIL,
                            payload: false,
                        });
                        props.history.push("/lms/profile/" + userId());
                    });
                }
            } catch (e) {
                dispatch({
                    type: USERS_ERROR,
                    payload: false,
                });
            }
        } else {
            setError("Password tidak sama");
        }
    };

    return (
        <div className="margin-t30 forum">
            <div className="top-header margin-b30">
                <div className="title">
                    <BsKey />
                    <h3>Ganti Password</h3>
                </div>
            </div>
            <div className="">
                <Form>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Password Baru</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Masukkan password baru..."
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>
                                            Ulangi Password Baru
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password2"
                                            placeholder="Ulangi password baru..."
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}

                                    <button
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>
                                    <Link
                                        to={"/lms/profile/" + userId()}
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default ChangePassword;
