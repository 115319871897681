import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  GET_COMMENT,
  GET_COMMENT_DETAIL
} from "../types";

const initialState = {
  comments: false,
  comment: false,
  child: false,
  loading: true
};

export const commentReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COMMENT:
      return {
        ...state,
        comments: payload,
        child: payload ? payload.reply : null,
        loading: false
      };
    case GET_COMMENT_DETAIL:
      return {
        ...state,
        comment: payload,
        loading: false
      };
    case CREATE_COMMENT:
      return {
        ...state,
        comment: payload,
        loading: false
      };

    case DELETE_COMMENT:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
