import jwt_decode from "jwt-decode";

export const signin = () => {
    localStorage.setItem("login", true);
};

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.removeItem("remember");
};

export const setRemember = (remember) => {
    localStorage.setItem("remember", remember);
};

export const isRemember = () => {
    if (localStorage.getItem("remember")) {
        return true;
    }

    return false;
};

export const setToken = (token) => {
    localStorage.setItem("token", token);
};

export const getToken = () => {
    if (localStorage.getItem("token")) {
        return localStorage.getItem("token");
    }

    return "";
};

export const setStatus = (status) => {
    localStorage.setItem("status", status);
};

export const getStatus = () => {
    if (localStorage.getItem("status")) {
        return localStorage.getItem("status");
    }

    return "";
};

export const decodeToken = () => {
    if (localStorage.getItem("token")) {
        return jwt_decode(localStorage.getItem("token"));
    }

    return "";
};

export const userId = () => {
    return decodeToken()["UserId"];
};

export const userPermissions = () => {
    return decodeToken()["Permissions"];
};

export const userName = () => {
    return decodeToken()["Name"];
};

export const roleId = () => {
    return decodeToken()["RoleId"];
};

export const isLogin = () => {
    if (localStorage.getItem("login") === "true") {
        return true;
    }

    return false;
};
