import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BiChevronLeft, BsPlus } from "react-icons/all";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { ROLES_ERROR, CREATE_ROLE } from "../../../redux/types";
import { createRole } from "../../../resources/role";
import "./role.scss";

const AddRole = (props) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [errorName, setErrorName] = useState("");
    const [errorStatus, setErrorStatus] = useState("");

    const goBack = () => {
        props.history.goBack();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);

        var val = await validation(data);

        if (val) {
            try {
                const res = await createRole(data);

                dispatch({
                    type: CREATE_ROLE,
                    payload: res,
                });

                if (res.status !== "success" || res === undefined) {
                    if (res.status_code === 422) {
                        if (res.data.name) {
                            setErrorName("Nama harus diisi.");
                        }
                    } else {
                        setError(
                            "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                        );
                    }
                } else {
                    swal({
                        title: "Sukses!",
                        text: "Data kategori sudah disimpan!",
                        icon: "success",
                        button: "Kembali!",
                    }).then(() => {
                        props.history.goBack();
                    });
                }
            } catch (e) {
                dispatch({
                    type: ROLES_ERROR,
                    payload: false,
                });
            }
        }
    };

    function validation(data) {
        var name = false;
        var status = false;

        if (data.get("title") === "" || data.get("title") === null) {
            setErrorName("Nama harus diisi.");
            name = true;
        }

        if (data.get("status") === "" || data.get("status") === null) {
            setErrorStatus("Status belum dipilih.");
            status = true;
        }

        if (name === false && status === false) {
            return true;
        }

        return false;
    }

    const removeAlert = (e) => {
        if (e.target.name === "title") {
            setErrorName("");
            setError("");
        }
    };

    const statusChange = (e) => {
        setErrorStatus(null);
    };

    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsPlus />
                        <h3>Tambah Peran</h3>
                    </div>
                </div>
            </Container>
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            onFocus={removeAlert}
                                            placeholder="Masukkan nama peran..."
                                        />
                                        {errorName && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorName}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control
                                            name="description"
                                            as="textarea"
                                            placeholder="Masukkan deskripsi peran..."
                                        />
                                    </Form.Group>
                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}

                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>
                                    <Link
                                        to="/lms/role"
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            name="status"
                                            size="sm"
                                            defaultValue="active"
                                            onChange={statusChange}
                                        >
                                            <option disabled>Pilih...</option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                        </Form.Select>
                                        {errorStatus && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorStatus}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default AddRole;
