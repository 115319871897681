import React from "react";
import {
    align,
    fontColor,
    hiliteColor,
    list,
    table,
    formatBlock,
    textStyle,
    image,
    imageGallery,
    fontSize,
    font,
} from "suneditor/src/plugins";
// import ImageGallery from "../../plugins/ImageGallery";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export const TextEditor = ({ token, initialContent, handleChange }) => {
    // const handleEditorChange = (event) => {
    //     handleChange;
    // };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleImageUploadBefore = async (files, info, uploadHandler) => {
        const base64 = await convertBase64(files[0]);

        const xhr = new XMLHttpRequest();
        xhr.open(
            "POST",
            process.env.REACT_APP_API_URL + "/api/v1/image/create"
        );
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        const data = new FormData();
        data.append("image", base64);
        xhr.send(data);
        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText);

            const resp = {
                result: [
                    {
                        url: `${response.data.url}`,
                        name: response.data.name,
                        size: 0,
                    },
                ],
            };

            uploadHandler(resp);
        });
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);

            uploadHandler(error);
        });
    };

    return (
        <SunEditor
            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 20px;"
            name="description"
            height="100%"
            onChange={handleChange}
            onImageUploadBefore={handleImageUploadBefore}
            placeholder="Please type here..."
            showToolbar={true}
            setOptions={{
                imageGalleryLoadURL: "https://fake-images.glitch.me?limit=100",
                imageUploadSizeLimit: 2000000,
                plugins: [
                    align,
                    fontSize,
                    formatBlock,
                    fontColor,
                    hiliteColor,
                    list,
                    table,
                    textStyle,
                    image,
                    // ImageGallery,
                ],
                buttonList: [
                    ['undo', 'redo','fontSize', 'formatBlock'],
                    ["image","table", "codeView"],
                    [
                        '-left', ':r-More-default.more_vertical',
                        "bold",
                        "italic",
                        "underline",
                        "fontColor",
                        "align",
                        "outdent",
                        "indent",
                        "list",
                        "removeFormat",
                    ],
                ],
            }}
            setContents={initialContent}
        />
    );
};
