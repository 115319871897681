import {
    CREATE_ROLE,
    DELETE_ROLE,
    GET_ROLES,
    GET_ROLE_DETAIL,
    UPDATE_ROLE,
} from "../types";

const initialState = {
    roles: false,
    role: false,
    responseRole: false,
    loading: true,
};

export const roleReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ROLES:
            return {
                ...state,
                roles: payload,
                loading: false,
            };
        case GET_ROLE_DETAIL:
            return {
                ...state,
                role: payload,
                loading: false,
            };
        case CREATE_ROLE:
            return {
                ...state,
                responseRole: payload,
                loading: false,
            };
        case UPDATE_ROLE:
            return {
                ...state,
                responserRole: payload,
                loading: false,
            };
        case DELETE_ROLE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
