import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    ButtonGroup,
    Button,
    Form,
} from "react-bootstrap";
import {
    BiArrowFromTop,
    FaEdit,
    BsGraphUp,
    BiArrowFromBottom,
    BiCaretDown,
    BiComment,
} from "react-icons/all";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    getRandomColor,
    createImageFromInitials,
} from "../../../resources/utilities";
import { CommentForum } from "../../../components/Comment";
import { getAllForum, getForumsID } from "../../../redux/actions/forumAction";
import Moment from "react-moment";
import {
    ADD_RESPONSE,
    CREATE_RESPONSE,
    ERROR_RESPONSE,
} from "../../../redux/types";
import { createResponse } from "../../../resources/forum";
import "./forum.scss";
import DOMPurify from "dompurify";

const Forum = (props) => {
    const dispatch = useDispatch();
    const forumDetail = useSelector((state) => state.forum);
    const { forums, forum, response } = forumDetail;
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        dispatch(getAllForum());
        dispatch(getForumsID(props.match.params.id));
    }, [dispatch]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        data.set("status", "active");
        try {
            const res = await createResponse(data, props.match.params.id);

            console.log(res);
            dispatch({
                type: ADD_RESPONSE,
                payload: res,
            });
            dispatch(getForumsID(props.match.params.id));
            setShow(false);
        } catch (e) {
            dispatch({
                type: ERROR_RESPONSE,
                payload: false,
            });
        }
    };

    const comment = () => {
        setShow(!show);
    };
    const createForum = () => {
        props.history.push("/lms/forum/create");
    };
    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <Row>
                    <Col lg={8}>
                        <div className="detail-card">
                            <div className="detail-content">
                                <div className="detail-top">
                                    <img
                                        className="rounded-img"
                                        id="preview"
                                        src={createImageFromInitials(
                                            500,
                                            forum.created_by
                                                ? forum.created_by.name
                                                : "Unknown",
                                            getRandomColor()
                                        )}
                                        alt="profile-pic"
                                    />
                                    <div className="detail-inside">
                                        <span>
                                            Dibuat pada,
                                            <Moment
                                                format="D MMMM YYYY"
                                                withTitle
                                            >
                                                {forum.created_at}
                                            </Moment>{" "}
                                            ·{" "}
                                            <Moment local fromNow>
                                                {forum.created_at}
                                            </Moment>
                                        </span>
                                        <h1>{forum.title}</h1>
                                        <div className="content">
                                            <div className="text">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            forum.description
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <div className="category">
                                                <span>
                                                    {forum.category_id
                                                        ? forum.category_id
                                                              .title
                                                        : ""}
                                                </span>
                                            </div>
                                            <div className="comment-section">
                                                {show ? (
                                                    <Form
                                                        onSubmit={handleSubmit}
                                                        className="comment-form"
                                                    >
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="exampleForm.ControlInput1"
                                                        >
                                                            <Form.Label>
                                                                Pesan
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                name="response"
                                                                placeholder="Tulis pesan anda disini... "
                                                            />
                                                        </Form.Group>
                                                        <button className="answer right">
                                                            Kirim
                                                        </button>
                                                    </Form>
                                                ) : (
                                                    <button
                                                        onClick={comment}
                                                        className="answer"
                                                    >
                                                        Jawab
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detail-body">
                                    <div className="indicator">
                                        {/* {count < 1 ? (
                      <span onClick={setCount(count + 1)}>
                        <BiCaretUp />
                      </span>
                    ) : (
                      <span className='clicked'>
                        {" "}
                        <BiCaretUp />{" "}
                      </span>
                    )}
                    <span>{count}</span>
                    {count > -1 ? (
                      <span onClick={setCount(count - 1)}>
                        <BiCaretDown />
                      </span>
                    ) : (
                      <span className='clicked'>
                        {" "}
                        <BiCaretDown />{" "}
                      </span>
                    )} */}
                                    </div>
                                </div>
                            </div>
                            {response ? (
                                <CommentForum
                                    list={response}
                                    total={response ? response.length : "0"}
                                    id={forum.id}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </Col>
                    <Col lg={4}>
                        <aside>
                            <button
                                onClick={createForum}
                                className="btn-secondary"
                            >
                                <FaEdit /> Buat Pertanyaan
                            </button>
                            <div className="side-block">
                                <div className="top-block">
                                    <BsGraphUp /> Forum Teratas
                                    <div className="top-list">
                                        {forums.length > 0 &&
                                            forums
                                                .slice(0, 5)
                                                .map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="top-item clickable"
                                                        onClick={() => {
                                                            props.history.push(
                                                                "/lms/forum/detail/" +
                                                                    item.id
                                                            );
                                                        }}
                                                    >
                                                        <div className="title">
                                                            {item.title}
                                                        </div>
                                                        <div className="response">
                                                            Dibuat{" "}
                                                            {
                                                                item.created_by
                                                                    .name
                                                            }
                                                            {/* <MdModeComment /> 87 */}
                                                        </div>
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Forum;
