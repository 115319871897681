import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function RandomColor({ name }) {
    useEffect(() => {
        const randomBetween = (min, max) =>
            min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const background = `rgba(${r},${g},${b},.08)`;
        const border = `rgba(${r},${g},${b},.01)`;
        const text = `rgba(${r},${g},${b},1)`;
        var els = document.getElementsByClassName("note" + name);
        Array.prototype.forEach.call(els, function (el) {
            // Do stuff here
            el.setAttribute(
                "style",
                `background: ${background};color:${text};border:1px solid ${border}`
            );
        });
    });

    return (
        <span id="test" className={`note${name} u-role`}>
            {name}
        </span>
    );
}
export function CategoryList({ className, click, name }) {
    function getColor() {
        const randomBetween = (min, max) =>
            min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const background = `rgba(${r},${g},${b},.1)`;
        const border = `rgba(${r},${g},${b},.2)`;
        const text = `rgba(${r},${g},${b},1)`;
        return (
            <button
                style={{
                    border: "none",
                    background: "transparent",
                    padding: "0",
                }}
                className={"category-list " + className}
                onClick={className ? () => {} : click}
            >
                <div
                    style={{
                        background: background,
                        border: `1px solid ${border}`,
                    }}
                    className="category-body"
                >
                    <div
                        className="dot"
                        style={{ backgroundColor: text }}
                    ></div>
                    {name}
                </div>
            </button>
        );
    }
    return <>{getColor()}</>;
}

export function CategoryBody({ name }) {
    const getColor = () => {
        const randomBetween = (min, max) =>
            min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const background = `rgba(${r},${g},${b}, .1)`;
        const border = `rgba(${r},${g},${b},.2)`;
        const text = `rgba(${r},${g},${b}, 1)`;
        return (
            <div className="category-tag">
                <div
                    style={{
                        background: background,
                        border: `1px solid ${border}`,
                    }}
                    className="category"
                >
                    <span style={{ color: text }}>{name}</span>
                </div>
            </div>
        );
    };
    return <>{getColor()}</>;
}
