import {
    AddCourses,
    getCourses,
    getCourseDetail,
    updateCourse,
    createCourse,
    getCoursesUser,
    courseWishlist,
    getCourseCP,
} from "../../resources/course";
import {
    ADD_COURSE,
    COURSE_ERROR,
    GET_COURSE,
    GET_COURSE_CP,
    GET_COURSE_DETAIL,
    GET_COURSE_DETAIL_DATA,
    GET_COURSE_USER_CREATED,
    UPDATE_COURSE,
    WISHLIST_COURSE,
} from "../types";

export const getAllCourse = (id) => async (dispatch) => {
    try {
        const res = await getCourses(id);
        dispatch({
            type: GET_COURSE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: e,
        });
    }
};

export const getAllCourseUser = () => async (dispatch) => {
    try {
        const res = await getCoursesUser();
        dispatch({
            type: GET_COURSE_USER_CREATED,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: e,
        });
    }
};

export const getCourseID = (id) => async (dispatch) => {
    try {
        const res = await getCourseDetail(id);
        dispatch({
            type: GET_COURSE_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: console.log(e),
        });
    }
};

export const getCourseIDDetails = (id) => async (dispatch) => {
    try {
        const res = await getCourseDetail(id);
        dispatch({
            type: GET_COURSE_DETAIL_DATA,
            payload: res.details.data,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: console.log(e),
        });
    }
};

export const addCourses = () => async (dispatch) => {
    try {
        const res = await createCourse();
        dispatch({
            type: ADD_COURSE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: console.log(e),
        });
    }
};

export const editCourse = (data, id) => async (dispatch) => {
    try {
        const res = await updateCourse(data, id);
        dispatch({
            type: UPDATE_COURSE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: false,
        });
    }
};

export const wishlistCourse = (id) => async (dispatch) => {
    try {
        const res = await courseWishlist(id);
        dispatch({
            type: WISHLIST_COURSE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: false,
        });
    }
};

export const clearCourse = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_COURSE_DETAIL,
            payload: false,
        });

        dispatch({
            type: GET_COURSE_DETAIL_DATA,
            payload: false,
        });

        dispatch({
            type: UPDATE_COURSE,
            payload: false,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: console.log(e),
        });
    }
};

export const getAllCourseCp = (id) => async (dispatch) => {
    try {
        const res = await getCourseCP(id);
        dispatch({
            type: GET_COURSE_CP,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: COURSE_ERROR,
            payload: e,
        });
    }
};
