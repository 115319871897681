import { getApi, postApi, putApi } from "../services/axios";
import { getToken, userId } from "./utils";

var data = [];
export const getForums = async (id, limit, page, search) => {
  const path = "forum/list?status=&limit=99999&category_id=" + (id ? id : "");
  // "&limit=" +
  // (limit ? limit : "") +
  // "&page=" +
  // (page ? page : "") +
  // "&search=" +
  // (search ? search : "");

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await getApi(path, headers).then((result) => {
    if (result.status_code === 200) {
      data = result.data;
    } else {
      data = null;
    }
  });

  return data;
};

export const getForumsUser = async () => {
  const path = "forum/list/" + userId();

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await getApi(path, headers).then((result) => {
    if (result.status_code === 200) {
      data = result.data;
    } else {
      data = null;
    }
  });

  return data;
};

export const getForumDetail = async (id) => {
  const path = "forum/detail/" + id;

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await getApi(path, headers).then((result) => {
    if (result.status_code === 200) {
      data = result.data;
    } else {
      data = null;
    }
  });

  return data;
};

export const createForum = async (data) => {
  const path = "forum/create";

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await postApi(path, data, headers).then((result) => {
    data = result;
  });

  return data;
};

export const createResponse = async (data, id) => {
  const path = "forum/response/" + id;

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await postApi(path, data, headers).then((result) => {
    data = result;
  });

  return data;
};

export const like = async (id) => {
  const path = "forum/like/" + id;

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await putApi(path, [], headers).then((result) => {
    data = result;
  });

  return data;
};

export const dislike = async (id) => {
  const path = "forum/dislike/" + id;

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await putApi(path, [], headers).then((result) => {
    data = result;
  });

  return data;
};

export const updateForum = async (data, id) => {
  const path = "forum/update/" + id;

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await putApi(path, data, headers).then((result) => {
    data = result;
  });

  return data;
};

export const getResponseByForumId = async (id) => {
  const path = "forum/response/" + id;

  const token = "Bearer " + getToken();
  const headers = {
    headers: {
      Authorization: token
    }
  };

  await getApi(path, headers).then((result) => {
    if (result.status_code === 200) {
      data = result.data;
    } else {
      data = null;
    }
  });

  return data;
};
