import { combineReducers } from "redux";
import { categoryReducers } from "./categoryReducers";
import { roleReducers } from "./roleReducers";
import { userReducer } from "./userReducers";
import { forumReducers } from "./forumReducers";
import { coursesReducers } from "./coursesReducers";
import { settingsReducers } from "./settingsReducers";
import { permissionReducers } from "./permissionsReducers";
import { homeReducers } from "./homeReducers";
import { commentReducers } from "./commentReducers";
import { logReducers } from "./logReducers";

export default combineReducers({
    users: userReducer,
    roles: roleReducers,
    forum: forumReducers,
    category: categoryReducers,
    comments: commentReducers,
    courses: coursesReducers,
    permissions: permissionReducers,
    settings: settingsReducers,
    home: homeReducers,
    log: logReducers,
});
