import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, ListGroup, Spinner } from "react-bootstrap";
import {
  BiMessageSquareDetail,
  BsClockHistory,
  FaEdit,
  FaPen,
  HiMinusCircle,
  BiChevronLeft,
} from "react-icons/all";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourse } from "../../../redux/actions/courseAction";
import { getProfile, userDetail } from "../../../redux/actions/userActions";
import { getToken, roleId, userId } from "../../../resources/utils";
import { deleteApi } from "../../../services/axios";
import swal from "sweetalert";
import { getDetailPermissionById } from "../../../redux/actions/permissionAction";
import { getAllForum } from "../../../redux/actions/forumAction";
import { useHistory } from "react-router-dom";

const TableDetail = (props) => {
  const dispatch = useDispatch();
  const getUserDetail = useSelector((state) => state.users);
  const { user, userCourses, userForums, joinCourse, joinForum, wishlist } =
    getUserDetail;
  const courseList = useSelector((state) => state.courses);
  const { courses } = courseList;
  const forumList = useSelector((state) => state.forum);
  const { forums } = forumList;
  const permissionList = useSelector((state) => state.permissions);
  const { permissionByRole } = permissionList;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [heading, setHead] = useState("");
  const pageId = props.match.params.id;
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllCourse());
    dispatch(getAllForum());
    dispatch(userDetail(userId()));
    dispatch(getDetailPermissionById(roleId()));
  }, [dispatch]);

  const goBack = () => {
    props.history.goBack();
  };

  const createCourse = () => {
    props.history.push("/lms/learn/create");
  };

  const handleDelete = (id) => {
    swal({
      title: "Apakah anda yakin?",
      text: "Data yang sudah dihapus tidak dapat dikembalikan!",
      icon: "warning",
      buttons: {
        cancel: "Batal",
        danger: {
          text: "Hapus",
          value: true,
        },
      },
      dangerMode: true,
    }).then((value) => {
      if (value) {
        const url = `course/delete/${id}`;
        const token = "Bearer " + getToken();
        const headers = {
          headers: {
            Authorization: token,
          },
        };

        deleteApi(url, headers)
          .then((res) => {
            if (res.status !== "error") {
              dispatch(getAllCourse());
              swal("Data kursus berhasil dihapus!", {
                icon: "success",
              });
            } else {
              swal("Data kursus gagal dihapus!", {
                icon: "error",
                buttons: { cancel: "Kembali" },
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const column = [
    {
      name: "Judul Diskusi",
      selector: "name",
      cell: (forum) => (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/lms/${
                  pageId == "forum" || pageId == "joinedforum"
                    ? "forum"
                    : "learn"
                }/detail/${forum.id}`,
              )
            }
            className="u-name"
          >
            {forum.title}
          </div>
        </>
      ),
    },
    {
      name: "Status",
      cell: (forum) => (
        <>
          <span className={`u-status ${forum.status}`}>{forum.status}</span>
        </>
      ),
      center: true,
    },
    {
      cell: (forum) => (
        <>
          <span>
            <a className="btn-edit" href={"course/edit/" + forum.id}>
              <FaPen />
            </a>{" "}
            |{" "}
            <a className="btn-delete" onClick={() => handleDelete(forum.id)}>
              <HiMinusCircle />
            </a>
          </span>
        </>
      ),
      right: true,
      omit: pageId == "joinedforum" || pageId == "wishlist" ? true : false,
    },
  ];

  useEffect(() => {
    setTimeout(
      function () {
        setLoading(false);
      }.bind(this),
      3000,
    );
  }, []);

  useEffect(() => {
    const id = props.match.params.id;
    if (id == "course") {
      setHead("Kursus Saya");
      courses
        ? setData(courses.filter((obj) => obj.created_by.id === userId()))
        : setData(false);
    } else if (id == "forum") {
      setHead("Forum Saya");
      forums
        ? setData(forums.filter((obj) => obj.created_by.id === userId()))
        : setData(false);
    } else if (id == "joinedforum") {
      setHead("Diskusi yang diikuti");
      joinForum ? setData(joinForum) : setData(false);
    } else if (id == "joinedcourse") {
      setHead("Kursus yang diikuti");
      joinCourse ? setData(joinCourse) : setData(false);
    } else if (id == "wishlist") {
      setHead("Kursus yang disimpan");
      wishlist ? setData(wishlist) : setData(false);
    } else {
      setData(false);
    }
    // courses
    //   ? setData(courses.filter((obj) => obj.created_by.id === userId()))
    //   : setData(false);
  }, [courses, forums, joinForum]);

  return (
    <div className="course">
      <Container fluid>
        <div className="margin-t10 title margin-b20">
          <BiMessageSquareDetail />
          <h3>{heading}</h3>
        </div>
        <div className="user-body">
          <Row>
            <Col lg={8} xs={12}>
              <Card className="min-h95p padding10 margin-b30">
                {data.length > 0 ? (
                  <DataTable
                    columns={column}
                    data={data}
                    defaultSortFieldId={1}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                  />
                ) : loading ? (
                  <Spinner animation="border" className="p-absolute left50" />
                ) : (
                  <span className="p-absolute left50 transform-l50">
                    Data tidak ditemukan
                  </span>
                )}
              </Card>
            </Col>
            <Col lg={4} xs={12}>
              <div className="side-block">
                {permissionByRole
                  ? permissionByRole.map((val) =>
                      val.key === "access_write_course" ? (
                        val.value === 1 ? (
                          <button
                            onClick={createCourse}
                            className="btn-secondary margin-b30"
                          >
                            <FaEdit /> Buka Kursus Baru
                          </button>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      ),
                    )
                  : ""}
                <div className="card-title border-r20">
                  <span className="title-block">
                    <BsClockHistory /> Aktivitas
                  </span>
                </div>
                <ListGroup>
                  <ListGroup.Item>
                    13:00 - Admin membuat user baru
                  </ListGroup.Item>
                  <ListGroup.Item>
                    13:20 - Admin update user role pada user A
                  </ListGroup.Item>
                  <ListGroup.Item>
                    13:50 - Admin menghapus user C
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </div>
        <div className="p-footer margin-b30"></div>
      </Container>
    </div>
  );
};

export default TableDetail;
