import axios from "axios";

var baseUrl = `https://api.kelaslenterakuark.id/api/v1/lms/`;

export const getApi = async (path, headers) => {
    try {
        const response = await axios.get(baseUrl + path, headers);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const postApi = async (path, data, headers) => {
    try {
        const response = await axios.post(baseUrl + path, data, headers);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const putApi = async (path, data, headers) => {
    try {
        const response = await axios.put(baseUrl + path, data, headers);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const deleteApi = async (path, headers) => {
    try {
        const response = await axios.delete(baseUrl + path, headers);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
