import { deleteApi, getApi, postApi, putApi } from "../services/axios";
import { getToken, userId } from "./utils";

var data = [];

export const getUsers = async (status, limit, page, search) => {
    const path =
        "user/list?status=" +
        (status ? status : "") +
        "&limit=" +
        (limit ? limit : "1000") +
        "&page=" +
        (page ? page : "") +
        "&search=" +
        (search ? search : "");

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getUserDetail = async (id) => {
    const path = "user/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getUnverifiedUser = async () => {
    const path = "user/unverified";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const createUser = async (data) => {
    const path = "user/create";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const updateUser = async (data, id) => {
    const path = "user/update/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const changePassword = async (data, id) => {
    const path = "user/password/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const deleteUser = async (id) => {
    const path = "user/delete/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await deleteApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getUserProfile = async () => {
    const path = "user/detail/" + userId();

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};
