import {
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    GET_CATEGORY,
    GET_CATEGORY_DETAIL,
    UPDATE_CATEGORY,
} from "../types";

const initialState = {
    categories: false,
    category: false,
    responseCategory: false,
    loading: true,
};

export const categoryReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CATEGORY:
            return {
                ...state,
                categories: payload,
                loading: false,
            };
        case GET_CATEGORY_DETAIL:
            return {
                ...state,
                category: payload,
                loading: false,
            };
        case CREATE_CATEGORY:
            return {
                ...state,
                responseCategory: payload,
                loading: false,
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                responseCategory: payload,
                loading: false,
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
