import React, { Component, useState, useEffect } from "react";
import {
    Accordion,
    Col,
    Container,
    Form,
    Row,
    Button,
    Image,
    InputGroup,
    FormControl,
    Modal,
    FormGroup,
    Card,
} from "react-bootstrap";
import {
    BiChevronLeft,
    BsFileEarmarkText,
    AiOutlineFilePdf,
    BiVideo,
    BiTrash,
    BsPlus,
} from "react-icons/all";
import DatePicker from "react-datepicker";
import { getToken } from "../../../resources/utils";
import { postApi } from "../../../services/axios";
import { v4 as uuidv4 } from "uuid";
import { getAllCategory } from "../../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import { createCourse, createCourseSection } from "../../../resources/course";
import {
    ADD_COURSE,
    ADD_COURSE_SECTION,
    COURSE_ERROR,
} from "../../../redux/types";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { validURL } from "../../../resources/helpers";

function ModalAttachment(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Konten
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer>
                <Button className="btn-clear" onClick={props.onHide}>
                    Close
                </Button>
                <Button onClick={props.onSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
}

const Section = ({ count, uuid, closed_date }) => {
    const dispatch = useDispatch();
    const [showing, setShows] = useState(true);
    const [content, setContents] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [error, setError] = useState([]);
    const [errorTitle, setErrorTitle] = useState([]);
    const [errorEndDate, setErrorEndDate] = useState([]);
    const [errorAttachment, setErrorAttachment] = useState([]);
    const [errorDescription, setErrorDescription] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [title, setTitle] = useState([]);
    const [description, setDescription] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const addFile = (id) => {
        setContents((content) => [
            ...content,
            <InputGroup className="mb-3">
                <FormControl
                    aria-label="Masukkan link file..."
                    aria-describedby="basic-addon2"
                    name={"attachment" + count}
                    type="text"
                    placeholder="Masukkan link file..."
                    onChange={(e) => {
                        setAttachment([
                            ...attachment,
                            {
                                type: "file",
                                value: e.target.value,
                            },
                        ]);
                    }}
                    onFocus={() => {
                        setErrorAttachment({ [count]: "" });
                    }}
                />
                <Button
                    variant="outline-danger"
                    id="button-delete"
                    className={content.length}
                    onClick={() => {
                        onDeleteByIndex(content.length);
                    }}
                >
                    <BiTrash />{" "}
                </Button>
            </InputGroup>,
        ]);
    };

    const addVideo = (id) => {
        setContents((content) => [
            ...content,
            <InputGroup className="mb-3">
                <FormControl
                    aria-label="Masukkan link video..."
                    aria-describedby="basic-addon2"
                    name={"attachment" + count}
                    type="text"
                    placeholder="Masukkan link video..."
                    onChange={(e) => {
                        setAttachment([
                            ...attachment,
                            {
                                type: "video",
                                value: e.target.value,
                            },
                        ]);
                    }}
                    onFocus={() => {
                        setErrorAttachment({ [count]: "" });
                    }}
                />
                <Button
                    variant="outline-danger"
                    id="button-delete"
                    className={content.length}
                    onClick={() => {
                        onDeleteByIndex(content.length);
                    }}
                >
                    <BiTrash />{" "}
                </Button>
            </InputGroup>,
        ]);
    };

    const onDeleteByIndex = (index) => {
        const newCountries = [...content];
        newCountries.splice(index, 1);
        setShows(true);
        setContents(newCountries);

        const newAttachment = [...attachment];
        newAttachment.splice(index, 1);
        setAttachment(newAttachment);
    };

    function formatDate(str) {
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateFormat = [year, month, day].join("-");
        return dateFormat;
    }

    const removeAlert = (e) => {
        if (e.target.name === "title" + e.target.name) {
            setErrorTitle([]);
            setError([]);
        } else if (e.target.name === "date" + e.target.name) {
            setErrorEndDate([]);
            setError([]);
        }
    };

    const submitContent = () => {
        const data = new FormData(document.getElementById("content"));
        setAttachment([
            ...attachment,
            {
                type: data.get("type"),
                title: data.get("title"),
                value: data.get("link"),
            },
        ]);

        if (!validURL(data.get("link"))) {
            setErrorAttachment("URL tidak valid.");
        }

        setModalShow(false);
    };

    const deleteAttachment = (index) => {
        const filteredAttach = attachment.filter((val, i) => i !== index);

        setAttachment(filteredAttach);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        var id = event.target.name;

        if (!title[id] || !attachment || endDate <= startDate) {
            if (!title[id]) {
                setErrorTitle({ [id]: "Judul modul kosong." });
            }

            if (!title[id]) {
                setErrorTitle({ [id]: "Judul modul kosong." });
            }

            if (!attachment) {
                setErrorAttachment("Anda belum menambahkan konten.");
            }

            if (endDate <= startDate) {
                setErrorEndDate({
                    [id]: "Tanggal selesai tidak dapat lebih kecil atau sama dengan tanggal dimulai.",
                });
            }

            return;
        }

        const data = new FormData(document.getElementById("section" + id));

        data.set("key", uuid);
        data.set("status", "active");
        data.set("title", title[id]);
        data.set("open_date", formatDate(startDate));
        data.set("closed_date", formatDate(endDate));
        data.set("attachment", JSON.stringify(attachment));

        try {
            const res = await createCourseSection(data);

            dispatch({
                type: ADD_COURSE_SECTION,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                if (res.status_code === 422) {
                    if (res.data.title) {
                        setErrorTitle({ [id]: "Judul modul kosong." });
                    }
                } else {
                    setError(
                        "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                    );
                }
            } else {
                swal({
                    title: "Sukses!",
                    text: "Module " + title[count] + " sudah disimpan!",
                    icon: "success",
                    button: "Ok!",
                });
            }
        } catch (error) {
            dispatch({
                type: COURSE_ERROR,
                payload: false,
            });
        }
    };

    useEffect(() => {
        setEndDate(new Date(closed_date));
        // console.log(closed_date);
    }, [closed_date]);

    return (
        <>
            <Accordion.Item eventKey={count}>
                <Accordion.Header>
                    {title[count] ? title[count] : "Modul " + count}
                </Accordion.Header>
                <Accordion.Body>
                    <Form key={count} id={"section" + count}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Judul</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Masukkan judul modul..."
                                onFocus={() => {
                                    setErrorTitle({ [count]: "" });
                                }}
                                name={"title" + count}
                                onChange={(e) => {
                                    setTitle({ [count]: e.target.value });
                                }}
                            />
                            {errorTitle[count] && (
                                <>
                                    <Form.Text className="text-alert">
                                        *{errorTitle[count]}
                                    </Form.Text>
                                </>
                            )}
                        </Form.Group>
                        <Form.Control
                            as="textarea"
                            name="description"
                            placeholder="Masukkan deskripsi modul..."
                            onChange={(e) => {
                                setDescription({ [count]: e.target.value });
                            }}
                            onFocus={() => {
                                setErrorDescription({ [count]: "" });
                            }}
                        />
                        <div className="date">
                            <span>
                                Mulai
                                <DatePicker
                                    className="form-control"
                                    selected={startDate}
                                    name={"start" + count}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setErrorEndDate([]);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </span>
                            <span>
                                Hingga
                                <DatePicker
                                    readOnly={true}
                                    className="form-control"
                                    selected={endDate}
                                    name={"end" + count}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        setErrorEndDate([]);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </span>
                        </div>
                        {errorEndDate[count] && (
                            <>
                                <Form.Text className="text-alert">
                                    *{errorEndDate[count]}
                                </Form.Text>
                            </>
                        )}

                        <Row>
                            {attachment.map((val, i) => (
                                <Col xs={12} md={6} key={i}>
                                    <Card className="padding-lr30">
                                        <div className="item-element" key={i}>
                                            <span className="text-label12">
                                                {val.type}
                                            </span>
                                            <div>{val.title}</div>
                                            <a
                                                href={val.value}
                                                className="text-label12 ellipsis"
                                            >
                                                {val.value}
                                            </a>
                                            <div className="delete-container">
                                                <Button
                                                    className="btn btn-delete-content text-white"
                                                    onClick={() =>
                                                        deleteAttachment(i)
                                                    }
                                                >
                                                    <BiTrash />
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                    {errorAttachment[count] && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{errorAttachment[count]}
                                            </Form.Text>
                                        </>
                                    )}
                                </Col>
                            ))}
                        </Row>

                        <div style={{ display: showing ? "flex" : "none" }}>
                            <Button
                                className="btn btn-primary margin-tb30 w-100"
                                onClick={() => {
                                    setModalShow(true);
                                    console.log(attachment);
                                }}
                            >
                                Tambah Konten
                            </Button>
                        </div>

                        <div className="">
                            <input
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                name={count}
                                type="submit"
                                value="Simpan"
                            />
                        </div>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>

            <ModalAttachment
                show={modalShow}
                onSubmit={submitContent}
                onHide={() => setModalShow(false)}
            >
                <Form onSubmit={submitContent} id="content">
                    <label className="display-block">Pilih Tipe Konten</label>
                    <Form.Select
                        name="type"
                        aria-label="Default select example"
                        defaultValue=""
                    >
                        <option disabled value="">
                            Pilih tipe konten
                        </option>
                        <option value="video">Video</option>
                        <option value="file">File</option>
                    </Form.Select>
                    <FormGroup className="margin-t10">
                        <label className="display-block">Judul</label>
                        <FormControl
                            aria-label="Masukkan judul ..."
                            aria-describedby="basic-addon2"
                            name="title"
                            type="text"
                            placeholder="Masukkan judul ..."
                        />
                    </FormGroup>
                    <FormGroup className="margin-t10">
                        <label className="display-block">Link</label>
                        <FormControl
                            aria-label="Masukkan link ..."
                            aria-describedby="basic-addon2"
                            name="link"
                            type="text"
                            placeholder="Masukkan link ..."
                        />
                    </FormGroup>
                </Form>
            </ModalAttachment>
        </>
    );
};

const AddCourse = (props) => {
    const dispatch = useDispatch();
    const categoryList = useSelector((state) => state.category);
    const { categories } = categoryList;
    const [number, setNumber] = useState(1);
    const [section, setSection] = useState([]);
    const [isScroll, setScroll] = useState("absolute");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [file, setFile] = useState(null);
    const [size, setSize] = useState(null);
    const [name, setName] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uuid, setUuid] = useState(uuidv4);
    const [error, setError] = useState("");
    const [errorTitle, setErrorTitle] = useState(null);
    const [errorEndDate, setErrorEndDate] = useState(null);
    const [errorCategory, setErrorCategory] = useState(null);
    const [cat, setCategory] = useState(0);
    const [cate, setCate] = useState([]);

    const categoryChange = (e) => {
        setErrorCategory(null);
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setErrorEndDate(null);
    };

    const addSection = () => {
        if (endDate === null) {
            setErrorTitle("Anda belum mengisi judul kursus");
            setErrorCategory("Anda belum memilih kategori kursus");
            setErrorEndDate("Anda belum menentukan tanggal berakhir kursus");
        } else {
            setNumber(number + 1);
            setSection([
                ...section,
                <Section
                    key={number}
                    count={number}
                    uuid={uuid}
                    closed_date={endDate}
                />,
            ]);
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    function formatDate(str) {
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var dateFormat = [year, month, day].join("-");
        return dateFormat;
    }

    const fileChange = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setFile(file);
                setSize(file.size);
                setName(file.name);
                setPreview(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    function checkEndDate(endDate) {
        var now = new Date();
        var d = now.getDate();
        var m = now.getMonth();
        var y = now.getFullYear();

        var dateNow = new Date(y, m, d);
        endDate = new Date(endDate);

        if (endDate > dateNow) {
            return true;
        } else {
            return false;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);

        var val = await validation(data);

        if (val) {
            try {
                data.set("key", uuid);
                data.set("open_date", formatDate(startDate));
                data.set("closed_date", formatDate(endDate));
                data.set("image", preview ? preview : "");

                const res = await createCourse(data);

                dispatch({
                    type: ADD_COURSE,
                    payload: res,
                });

                if (res.status !== "success" || res === undefined) {
                    if (res.status_code === 422) {
                        if (res.data.title) {
                            setErrorTitle("Nama harus diisi.");
                        }
                    } else {
                        setError(
                            "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                        );
                    }
                } else {
                    swal({
                        title: "Sukses!",
                        text: "Data kursus sudah disimpan!",
                        icon: "success",
                        button: "Kembali!",
                    }).then(() => {
                        props.history.goBack();
                    });
                }
            } catch (e) {
                dispatch({
                    type: COURSE_ERROR,
                    payload: false,
                });
            }
        }
    };

    function validation(data) {
        var date = false;
        var cat = false;
        var title = false;

        if (endDate < startDate) {
            setErrorEndDate("Tanggal akhir belum dipilih.");
            date = true;
        }

        if (
            data.get("category_id") === "" ||
            data.get("category_id") === null
        ) {
            setErrorCategory("Kategori belum dipilih.");
            cat = true;
        }

        if (data.get("title") === "" || data.get("category_id") === null) {
            setErrorTitle("Judul harus diisi.");
            title = true;
        }

        if (date === false && cat === false && title === false) {
            return true;
        }

        return false;
    }

    const removeAlert = (e) => {
        if (e.target.name === "title") {
            setErrorTitle(null);
            setError(null);
        } else if (e.target.name === "date") {
            setErrorEndDate(null);
            setError(null);
        }
    };

    useEffect(() => {
        dispatch(getAllCategory());
    }, [dispatch]);

    useEffect(() => {
        setCate(categories);
    }, [categories]);

    return (
        <div className="margin-t30 learn-page">
            <div className="top-header margin-b30">
                <div className="title">
                    <BsPlus />
                    <h3>Tambah Kursus</h3>
                </div>
            </div>
            <div className="learn-create">
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form learn-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Judul</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            onFocus={removeAlert}
                                            placeholder="Masukkan judul kursus..."
                                        />
                                        {errorTitle && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorTitle}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            placeholder="Masukkan deskripsi kursus..."
                                        />
                                        {/* <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                            </Form.Text> */}
                                    </Form.Group>
                                    <Accordion>{section}</Accordion>
                                    <a
                                        className="section-btn"
                                        onClick={addSection}
                                    >
                                        Tambahkan Modul
                                    </a>
                                    <br />

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}
                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>

                                    <Link
                                        to="/lms/learn"
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="card-form learn-form">
                                    <div className="date">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="status"
                                            defaultValue="draft"
                                        >
                                            <option value="" disabled>
                                                Pilih Status
                                            </option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                            <option value="draft">Draft</option>
                                        </Form.Select>
                                    </div>
                                    <div className="date">
                                        <Form.Label>Kategori</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="category_id"
                                            defaultValue=""
                                            onChange={categoryChange}
                                        >
                                            <option value="" disabled>
                                                Pilih kategori
                                            </option>
                                            {cate
                                                ? cate.map((cat, i) => (
                                                      <option
                                                          key={i}
                                                          defaultValue={cat.id}
                                                          value={cat.id}
                                                      >
                                                          {cat.title}
                                                      </option>
                                                  ))
                                                : ""}
                                        </Form.Select>

                                        {errorCategory && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorCategory}
                                                </Form.Text>
                                            </>
                                        )}
                                    </div>
                                    <div className="date">
                                        <Form.Label>Jadwal Aktif</Form.Label>
                                        <DatePicker
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            inline
                                            name="date"
                                        />
                                        {errorEndDate && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorEndDate}
                                                </Form.Text>
                                            </>
                                        )}
                                    </div>
                                    <div className="date">
                                        <Form.Label>Gambar</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={fileChange}
                                        />
                                    </div>
                                    <Image
                                        className="relative img-fit-cover img-size-100"
                                        src={
                                            preview
                                                ? preview
                                                : require("../../../assets/images/placeholder.png")
                                                      .default
                                        }
                                        alt="course thumbnail"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default AddCourse;
