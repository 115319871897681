import { deleteApi, getApi, postApi, putApi } from "../services/axios";
import { getAPI } from "./utilities";
import { getToken, userId } from "./utils";

var data = [];

export const getCourses = async (id, status, limit, page, search) => {
    const path =
        "course/list?order_by=created_at&order_as=desc&category_id=" +
        (id ? id : "");
    // (status ? status : "") +
    // "&limit=" +
    // (limit ? limit : "") +
    // "&page=" +
    // (page ? page : "") +
    // "&search=" +
    // (search ? search : "");

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getCoursesUser = async () => {
    const path = "course/list/" + userId();

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const createCourse = async (data) => {
    const path = "course/create";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const getCourseDetail = async (id) => {
    const path = "course/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const updateCourse = async (data, id) => {
    const path = "course/update/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const deleteCourse = async (id) => {
    const path = "course/delete/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await deleteApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const createCourseSection = async (data) => {
    const path = "course/section";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const updateCourseSection = async (data, id) => {
    const path = "course/section/update/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const deleteCourseSection = async (id) => {
    const path = "course/section/delete/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await deleteApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const joinCourse = async (id) => {
    const path = "course/join/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    let data = [];

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const unjoinCourse = async (id) => {
    const path = "course/unjoin/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    let data = [];

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const courseWishlist = async (id) => {
    const path = "course/wishlist/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, [], headers).then((result) => {
        data = result;
    });

    return data;
};

export const getCourseCP = async (id, status, limit, page, search) => {
    const path = "front/course/list";

    await getAPI(path).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};
