import {
    ADD_FORUM,
    ADD_RESPONSE,
    EDIT_FORUM,
    GET_FORUM,
    GET_FORUM_DETAIL,
    GET_FORUM_USER_CREATED,
    GET_RESPONSE,
    UPDATE_DISLIKES,
    UPDATE_LIKES,
} from "../types";

const initialState = {
    forums: false,
    forumsUser: false,
    forum: false,
    response: false,
    likes: false,
    dislikes: false,
    loading: true,
};

export const forumReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_FORUM:
            return {
                ...state,
                forums: payload,
                loading: false,
            };
        case GET_FORUM_USER_CREATED:
            return {
                ...state,
                forumsUser: payload,
                loading: false,
            };
        case GET_FORUM_DETAIL:
            return {
                ...state,
                forum: payload,
                response: payload
                    ? payload.details.data.sort(
                          (a, b) =>
                              new Date(b.created_at) - new Date(a.created_at)
                      )
                    : null,
                loading: false,
            };
        case ADD_FORUM:
            return {
                ...state,
                forum: payload,
                loading: false,
            };
        case EDIT_FORUM:
            return {
                ...state,
                forum: payload,
                loading: false,
            };
        case ADD_RESPONSE:
            return {
                ...state,
                response: payload,
                loading: false,
            };
        case UPDATE_LIKES:
            return {
                ...state,
                likes: payload,
                loading: false,
            };
        case UPDATE_DISLIKES:
            return {
                ...state,
                dislikes: payload,
                loading: false,
            };
        case GET_RESPONSE:
            return {
                ...state,
                response: payload,
                loading: false,
            };
        default:
            return state;
    }
};
