import React, { useState, useRef } from "react";
import login from "../../assets/images/login.svg";
import { Row, Col, Container, Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "./activity.scss";
import { getAPI } from "../../resources/utilities";
import Moment from "react-moment";
import "moment/locale/id";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function ActivityDetail(props) {
    const [posts, setPosts] = useState([]);
    const [postsList, setPostsList] = useState([]);
    const [show, setShow] = useState(false);
    const mounted = useRef();

    const getData = () => {
        var url = `front/post/detail/${props.match.params.id}`;
        getAPI(url).then((data) => {
            setPosts(data.data);
        });
    };

    const getList = () => {
        var url = `front/post/list?limit=5&category_id=1&order_by=post_date&order_as=asc`;
        getAPI(url).then((data) => {
            setPostsList(data.data);
        });
    };

    useEffect(() => {
        if (!mounted.current) {
            getData();
            getList();
            mounted.current = true;
        } else {
            getData();
            getList();
        }
    }, [posts]);

    return (
        <>
            <div className="activity-detail">
                <Container>
                    <div className="padding-t30 head-title">
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="activity-img">
                                    <span className="activity-status">
                                        {posts.status ? "Published" : "Pending"}
                                        ,{" "}
                                        <Moment format="D MMMM YYYY" withTitle>
                                            {posts.post_date}
                                        </Moment>
                                    </span>
                                    <h1>{posts.title}</h1>
                                    <span>{posts.subtitle}</span>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="activity-img">
                                    <div id="left"></div>
                                    <img id="center" src={posts.image} />
                                    <div id="right"></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="body-activity">
                    <Container>
                        <Row>
                            <Col md={9}>
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html: posts.description,
                                    }}
                                ></div>
                            </Col>
                            <Col md={3}>
                                <aside>
                                    <div className="side-title">
                                        <h5 className="">Recent Update</h5>
                                        <hr className="w-50 transform-l50" />
                                    </div>
                                    <div className="side-body">
                                        <ul>
                                            {postsList.map((list, i) => (
                                                <li
                                                    key={i}
                                                    className="text-elipsis-multi"
                                                >
                                                    <Link
                                                        to={
                                                            "/activity/detail/" +
                                                            list.id
                                                        }
                                                    >
                                                        <Row>
                                                            <Col xs={5} md={12}>
                                                                <img
                                                                    src={
                                                                        list.image
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xs={7} md={12}>
                                                                <span>
                                                                    {list.title.substring(
                                                                        0,
                                                                        100
                                                                    )}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </aside>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ActivityDetail;
