export const GET_USERS = "GET_USERS";
export const GET_USERS_DETAIL = "GET_USERS_DETAIL";
export const GET_USERS_PROFILE = "GET_USERS_PROFILE";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_SELECTED_USERS = "REMOVE_SELECTED_USERS";
export const GET_UNVERIFIED_USER = "GET_UNVERIFIED_USER";
export const USERS_ERROR = "USERS_ERROR";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const ROLES_ERROR = "ROLES_ERROR";

export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";

export const GET_FORUM = "GET_FORUM";
export const GET_FORUM_USER_CREATED = "GET_FORUM_USER_CREATED";
export const GET_FORUM_DETAIL = "GET_FORUM_DETAIL";
export const GET_RESPONSE = "GET_RESPONSE";
export const ADD_FORUM = "ADD_FORUM";
export const EDIT_FORUM = "EDIT_FORUM";
export const FORUM_ERROR = "FORUM_ERROR";
export const ADD_RESPONSE = "ADD_RESPONSE";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const UPDATE_DISLIKES = "UPDATE_DISLIKES";
export const ERROR_RESPONSE = "ERROR_RESPONSE";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_USER_CREATED = "GET_COURSE_USER_CREATED";
export const GET_COURSE_DETAIL = "GET_COURSE_DETAIL";
export const GET_COURSE_DETAIL_DATA = "GET_COURSE_DETAIL_DATA";
export const ADD_COURSE = "ADD_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const WISHLIST_COURSE = "WISHLIST_COURSE";
export const GET_COURSE_CP = "GET_COURSE_CP";

export const COURSE_ERROR = "COURSE_ERROR";

export const ADD_COURSE_SECTION = "ADD_COURSE_SECTION";
export const UPDATE_COURSE_SECTION = "UPDATE_COURSE_SECTION";
export const DELETE_COURSE_SECTION = "DELETE_COURSE_SECTION";

export const JOIN_COURSE = "JOIN_COURSE";
export const UNJOIN_COURSE = "UNJOIN_COURSE";

export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_DETAIL = "GET_COMMENT_DETAIL";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ERROR_COMMENT = "ERROR_COMMENT";
export const GET_COMMENT_CHILD = "GET_COMMENT_CHILD";

export const GET_SETTINGS = "GET_SETTINGS";
export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";
export const GET_BERANDA_SETTINGS = "GET_BERANDA_SETTINGS";
export const GET_TENTANG_SETTINGS = "GET_TENTANG_SETTINGS";
export const GET_PROGRAM_SETTINGS = "GET_PROGRAM_SETTINGS";
export const GET_CONTACT_SETTINGS = "GET_CONTACT_SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";

export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_GROUP = "GET_PERMISSION_GROUP";
export const GET_PERMISSION_DETAIL = "GET_PERMISSION_DETAIL";
export const GET_PERMISSION_DETAIL_ROLE_ID = "GET_PERMISSION_DETAIL_ROLE_ID";
export const ADD_PERMISSION = "ADD_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const SETUP_PERMISSION = "SETUP_PERMISSION";
export const ERROR_PERMISSION = "ERROR_PERMISSION";

export const GET_PARTNER = "GET_PARTNER";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_TIMELINE = "GET_TIMELINE";
export const GET_RESEARCH = "GET_RESEARCH";
export const GET_HOME_CATEGORY = "GET_HOME_CATEGORY";

export const HOME_ERROR = "HOME_ERROR";

export const GET_LOG = "GET_LOG";
export const GET_LOG_DETAIL = "GET_LOG_DETAIL";
export const LOG_ERROR = "LOG_ERROR";
