import React, { useState } from "react";
import forgot from "../../assets/images/forgot.svg";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import "./forgot.scss";
import { IoMail, AiOutlineLoading3Quarters } from "react-icons/all";
import { Link } from "react-router-dom";
import { postApi } from "../../services/axios";

function Forgot(props) {
    const [loading, setLoading] = useState(false);
    const email = useFormInput("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleForgot = (event) => {
        setError(null);

        event.preventDefault();
        setLoading(true);

        const header = {};

        const data = {
            email: email.value,
        };

        postApi("auth/forgot", data, header).then((result) => {
            setLoading(false);
            if (result.status_code !== 200) {
                setError(
                    result.message
                        ? result.message
                        : "Terjadi kesalahan, silahkan ulangi beberapa saat lagi"
                );
            } else {
                setSuccess("Email lupa password dikirim");
            }
        });
    };

    const removeAlert = (e) => {
        setError(null);
        setSuccess(null);
    };

    return (
        <>
            <Container className="padding-tb30">
                <h1 className="text-center">Lupa password?</h1>
                <p className="text-center">
                    Masukkan email terdaftar untuk mendapatkan link reset
                    password
                </p>
            </Container>
            <Container>
                <Card className="padding30 margin-b50">
                    <Row>
                        <Col lg={5} className="relative d-none d-lg-block">
                            <img alt="Content forgot" src={forgot} />
                        </Col>
                        <Col lg={7} sm={12}>
                            <Form onSubmit={handleForgot}>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="text"
                                                {...email}
                                                className="input-form"
                                                placeholder="kelaslentera@mail.id"
                                                onFocus={removeAlert}
                                                autoComplete="off"
                                            />
                                            <label className="input-label">
                                                Email
                                            </label>
                                            <IoMail className="icon-btnl left" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a
                                        onClick={handleForgot}
                                        className="btn-primary-lg btn-w100 text-center"
                                    >
                                        {loading === true ? (
                                            <div>
                                                <AiOutlineLoading3Quarters className="loading-spin" />
                                            </div>
                                        ) : (
                                            "Kirim"
                                        )}
                                    </a>
                                    {error && (
                                        <div className="text-center">
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                        </div>
                                    )}
                                    {success && (
                                        <div className="text-center">
                                            <Form.Text className="text-success">
                                                *{success}
                                            </Form.Text>
                                        </div>
                                    )}
                                </div>
                                <div className="margin-p75 text-center">
                                    <p>
                                        Sudah memiliki akun?{" "}
                                        <Link
                                            to="/login"
                                            className="btn-clear underline text-bold600"
                                        >
                                            Masuk
                                        </Link>{" "}
                                        atau{" "}
                                        <Link
                                            to="/register"
                                            className="btn-clear underline text-bold600"
                                        >
                                            Daftar disini
                                        </Link>
                                    </p>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return {
        value,
        onChange: handleChange,
    };
};

export default Forgot;
