export function CheckUserCourse(data, courseId) {
    var rw = false;
    data
        ? data.map((val) => (val.id === courseId ? (rw = true) : (rw = false)))
        : (rw = false);

    return rw;
}

export function CheckAdmin(data, admin) {
    var rw = [];
    data.map((val) => (val === admin ? rw.push(val) : ""));

    if (rw.length > 0) {
        return true;
    }

    return false;
}

export function CheckReadWrite(data, read, write) {
    var rw = [];
    data.map((val) =>
        val === read ? rw.push(val) : val === write ? rw.push(val) : ""
    );

    if (rw.length > 1) {
        return true;
    }

    return false;
}

export function CheckReadOnly(data, read) {
    var rw = [];
    data.map((val) => (val === read ? rw.push(val) : ""));

    if (rw.length > 0) {
        return true;
    }

    return false;
}

export function CheckWriteOnly(data, write) {
    var rw = [];
    data.map((val) => (val === write ? rw.push(val) : ""));

    if (rw.length > 0) {
        return true;
    }

    return false;
}

export function CompareDateNow(compareDate) {
    var dateNow = new Date().toISOString().slice(0, 10);
    compareDate = new Date(compareDate).toISOString().slice(0, 10);

    if (compareDate > dateNow) {
        return true;
    } else {
        return false;
    }
}

export function CompareDateNowWithType(data, compareDate, type) {
    if (data) {
        var dateNow = new Date().toISOString().slice(0, 10);
        compareDate = new Date(compareDate).toISOString().slice(0, 10);
        if (type === "open") {
            if (compareDate > dateNow) {
                return true;
            } else {
                return false;
            }
        } else {
            if (compareDate > dateNow) {
                return true;
            } else {
                return false;
            }
        }
    }
}

export function FormatDate(str, separate, format) {
    var d = new Date(str),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var form = [];

    if (format === 1) {
        form = [year, month, day];
    } else if (format === 2) {
        form = [day, month, year];
    }

    var dateFormat = form.join(separate);
    return dateFormat;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validURL(str) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}
