import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Image, Card, Spinner } from "react-bootstrap";
import {
  BiMessageSquareDetail,
  BiConversation,
  BiUser,
  BsGraphUp,
  BiBook,
  BiBox
} from "react-icons/all";
import Moment from "react-moment";
import useDraggableScroll from "use-draggable-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCourse,
  getAllCourseUser
} from "../../../redux/actions/courseAction";
import {
  getAllForum,
  getAllForumUser
} from "../../../redux/actions/forumAction";
import { getDetailPermissionById } from "../../../redux/actions/permissionAction";
import { getProfile } from "../../../redux/actions/userActions";
import {
  createImageFromInitials,
  getRandomColor
} from "../../../resources/utilities";
import { roleId, userId } from "../../../resources/utils";
import "./dashboard.scss";
import { getAllCategory } from "../../../redux/actions/categoryAction";
import { CompareDateNow } from "../../../resources/helpers";
import { HorizontalScroll } from "../../../components/CardScroll";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const courseList = useSelector((state) => state.courses);
  const forumList = useSelector((state) => state.forum);
  const categoryList = useSelector((state) => state.category);
  const permissionList = useSelector((state) => state.permissions);
  const { permissionByRole } = permissionList;
  const { courses, coursesUser } = courseList;
  const { forums, forumsUser } = forumList;
  const { categories } = categoryList;
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const ref = useRef(null);

  const { onMouseDown } = useDraggableScroll(ref, {
    direction: "horizontal"
  });

  useEffect(() => {
    dispatch(getAllCourse());
    dispatch(getAllCourseUser());
    dispatch(getAllForum());
    dispatch(getAllForumUser());
    dispatch(getDetailPermissionById(roleId()));
    dispatch(getAllCategory());
  }, [dispatch]);

  const filter = (id, index) => {
    const filterCourse = courses.filter((val) => val.category_id.id === id);
    const filterForum = forums.filter((val) => val.category_id.id === id);
    if (id == "All") {
      dispatch(getAllForum());
      dispatch(getAllCourse());
    } else {
      dispatch(getAllForum(id));
      dispatch(getAllCourse(id));
    }
    setActiveIndex(index); // remove the curly braces
  };

  function getLatestActivityForum(data) {
    let sortedDescending = data.sort((a, b) => {
      return b.id - a.id;
    });

    return sortedDescending[0].created_at;
  }

  useEffect(() => {
    document
      .getElementsByClassName(
        "react-horizontal-scrolling-menu--scroll-container"
      )[0]
      .setAttribute("id", "scroller");
    setTimeout(
      function () {
        setLoading(false);
      }.bind(this),
      3000
    );
  }, []);

  return (
    <>
      <div className='learning margin-tb30'>
        <Container fluid>
          <Row>
            <Col xs={12} lg={8}>
              <Row>
                <Col xs={12} lg={12}>
                  <div className='title'>
                    <BiBox />
                    <h3>Kategori</h3>
                  </div>
                  <div className='category-slide'>
                    <HorizontalScroll>
                      <button
                        className={`btn-item ${
                          activeIndex === 0 ? "active" : "unactive"
                        }`}
                        itemId={0}
                        onClick={() => filter("All", 0)}>
                        Semua
                      </button>
                      {categories.length > 0
                        ? categories.map((item, index) => (
                            <button
                              itemId={index + 1}
                              className={`btn-item ${
                                activeIndex === index + 1
                                  ? "active"
                                  : "unactive"
                              }`}
                              onClick={() => filter(item.id, index + 1)}
                              key={index}>
                              {item.title}
                            </button>
                          ))
                        : ""}
                    </HorizontalScroll>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className='title margin-b20'>
                  <BiMessageSquareDetail />
                  <h3>Kursus Terbaru</h3>
                </div>
                {courses.length > 0 ? (
                  courses.slice(0, 3).map((courses, i) => (
                    <Col
                      key={i}
                      md={6}
                      lg={4}
                      className='learn-col'
                      onClick={() => {
                        props.history.push("/lms/learn/detail/" + courses.id);
                      }}>
                      <Card className='learn-card'>
                        {courses.image !== "" ? (
                          <Card.Img
                            className='learn-card-image no-padding'
                            variant='top'
                            src={courses.image}
                          />
                        ) : (
                          <Card.Img
                            className='learn-card-image'
                            variant='top'
                            src={
                              require(`../../../assets/images/courses${
                                i + 1
                              }.svg`).default
                            }
                          />
                        )}
                        <Card.Body>
                          <div className='card-body-top'>
                            {"Author: " + courses.created_by.name}
                          </div>
                          <div className='card-body-title'>
                            {courses.title.substring(0, 40) + "..."}
                          </div>
                          <div className='card-body-desc'>
                            {CompareDateNow(courses.closed_date) ? (
                              <span className={`u-status active`}>Open</span>
                            ) : (
                              <span className={`u-status not-active`}>
                                Closed
                              </span>
                            )}
                          </div>
                          <div className='card-body-bottom'>
                            <div className='user'>
                              <BiUser />
                              <span>4</span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : loading ? (
                  <Spinner animation='border' className='p-absolute left50' />
                ) : (
                  <span>Data tidak ditemukan</span>
                )}
              </Row>

              <Row>
                <Col xs={12} lg={12}>
                  <div className='title margin-t30 margin-b20'>
                    <BiConversation />
                    <h3>Diskusi Populer</h3>
                  </div>
                </Col>
                {forums.length > 0 ? (
                  forums.slice(0, 2).map((forum, i) => (
                    <Col key={i} md={6} lg={6} className='learn-col'>
                      <Card
                        className='learn-card clickable'
                        onClick={() => {
                          props.history.push("/lms/forum/detail/" + forum.id);
                        }}>
                        <Card.Body className='h100 max-h100'>
                          <div className='card-body-top'>
                            <div className='display-flex'>
                              <Image
                                id='preview'
                                alt='profile'
                                roundedCircle
                                className='w50p h50p padding0 object-fit-cover object-position-center relative'
                                width='50'
                                height='50'
                                src={createImageFromInitials(
                                  500,
                                  forum.created_by.name
                                    ? forum.created_by.name
                                    : "Unknown",
                                  getRandomColor()
                                )}
                              />
                              <div className='padding5'>
                                <span className='display-block'>
                                  {forum.created_by.name
                                    ? forum.created_by.name
                                    : "Unknown"}
                                </span>
                                <span>
                                  <Moment
                                    locale='id'
                                    format='dddd, D MMMM YYYY'
                                    withTitle>
                                    {forum.created_at}
                                  </Moment>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='card-body-title padding-tb10 min-h95p'>
                            <span className='text-label12'>Pembahasan</span>
                            <h6>{forum.title}</h6>
                          </div>
                          <Row>
                            <Col sm={12}>
                              <span
                                className='text-label12'
                                style={{
                                  color: getRandomColor()
                                }}>
                                {forum.category_id.title}
                              </span>
                            </Col>
                            <Col>
                              <span className='text-label12'>Responden</span>
                              <div className='relative w100 h30p'>
                                {forum.details.data.length > 0
                                  ? forum.details.data.map((val, i) =>
                                      i < 3 ? (
                                        <Image
                                          key={i}
                                          id='preview'
                                          alt='profile'
                                          roundedCircle
                                          className={
                                            i === 0
                                              ? "z-index3 w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                              : i === 1
                                              ? "z-index2 left10p w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                              : i === 2
                                              ? "z-index1 left20p w30p h30p padding0 object-fit-cover object-position-center p-absolute img-border-solid1p white"
                                              : "" + ""
                                          }
                                          width='30'
                                          height='30'
                                          src={createImageFromInitials(
                                            500,
                                            forum.created_by.name
                                              ? forum.created_by.name
                                              : "Unknown",
                                            getRandomColor()
                                          )}
                                        />
                                      ) : (
                                        <span className='p-absolute left50p top50 transform-t50 padding5 font-size12'>
                                          +{forum.details.data.length - 3}
                                        </span>
                                      )
                                    )
                                  : "0"}
                              </div>
                            </Col>
                            <Col>
                              <span className='text-label12'>Aktivitas</span>
                              <h6 className='font-size12'>
                                {forum.details.data.length > 0 ? (
                                  <Moment locale='id' fromNow>
                                    {getLatestActivityForum(forum.details.data)}
                                  </Moment>
                                ) : (
                                  <Moment locale='id' fromNow>
                                    {forum.created_at}
                                  </Moment>
                                )}
                              </h6>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : loading ? (
                  <Spinner animation='border' className='p-absolute left50' />
                ) : (
                  <span>Data tidak ditemukan</span>
                )}
              </Row>
            </Col>
            <Col xs={12} lg={4}>
              <div className='side-block'>
                {permissionByRole
                  ? permissionByRole.map((val, i) =>
                      val.key === "access_write_course" ? (
                        val.value === 1 ? (
                          <div key={i}>
                            <div className='learn-top'>
                              <span className='title-block'>
                                <BiBook /> Kursus Anda
                              </span>
                              <Row>
                                {coursesUser.length > 0 ? (
                                  coursesUser.slice(0, 5).map((item, i) => (
                                    <Col key={i} lg={12} className='learn-col'>
                                      <div className='learn-card-side'>
                                        <img
                                          variant='top'
                                          src={
                                            require("../../../assets/images/courses9.svg")
                                              .default
                                          }
                                        />
                                        <div className='body'>
                                          <div className='card-body-top'></div>
                                          <div className='card-body-title'>
                                            {item.title}
                                          </div>
                                          <div className='card-body-bottom'>
                                            <Moment
                                              locale='id'
                                              format='dddd, D MMMM YYYY'
                                              withTitle>
                                              {item.created_at}
                                            </Moment>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  ))
                                ) : loading ? (
                                  <Spinner animation='border' />
                                ) : (
                                  <span>Data tidak ditemukan</span>
                                )}
                              </Row>
                            </div>
                            <div className='hr'></div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    )
                  : ""}
                <div className='top-block margin-t30'>
                  <span className='title-block'>
                    <BsGraphUp /> Forum Anda
                  </span>
                  <div className='top-list'>
                    {forumsUser.length > 0 ? (
                      forumsUser.slice(0, 5).map((item, index) => (
                        <div
                          key={index}
                          className='top-item clickable'
                          onClick={() => {
                            props.history.push("/lms/forum/detail/" + item.id);
                          }}>
                          <div className='title'>{item.title}</div>
                          <div className='response'>
                            By {item.created_by.name}
                            {/* <MdModeComment /> 87 */}
                          </div>
                        </div>
                      ))
                    ) : loading ? (
                      <Spinner animation='border' />
                    ) : (
                      <span className='font-weight400'>
                        Data tidak ditemukan
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
