import React, { Component, useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BiChevronLeft, BsPlus } from "react-icons/all";
import Tags from "../../../components/Tags";
import { getToken } from "../../../resources/utils";
import { postApi } from "../../../services/axios";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./forum.scss";
import { TextEditor } from "../../../components/TextEditor";
import { ADD_FORUM, FORUM_ERROR } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { createForum } from "../../../resources/forum";
import { getAllCategory } from "../../../redux/actions/categoryAction";
import { Link } from "react-router-dom";

const AddForum = (props) => {
    const dispatch = useDispatch();
    const categoryList = useSelector((state) => state.category);
    const { categories } = categoryList;
    const [errorTitle, setErrorTitle] = useState("");
    const [error, setError] = useState("");
    const [errorName, setErrorName] = useState("");
    const [errorStatus, setErrorStatus] = useState("");
    const [errorCategory, setErrorCategory] = useState("");
    const [cate, setCate] = useState([]);

    const categoryChange = (e) => {
        setErrorCategory(null);
    };

    const goBack = () => {
        props.history.goBack();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);

        var val = await validation(data);

        if (val) {
            try {
                const res = await createForum(data);

                dispatch({
                    type: ADD_FORUM,
                    payload: res,
                });

                if (res.status !== "success" || res === undefined) {
                    if (res.status_code === 422) {
                        if (res.data.name) {
                            setErrorName("Judul harus diisi.");
                        }
                    } else {
                        setError(
                            "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                        );
                    }
                } else {
                    swal({
                        title: "Sukses!",
                        text: "Data diskusi sudah disimpan!",
                        icon: "success",
                        button: "Kembali!",
                    }).then(() => {
                        props.history.goBack();
                    });
                }
            } catch (e) {
                dispatch({
                    type: FORUM_ERROR,
                    payload: false,
                });
            }
        }
    };

    function validation(data) {
        var name = false;
        var status = false;
        var category = false;

        if (data.get("title") === "" || data.get("title") === null) {
            setErrorName("Nama harus diisi.");
            name = true;
        }

        if (data.get("status") === "" || data.get("status") === null) {
            setErrorStatus("Status belum dipilih.");
            status = true;
        }

        if (
            data.get("category_id") === "" ||
            data.get("category_id") === null
        ) {
            setErrorCategory("Kategory belum dipilih.");
            category = true;
        }

        if (name === false && status === false && category === false) {
            return true;
        }

        return false;
    }

    const removeAlert = (e) => {
        if (e.target.name === "title") {
            setErrorName("");
            setError("");
        }
    };

    const statusChange = (e) => {
        setErrorStatus(null);
    };

    useEffect(() => {
        dispatch(getAllCategory());
    }, [dispatch]);

    useEffect(() => {
        setCate(categories);
    }, [categories]);

    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsPlus />
                        <h3>Tambah Diskusi</h3>
                    </div>
                </div>
            </Container>
            <div>
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Judul</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            onFocus={removeAlert}
                                            placeholder="Masukkan judul diskusi..."
                                        />
                                        {errorName && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorName}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Deskripsi</Form.Label>
                                        <TextEditor />
                                    </Form.Group>

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}

                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>

                                    <Link
                                        to="/lms/forum"
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="card-form">
                                    <Form.Group>
                                        <Form.Label>Kategori</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="category_id"
                                            defaultValue=""
                                            onChange={categoryChange}
                                        >
                                            <option value="" disabled>
                                                Pilih kategori
                                            </option>
                                            {cate
                                                ? cate.map((cat, i) => (
                                                      <option
                                                          key={i}
                                                          defaultValue={cat.id}
                                                          value={cat.id}
                                                      >
                                                          {cat.title}
                                                      </option>
                                                  ))
                                                : ""}
                                        </Form.Select>

                                        {errorCategory && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorCategory}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            name="status"
                                            size="sm"
                                            defaultValue="active"
                                            onChange={statusChange}
                                        >
                                            <option disabled>Pilih...</option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                        </Form.Select>
                                        {errorStatus && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{errorStatus}
                                                </Form.Text>
                                            </>
                                        )}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};
export default AddForum;
