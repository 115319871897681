import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { BiChevronLeft, FiEdit3, BsKey } from "react-icons/all";
import { connect, useDispatch, useSelector } from "react-redux";
import { getToken, userPermissions } from "../../../resources/utils";
import { putApi } from "../../../services/axios";
import { userDetail } from "../../../redux/actions/userActions";
import "./user.scss";
import { getAllRoles } from "../../../redux/actions/roleAction";
import { Link } from "react-router-dom";
import { updateUser } from "../../../resources/user";
import {
    UPDATE_USER,
    USERS_ERROR,
    GET_USERS_DETAIL,
} from "../../../redux/types";
import { CheckWriteOnly } from "../../../resources/helpers";
import swal from "sweetalert";

const EditUser = (props) => {
    const dispatch = useDispatch();
    const dataUser = useSelector((state) => state.users);
    const { user } = dataUser;
    const [userData, setUserData] = useState([]);
    const getRoles = useSelector((state) => state.roles);
    const { roles } = getRoles;
    const [error, setError] = useState("");
    const [isUser, setIsUser] = useState(false);

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            name: userData.name,
            username: userData.username,
            email: userData.email,
            phone: userData.phone,
            role_id: userData.role_id,
            asal_sekolah: userData.asal_sekolah,
            mata_pelajaran: userData.mata_pelajaran,
            kelas: userData.kelas,
            status: userData.status,
        };

        try {
            const res = await updateUser(data, props.match.params.id);

            dispatch({
                type: UPDATE_USER,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                setError(
                    "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                );
            } else {
                swal({
                    title: "Sukses!",
                    text: "Data pengguna berhasil diubah!",
                    icon: "success",
                    button: "Kembali!",
                }).then(() => {
                    dispatch({
                        type: GET_USERS_DETAIL,
                        payload: false,
                    });
                    props.history.goBack();
                });
            }
        } catch (e) {
            dispatch({
                type: USERS_ERROR,
                payload: false,
            });
        }
    };

    const changePassword = () => {
        props.history.push("/lms/changepassword");
    };

    useEffect(() => {
        dispatch(userDetail(props.match.params.id));
        dispatch(getAllRoles());
    }, [dispatch, props]);

    useEffect(() => {
        setUserData(user);
    }, [user]);

    return (
        <div className="margin-t30 forum">
            <div className="top-header margin-b30">
                <div className="title">
                    <FiEdit3 />
                    <h3>Ubah Pengguna</h3>
                    {userData ? "" : <Spinner animation="border" />}
                </div>
                <button
                    onClick={changePassword}
                    className="btn-secondary w200p"
                >
                    <span className="btn-icon">
                        <BsKey />
                    </span>

                    <span className="btn-text">Ganti Password</span>
                </button>
            </div>
            <div className="">
                <Form>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Masukkan nama anda..."
                                            onChange={handleChange}
                                            value={userData.name}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>username</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            placeholder="Masukkan username anda..."
                                            onChange={handleChange}
                                            value={userData.username}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    No Telepon
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Masukkan judul topik anda..."
                                                    onChange={handleChange}
                                                    value={userData.phone}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    placeholder="Masukkan judul topik anda..."
                                                    onChange={handleChange}
                                                    value={userData.email}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <hr className="tiny" />
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Asal Sekolah</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="asal_sekolah"
                                            placeholder="Masukkan asal sekolah..."
                                            onChange={handleChange}
                                            value={userData.asal_sekolah}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Mata Pelajaran</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="mata_pelajaran"
                                            placeholder="Masukkan mata pelajaran yang diampu..."
                                            onChange={handleChange}
                                            value={userData.mata_pelajaran}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Kelas</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="kelas"
                                            placeholder="Masukkan kelas..."
                                            onChange={handleChange}
                                            value={userData.kelas}
                                        />
                                    </Form.Group>

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}

                                    <button
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>
                                    <Link
                                        to={
                                            CheckWriteOnly(
                                                userPermissions(),
                                                "access_user"
                                            )
                                                ? "/lms/user"
                                                : "/lms/profile/" +
                                                  props.match.params.id
                                        }
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            {CheckWriteOnly(
                                userPermissions(),
                                "access_user"
                            ) ? (
                                <Col>
                                    <div className="card-form">
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label>Peran</Form.Label>
                                            <Form.Select
                                                size="sm"
                                                name="role_id"
                                                onChange={handleChange}
                                                value={userData.role_id || ""}
                                            >
                                                <option value="" disabled>
                                                    Pilih...
                                                </option>
                                                {roles ? (
                                                    roles.map((roles) => (
                                                        <option
                                                            value={roles.id}
                                                        >
                                                            {roles.title}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="0" disabled>
                                                        Pilih...
                                                    </option>
                                                )}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label>Status</Form.Label>
                                            <Form.Select
                                                size="sm"
                                                name="status"
                                                onChange={handleChange}
                                                value={userData.status || ""}
                                            >
                                                <option value="" disabled>
                                                    Pilih...
                                                </option>
                                                <option value="active">
                                                    Aktif
                                                </option>
                                                <option value="not-active">
                                                    Tidak Aktif
                                                </option>
                                                {/* <option value="unverified">
                                                    Belum Diverifikasi
                                                </option> */}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </Col>
                            ) : (
                                ""
                            )}
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default EditUser;
