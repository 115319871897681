import React, { useState } from "react";
import login from "../../assets/images/login.svg";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import "./login.scss";
import {
    ImGoogle,
    AiOutlineLoading3Quarters,
    ImFacebook,
    IoMail,
    IoLockClosed,
} from "react-icons/all";
import {
    setRemember,
    setStatus,
    setToken,
    signin,
    userId,
} from "../../resources/utils";
import { auth, google, facebook } from "../../services/firebase";
import { Link } from "react-router-dom";
import { postApi } from "../../services/axios";
import { useEffect } from "react";

function Login(props) {
    const [loading, setLoading] = useState(false);
    const username = useFormInput("");
    const password = useFormInput("");
    const [remember, setRem] = useState(false);
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    const handleLogin = (event) => {
        if (emailValidation(username.value) && password.value != "") {
            setError(null);
            setEmailError(null);
            setPasswordError(null);
    
            event.preventDefault();
            setLoading(true);
    
            const header = {};
    
            const data = {
                email: username.value,
                password: password.value,
            };
    
            postApi("auth/login", data, header).then((result) => {
                setLoading(false);
                if (result.status_code === 200) {
                    if (remember === true) {
                        setRemember(remember);
                    }
    
                    setToken(result.data.token);
                    setStatus(result.data.status);
                    signin();
    
                    if (result.data.status === "active") {
                        props.history.push("/lms/dashboard");
                    } else {
                        props.history.push("/lms/profile/" + userId());
                    }
                } else {
                    if (result.status_code === 422) {
                        if (result.data.email) {
                            setEmailError(result.data.email);
                        }
    
                        if (result.data.password) {
                            setPasswordError(result.data.password);
                        }
                    } else {
                        setError(result.message);
                    }
                }
            });
        } else {
            if (password.value == "") setPasswordError("The password field is required.")
            if (username.value == "") setEmailError("The email field is required.")
        }
    };

    const loginGoogle = () => {
        auth.signInWithPopup(google)
            .then((res) => {
                this.setState({
                    isAuth: true,
                    account: res.additionalUserInfo.profile,
                });
            })
            .catch((err) => {
                this.setState({
                    isError: err,
                });
            });
    };

    const logoutGoogle = () => {
        auth.signOut()
            .then(() => {
                this.setState({
                    isAuth: false,
                    account: [],
                });
            })
            .catch((err) => {
                this.setState({
                    isError: err,
                });
            });
    };

    const loginFacebook = () => {
        auth.signInWithPopup(facebook)
            .then((res) => {
                this.setState({
                    isAuth: true,
                    account: res.additionalUserInfo.profile,
                });
            })
            .catch((err) => {
                this.setState({
                    isError: err,
                });
            });
    };

    const logoutFacebook = () => {
        auth.signOut()
            .then(() => {
                this.setState({
                    isAuth: false,
                    account: [],
                });
            })
            .catch((err) => {
                this.setState({
                    isError: err,
                });
            });
    };

    const removeAlert = (e) => {
        if (e.target.id === "password") {
            setPasswordError(null);
            setError(null);
        } else if (e.target.id === "username") {
            setEmailError(null);
            setError(null);
        }
    };

    const checkRemember = (e) => {
        if (e.target.checked === true) {
            setRem(true);
        } else {
            setRem(false);
        }
    };

    const emailValidation = (email) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){
            setEmailError("Email is not valid")
            return false;
        }
        return true;
    }

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleLogin(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    return (
        <>
            <Container className="padding-tb30">
                <h1 className="text-center">Masuk</h1>
                <p className="text-center">
                    Masuk dengan akun anda untuk mengakses forum
                </p>
            </Container>
            <Container>
                <Card className="padding30 margin-b50">
                    <Row>
                        <Col lg={7} className="relative d-none d-lg-block">
                            <img alt="Content login" src={login} />
                        </Col>
                        <Col lg={5} sm={12}>
                            <Row>
                                {/* <Col sm={12}>
                                    <button
                                        onClick={loginGoogle}
                                        className="btn-white btn-w100 text-center no-border w100"
                                    >
                                        <ImGoogle className="icon-btn left" />
                                        Masuk dengan Google
                                    </button>
                                    <button
                                        onClick={loginFacebook}
                                        className="btn-white btn-w100 text-center no-border w100"
                                    >
                                        <ImFacebook className="icon-btn left" />
                                        Masuk dengan Facebook
                                    </button>
                                    <p className="line-lr text-center padding-tb30 margin-p75">
                                        atau
                                    </p>
                                </Col> */}
                                <Col sm={12}>
                                    <Form onSubmit={handleLogin}>
                                        <div className="margin-p75">
                                            {emailError && (
                                                <>
                                                    <Form.Text className="text-alert">
                                                        *{emailError}
                                                    </Form.Text>
                                                </>
                                            )}
                                            {error && (
                                                <>
                                                    <Form.Text className="text-alert">
                                                        *{error}
                                                    </Form.Text>
                                                </>
                                            )}
                                            <div className="input-box">
                                                <div className="padding-tb5">
                                                    <input
                                                        id="username"
                                                        type="text"
                                                        {...username}
                                                        className="input-form"
                                                        placeholder="kelaslentera@mail.id"
                                                        onFocus={removeAlert}
                                                        autoComplete="off"
                                                    />
                                                    <label className="input-label">
                                                        Email
                                                    </label>
                                                    <IoMail className="icon-btnl left" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="margin-p75">
                                            {passwordError && (
                                                <>
                                                    <Form.Text className="text-alert">
                                                        *{passwordError}
                                                    </Form.Text>
                                                </>
                                            )}
                                            <div className="input-box">
                                                <div className="padding-tb5">
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        {...password}
                                                        className="input-form input-password"
                                                        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                                        onFocus={removeAlert}
                                                        autoComplete="off"
                                                    />
                                                    <label className="input-label">
                                                        Password
                                                    </label>
                                                    <IoLockClosed className="icon-btnl left" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="margin-p75">
                                            <Row>
                                                <Col sm={12} lg={6}>
                                                    <label className="input-check-box">
                                                        Ingatkan saya
                                                        <input
                                                            type="checkbox"
                                                            onChange={
                                                                checkRemember
                                                            }
                                                            name="remember"
                                                            className="input-check"
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </Col>
                                                <Col
                                                    sm={12}
                                                    lg={6}
                                                    className="text-right"
                                                >
                                                    {/* <Link
                                                        to="/forgot-password"
                                                        className="btn-clear underline no-border"
                                                    >
                                                        Lupa password?
                                                    </Link> */}
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <a
                                                onClick={handleLogin}
                                                className="btn-primary-lg btn-w100 text-center"
                                            >
                                                {loading === true ? (
                                                    <div>
                                                        <AiOutlineLoading3Quarters className="loading-spin" />
                                                    </div>
                                                ) : (
                                                    "Masuk"
                                                )}
                                            </a>
                                        </div>
                                        <div className="margin-p75 text-center">
                                            <p>
                                                Belum memiliki akun?{" "}
                                                <Link
                                                    to="/register"
                                                    className="btn-clear underline text-bold600 no-border"
                                                >
                                                    Daftar
                                                </Link>
                                            </p>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return {
        value,
        onChange: handleChange,
    };
};

export default Login;
