import React, { useState } from "react";
import login from "../../assets/images/login.svg";
import { Row, Col, Container, Card } from "react-bootstrap";
import "./activity.scss";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { getAPI } from "../../resources/utilities";
import { BsClock } from "react-icons/all";
import Moment from "react-moment";
import "moment/locale/id";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useRef } from "react";
import { getProgramSettings } from "../../redux/actions/settingsAction";

const Activity = (props) => {
    const dispatch = useDispatch();
    const settingList = useSelector((state) => state.settings);
    const { id } = useParams();
    const { program } = settingList;
    const [data, setData] = useState([]);
    const [posts, setPosts] = useState([]);
    const [category, setCategory] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [show, setShow] = useState(false);

    function removeTag(data) {
        if (data === null || data === "") return false;
        else data = data.toString();

        return data.replace(/(<([^>]+)>)/gi, "");
    }

    const getCategory = () => {
        var id = props.match.params.id;

        if (id === "all") {
            id = 1;
        }
        var url = `front/category/detail/${id}`;
        getAPI(url).then((data) => {
            setCategory(data.data);
        });
    };

    const getData = () => {
        var id = props.match.params.id;

        if (id === "all") {
            id = "";
        } else {
            id = "&category=" + id;
        }
        var url =
            `front/post/list?type=post&limit=10${id}&order_by=post_date&order_as=desc&page=` +
            offset;
        getAPI(url).then((data) => {
            setPosts(data.data);
            setPageCount(
                Math.ceil(
                    data.meta.pagination.total / data.meta.pagination.per_page
                )
            );
        });
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected + 1);

        console.log(selected, offset);

        setOffset(offset);
        getData();
    };

    useEffect(() => {
        dispatch(getProgramSettings());
    }, [dispatch]);

    useEffect(() => {
        setData(program);
        getCategory();
        getData();
    }, [program, id]);

    return (
        <div className="activity">
            <Container className="padding-tb30">
                <h1 className="text-center">
                    {category.title === "Activity"
                        ? data
                            ? data.map((val, i) =>
                                  val.key === "program-title" ? val.value : ""
                              )
                            : ""
                        : category.title}
                </h1>
                <p className="text-center">
                    {category.title === "Activity"
                        ? data
                            ? data.map((val, i) =>
                                  val.key === "program-desc" ? val.value : ""
                              )
                            : ""
                        : ""}
                </p>
                {posts.length === 0 ? (
                    <div className="display-flex center-horizontal">
                        Konten tidak ditemukan
                    </div>
                ) : (
                    ""
                )}
            </Container>
            <Container>
                <Card className="padding30 margin-b50 no-background">
                    {posts
                        ? posts.map((post, i) =>
                              i === 0 ? (
                                  <Row key={i}>
                                      <Col md={6} sm={12}>
                                          <img
                                              className="img-fit-cover img-content top-content"
                                              src={post.image}
                                              alt="Top Content"
                                          />
                                      </Col>
                                      <Col md={6} sm={12}>
                                          <div className="relative w100 h350p">
                                              <p className="text-date">
                                                  <BsClock />
                                                  <Moment
                                                      locale="id"
                                                      format="dddd, D MMMM YYYY"
                                                      withTitle
                                                  >
                                                      {post.post_date}
                                                  </Moment>
                                              </p>
                                              <h3 className="text-elipsis-single">
                                                  {post.title}
                                              </h3>
                                              <div className="text-elipsis-multi line8">
                                                  <p
                                                      dangerouslySetInnerHTML={{
                                                          __html: removeTag(
                                                              post.description
                                                          ),
                                                      }}
                                                  ></p>
                                              </div>
                                              <Link
                                                  to={
                                                      "/activity/detail/" +
                                                      post.id
                                                  }
                                                  className="btn-primary p-absolute left0 bottom0"
                                              >
                                                  Selengkapnya
                                              </Link>
                                          </div>
                                      </Col>
                                  </Row>
                              ) : (
                                  ""
                              )
                          )
                        : ""}
                </Card>
                <Row>
                    {posts
                        ? posts.map((post, i) =>
                              i > 0 ? (
                                  <Col md={4} sm={12} key={i}>
                                      <Link to={"/activity/detail/" + post.id}>
                                          <Card className="padding30  no-background margin-b10">
                                              <Row>
                                                  <Col md={12} xs={5}>
                                                      <img
                                                          className="img-fit-cover img-content btm-content"
                                                          src={post.image}
                                                          alt="Top Content"
                                                      />
                                                  </Col>
                                                  <Col md={12} xs={7}>
                                                      <div className="margin-tb10 mbl-margin0">
                                                          <p className="mbl-margin0 text-date">
                                                              <BsClock />
                                                              <Moment
                                                                  locale="id"
                                                                  format="dddd, D MMMM YYYY"
                                                                  withTitle
                                                              >
                                                                  {
                                                                      post.post_date
                                                                  }
                                                              </Moment>
                                                          </p>
                                                          <h4 className="text-elipsis-single">
                                                              {post.title}
                                                          </h4>
                                                          <div className="text-elipsis-multi line4">
                                                              <p
                                                                  dangerouslySetInnerHTML={{
                                                                      __html: removeTag(
                                                                          post.description
                                                                      ),
                                                                  }}
                                                                  className="d-none d-md-block"
                                                              ></p>
                                                          </div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </Card>
                                      </Link>
                                  </Col>
                              ) : (
                                  ""
                              )
                          )
                        : ""}
                </Row>
                <div className="relative h100p margin-b50">
                    <div className="p-absolute mobile left50 top50 transform-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination margin-b0"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Activity;
