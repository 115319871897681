import React, { useEffect } from "react";
import {
    Col,
    Container,
    Row,
    Image,
    Card,
    ListGroup,
    Spinner,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
    BsPencilSquare,
    BsClockHistory,
    BsPeople,
    FaPen,
    HiMinusCircle,
} from "react-icons/all";
import { Link } from "react-router-dom";
import "./user.scss";
import { getAPI } from "../../../resources/utilities";
import { getUsers } from "../../../resources/user";
import { clearUser, getAllUsers } from "../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { deleteApi } from "../../../services/axios";
import { getToken } from "../../../resources/utils";
import { RandomColor } from "../../../components/Color";
import swal from "sweetalert";
import { useState } from "react";
import { getLogUser } from "../../../redux/actions/logAction";
import Moment from "react-moment";
import "moment-timezone";
import { capitalizeFirstLetter } from "../../../resources/helpers";

const User = (props) => {
    const dispatch = useDispatch();
    const userList = useSelector((state) => state.users);
    const { users } = userList;
    const logList = useSelector((state) => state.log);
    const { logs } = logList;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(clearUser());
        dispatch(getLogUser("user"));
    }, [dispatch]);

    const createUser = () => {
        props.history.push("/lms/user/create");
    };

    const handleDelete = (id) => {
        swal({
            title: "Apakah anda yakin?",
            text: "Data yang sudah dihapus tidak dapat dikembalikan!",
            icon: "warning",
            buttons: {
                cancel: "Batal",
                danger: {
                    text: "Hapus",
                    value: true,
                },
            },
            dangerMode: true,
        }).then((value) => {
            if (value) {
                const url = `user/delete/${id}`;
                const token = "Bearer " + getToken();
                const headers = {
                    headers: {
                        Authorization: token,
                    },
                };

                deleteApi(url, headers)
                    .then((res) => {
                        if (res.status !== "error") {
                            dispatch(getAllUsers());
                            swal("Data pengguna berhasil dihapus!", {
                                icon: "success",
                            });
                        } else {
                            swal("Data pengguna gagal dihapus!", {
                                icon: "error",
                                buttons: { cancel: "Kembali" },
                            });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    const columns = [
        {
            name: "Nama",
            sortable: true,
            reorder: true,
            cell: (user) => (
                <Link to={"/lms/user/detail/" + user.id}>
                    {capitalizeFirstLetter(user.name)}
                </Link>
            ),
        },
        {
            name: "Peran",
            cell: (user) => <RandomColor name={user.role_name} />,
            sortable: true,
            reorder: true,
        },
        {
            name: "Status",
            cell: (user) => (
                <span className={`u-status ${user.status}`}>
                    {user.status === "active" ? "Aktif" : "Tidak Aktif"}
                </span>
            ),
            sortable: true,
            width: "120px",
        },
        {
            name: "Dibuat pada",
            selector: (user) => user.created_at,
            cell: (user) => user.created_at,
            sortable: true,
        },
        {
            width: "80px",
            cell: (user) => (
                <span>
                    <Link className="btn-edit" to={"user/edit/" + user.id}>
                        <FaPen />
                    </Link>{" "}
                    {user.role_id !== 1 ? (
                        <>
                            |
                            <a
                                className="btn-delete"
                                onClick={() => handleDelete(user.id)}
                            >
                                <HiMinusCircle />
                            </a>
                        </>
                    ) : (
                        ""
                    )}
                </span>
            ),
            right: true,
        },
    ];

    useEffect(() => {
        setTimeout(
            function () {
                setLoading(false);
            }.bind(this),
            2000
        );
    }, [users]);

    return (
        <div className="user margin-t30">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsPeople />
                        <h3>Pengguna</h3>
                    </div>
                    <button onClick={createUser} className="btn-transform">
                        <span className="btn-icon">
                            <BsPencilSquare />
                        </span>

                        <span className="btn-text">Tambah Baru</span>
                    </button>
                </div>
                <div className="user-body">
                    <Row>
                        <Col lg={8} xs={12}>
                            <Card className="min-h95p padding10 margin-b30">
                                {users ? (
                                    <DataTable
                                        columns={columns}
                                        data={users}
                                        defaultSortFieldId={4}
                                        defaultSortAsc={false}
                                        pagination
                                        paginationRowsPerPageOptions={[
                                            5, 10, 25, 50, 100,
                                        ]}
                                    />
                                ) : loading ? (
                                    <Spinner
                                        animation="border"
                                        className="p-absolute left50"
                                    />
                                ) : (
                                    <span className="p-absolute left50 transform-l50">
                                        Data tidak ditemukan
                                    </span>
                                )}
                            </Card>
                        </Col>
                        <Col lg={4} xs={12}>
                            <div className="side-block">
                                <Link to={"/lms/log"}>
                                    <div className="card-title border-r20">
                                        <span className="title-block">
                                            <BsClockHistory /> Aktivitas
                                        </span>
                                    </div>
                                    <ListGroup>
                                        {logs ? (
                                            logs.map((val, i) => (
                                                <ListGroup.Item key={i}>
                                                    <div className="padding-lr10">
                                                        <span className="text-label12">
                                                            <Moment
                                                                locale="id"
                                                                fromNow
                                                            >
                                                                {val.created_at}
                                                            </Moment>
                                                        </span>
                                                        <br />
                                                        {val.value}
                                                    </div>
                                                </ListGroup.Item>
                                            ))
                                        ) : (
                                            <Spinner
                                                animation="border"
                                                className="p-absolute"
                                            />
                                        )}
                                    </ListGroup>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-footer margin-b30"></div>
            </Container>
        </div>
    );
};

export default User;
