import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, ListGroup, Spinner } from "react-bootstrap";
import { BsClockHistory } from "react-icons/all";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Moment from "react-moment";
import "moment-timezone";
import { getLogUser } from "../../../redux/actions/logAction";

const Log = (props) => {
    const dispatch = useDispatch();
    const logList = useSelector((state) => state.log);
    const { logs } = logList;

    useEffect(() => {
        dispatch(getLogUser());
    }, [dispatch]);

    const column = [
        {
            name: "Aktivitas",
            cell: (log) => (
                <>
                    <div>{log.value}</div>
                </>
            ),
            sortable: true,
        },
        {
            cell: (log) => (
                <span className="w100">
                    <Moment className="right" fromNow>
                        {log.created_at}
                    </Moment>
                </span>
            ),
        },
    ];

    return (
        <div className="role margin-t30">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsClockHistory />
                        <h3>Aktivitas Pengguna </h3>
                    </div>
                </div>
                <div className="user-body">
                    <Row>
                        <Col xs={12}>
                            <Card className="padding10 margin-b30">
                                {logs ? (
                                    <DataTable
                                        columns={column}
                                        data={logs}
                                        defaultSortFieldId={1}
                                        pagination
                                        paginationRowsPerPageOptions={[
                                            5, 10, 25, 50, 100,
                                        ]}
                                    />
                                ) : (
                                    <Spinner
                                        animation="border"
                                        className="p-absolute left50"
                                    />
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="p-footer margin-b30"></div>
            </Container>
        </div>
    );
};

export default Log;
