import axios from "axios";
import { getToken } from "./utils";

var data = [];

export const getAllSettings = async (type) => {
    const path =
        "https://api.kelaslenterakuark.id/api/v1/front/setting/list?status=active&limit=1000&type=" +
        type;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    try {
        const response = await axios.get(path, headers);

        if (response.data.status_code === 200) {
            data = response.data.data;
        } else {
            data = null;
        }
    } catch (error) {
        return error.response.data;
    }

    return data;
};
