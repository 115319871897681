import {
  createForum,
  createResponse,
  dislike,
  getForumDetail,
  getForums,
  getForumsUser,
  getResponseByForumId,
  like,
  updateForum
} from "../../resources/forum";
import {
  ADD_FORUM,
  ADD_RESPONSE,
  EDIT_FORUM,
  ERROR_RESPONSE,
  FORUM_ERROR,
  GET_FORUM,
  GET_FORUM_DETAIL,
  GET_FORUM_USER_CREATED,
  GET_RESPONSE,
  UPDATE_DISLIKES,
  UPDATE_LIKES
} from "../types";

export const getAllForum = (id) => async (dispatch) => {
  try {
    const res = await getForums(id);
    dispatch({
      type: GET_FORUM,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: FORUM_ERROR,
      payload: console.log(e)
    });
  }
};

export const getAllForumUser = () => async (dispatch) => {
  try {
    const res = await getForumsUser();
    dispatch({
      type: GET_FORUM_USER_CREATED,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: FORUM_ERROR,
      payload: console.log(e)
    });
  }
};

export const getForumsID = (id) => async (dispatch) => {
  try {
    const res = await getForumDetail(id);
    dispatch({
      type: GET_FORUM_DETAIL,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: FORUM_ERROR,
      payload: console.log(e)
    });
  }
};

export const addForum = (data) => async (dispatch) => {
  try {
    const res = await createForum(data);
    dispatch({
      type: ADD_FORUM,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: FORUM_ERROR,
      payload: false
    });
  }
};

export const addResponse = (data, id) => async (dispatch) => {
  try {
    const res = await createResponse(data, id);
    dispatch({
      type: ADD_RESPONSE,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: ERROR_RESPONSE,
      payload: false
    });
  }
};

export const editForum = (data, id) => async (dispatch) => {
  try {
    const res = await updateForum(data, id);
    dispatch({
      type: EDIT_FORUM,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: FORUM_ERROR,
      payload: false
    });
  }
};

export const updateLikes = (id) => async (dispatch) => {
  try {
    const res = await like(id);

    dispatch({
      type: UPDATE_LIKES,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: ERROR_RESPONSE,
      payload: false
    });
  }
};

export const updateDislikes = (id) => async (dispatch) => {
  try {
    const res = await dislike(id);

    dispatch({
      type: UPDATE_DISLIKES,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: ERROR_RESPONSE,
      payload: false
    });
  }
};

export const getResponse = (id) => async (dispatch) => {
  try {
    const res = await getResponseByForumId(id);

    dispatch({
      type: GET_RESPONSE,
      payload: res
    });
  } catch (e) {
    dispatch({
      type: ERROR_RESPONSE,
      payload: false
    });
  }
};
