import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Card,
    ListGroup,
    Spinner,
    Form,
} from "react-bootstrap";
import {
    BiChevronLeft,
    BsClockHistory,
    BsCardChecklist,
} from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
    getDetailPermissionById,
    getGroupPermissions,
} from "../../../redux/actions/permissionAction";
import { getAllRoles } from "../../../redux/actions/roleAction";
import {
    ERROR_PERMISSION,
    GET_PERMISSION_DETAIL_ROLE_ID,
    UPDATE_PERMISSION,
} from "../../../redux/types";
import {
    setupPermission,
    updatePermission,
} from "../../../resources/permission";

const Permission = (props) => {
    const [value, setValue] = useState();
    const dispatch = useDispatch();
    const permissionList = useSelector((state) => state.permissions);
    const { group, permissionByRole } = permissionList;
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [kursus, setKursus] = useState(true);
    const [forum, setForum] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let dataSelected = data.filter((val) => val.value === 1);

        if (dataSelected.length === 0) {
            setError("Belum memilih satupun hak akses");
        } else {
            const param = {
                id: props.match.params.id,
                perm: data,
            };

            try {
                const res = await setupPermission(param, props.match.params.id);

                dispatch({
                    type: UPDATE_PERMISSION,
                    payload: res,
                });

                if (res.status !== "success" || res === undefined) {
                    setError(
                        "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                    );
                } else {
                    swal({
                        title: "Sukses!",
                        text: "Data kategori berhasil diubah!",
                        icon: "success",
                        button: "Kembali!",
                    }).then(() => {
                        dispatch({
                            type: GET_PERMISSION_DETAIL_ROLE_ID,
                            payload: false,
                        });
                        props.history.goBack();
                    });
                }
            } catch (error) {
                dispatch({
                    type: ERROR_PERMISSION,
                    payload: false,
                });
            }
        }
    };

    useEffect(() => {
        dispatch(getAllRoles());
        dispatch(getGroupPermissions());
        dispatch(getDetailPermissionById(props.match.params.id));
    }, [dispatch]);

    useEffect(() => {
        permissionByRole ? setValue(permissionByRole) : setValue(group);

        if (permissionByRole) {
            permissionByRole.map((val) =>
                val.key === "access_course"
                    ? val.value === 1
                        ? setKursus(false)
                        : setKursus(true)
                    : ""
            );
            permissionByRole.map((val) =>
                val.key === "access_forum"
                    ? val.value === 1
                        ? setForum(false)
                        : setForum(true)
                    : ""
            );

            permissionByRole.map((val) =>
                setData((data) => [
                    ...data,
                    {
                        key: val.key,
                        name: val.title,
                        value: val.value,
                    },
                ])
            );
        } else {
            if (group && data.length < group.length) {
                group.map((val) =>
                    setData((data) => [
                        ...data,
                        {
                            key: val.key,
                            name: val.title,
                            value: 0,
                        },
                    ])
                );
            }
        }
    }, [group, permissionByRole]);

    return (
        <>
            <div className="margin-t30 forum">
                <Container fluid>
                    <div className="top-header margin-b30">
                        <div className="title">
                            <BsCardChecklist />
                            <h3>Pengaturan Hak Akses</h3>
                            {value ? "" : <Spinner animation="border" />}
                        </div>
                    </div>
                </Container>
                <div className="">
                    <Form onSubmit={handleSubmit}>
                        <Container fluid>
                            <Row>
                                <Col lg={8}>
                                    <div className="card-form">
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                        >
                                            <div>
                                                <Row>
                                                    <h5>Menu</h5>
                                                    {value
                                                        ? value.map(
                                                              (item, index) =>
                                                                  item.type ===
                                                                  "menu" ? (
                                                                      <Col
                                                                          md="4"
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          <Form.Check
                                                                              key={
                                                                                  index
                                                                              }
                                                                              inline
                                                                              type="checkbox"
                                                                              id={
                                                                                  item.key
                                                                              }
                                                                              name={
                                                                                  item.title
                                                                              }
                                                                              label={
                                                                                  item.title
                                                                              }
                                                                              defaultChecked={
                                                                                  item.value
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) => {
                                                                                  setData(
                                                                                      data.filter(
                                                                                          (
                                                                                              data
                                                                                          ) =>
                                                                                              data.key !==
                                                                                              e
                                                                                                  .target
                                                                                                  .id
                                                                                      )
                                                                                  );

                                                                                  if (
                                                                                      e
                                                                                          .target
                                                                                          .id ===
                                                                                      "access_course"
                                                                                  ) {
                                                                                      if (
                                                                                          e
                                                                                              .target
                                                                                              .checked
                                                                                      ) {
                                                                                          setKursus(
                                                                                              false
                                                                                          );
                                                                                      } else {
                                                                                          setKursus(
                                                                                              true
                                                                                          );
                                                                                      }
                                                                                  }

                                                                                  if (
                                                                                      e
                                                                                          .target
                                                                                          .id ===
                                                                                      "access_forum"
                                                                                  ) {
                                                                                      if (
                                                                                          e
                                                                                              .target
                                                                                              .checked
                                                                                      ) {
                                                                                          setForum(
                                                                                              false
                                                                                          );
                                                                                      } else {
                                                                                          setForum(
                                                                                              true
                                                                                          );
                                                                                      }
                                                                                  }

                                                                                  setData(
                                                                                      (
                                                                                          value
                                                                                      ) => [
                                                                                          ...value,
                                                                                          {
                                                                                              key: e
                                                                                                  .target
                                                                                                  .id,
                                                                                              name: e
                                                                                                  .target
                                                                                                  .name,
                                                                                              value: e
                                                                                                  .target
                                                                                                  .checked
                                                                                                  ? 1
                                                                                                  : 0,
                                                                                          },
                                                                                      ]
                                                                                  );

                                                                                  setError();
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  ) : (
                                                                      ""
                                                                  )
                                                          )
                                                        : ""}
                                                </Row>
                                            </div>
                                            <div className="margin-t30">
                                                <Row>
                                                    <h5>Kursus</h5>
                                                    {value
                                                        ? value.map(
                                                              (item, index) =>
                                                                  item.type ===
                                                                  "course" ? (
                                                                      <Col
                                                                          md="4"
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          <Form.Check
                                                                              key={
                                                                                  index
                                                                              }
                                                                              inline
                                                                              type="checkbox"
                                                                              id={
                                                                                  item.key
                                                                              }
                                                                              name={
                                                                                  item.title
                                                                              }
                                                                              label={
                                                                                  item.title
                                                                              }
                                                                              defaultChecked={
                                                                                  item.value
                                                                              }
                                                                              disabled={
                                                                                  kursus
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) => {
                                                                                  setData(
                                                                                      data.filter(
                                                                                          (
                                                                                              data
                                                                                          ) =>
                                                                                              data.key !==
                                                                                              e
                                                                                                  .target
                                                                                                  .id
                                                                                      )
                                                                                  );

                                                                                  setData(
                                                                                      (
                                                                                          value
                                                                                      ) => [
                                                                                          ...value,
                                                                                          {
                                                                                              key: e
                                                                                                  .target
                                                                                                  .id,
                                                                                              name: e
                                                                                                  .target
                                                                                                  .name,
                                                                                              value: e
                                                                                                  .target
                                                                                                  .checked
                                                                                                  ? 1
                                                                                                  : 0,
                                                                                          },
                                                                                      ]
                                                                                  );

                                                                                  setError();
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  ) : (
                                                                      ""
                                                                  )
                                                          )
                                                        : ""}
                                                </Row>
                                            </div>
                                            <div className="margin-t30">
                                                <Row>
                                                    <h5>Forum</h5>
                                                    {value
                                                        ? value.map(
                                                              (item, index) =>
                                                                  item.type ===
                                                                  "forum" ? (
                                                                      <Col
                                                                          md="4"
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          <Form.Check
                                                                              key={
                                                                                  index
                                                                              }
                                                                              inline
                                                                              type="checkbox"
                                                                              id={
                                                                                  item.key
                                                                              }
                                                                              name={
                                                                                  item.title
                                                                              }
                                                                              label={
                                                                                  item.title
                                                                              }
                                                                              defaultChecked={
                                                                                  item.value
                                                                              }
                                                                              disabled={
                                                                                  forum
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) => {
                                                                                  setData(
                                                                                      data.filter(
                                                                                          (
                                                                                              data
                                                                                          ) =>
                                                                                              data.key !==
                                                                                              e
                                                                                                  .target
                                                                                                  .id
                                                                                      )
                                                                                  );

                                                                                  setData(
                                                                                      (
                                                                                          value
                                                                                      ) => [
                                                                                          ...value,
                                                                                          {
                                                                                              key: e
                                                                                                  .target
                                                                                                  .id,
                                                                                              name: e
                                                                                                  .target
                                                                                                  .name,
                                                                                              value: e
                                                                                                  .target
                                                                                                  .checked
                                                                                                  ? 1
                                                                                                  : 0,
                                                                                          },
                                                                                      ]
                                                                                  );

                                                                                  setError();
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  ) : (
                                                                      ""
                                                                  )
                                                          )
                                                        : ""}
                                                </Row>
                                            </div>
                                            <div className="margin-t30">
                                                <Row>
                                                    <h5>Lainnya</h5>
                                                    {value
                                                        ? value.map(
                                                              (item, index) =>
                                                                  item.type ===
                                                                  "feature" ? (
                                                                      <Col
                                                                          md="4"
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          <Form.Check
                                                                              key={
                                                                                  index
                                                                              }
                                                                              inline
                                                                              type="checkbox"
                                                                              id={
                                                                                  item.key
                                                                              }
                                                                              name={
                                                                                  item.title
                                                                              }
                                                                              label={
                                                                                  item.title
                                                                              }
                                                                              defaultChecked={
                                                                                  item.value
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) => {
                                                                                  setData(
                                                                                      data.filter(
                                                                                          (
                                                                                              data
                                                                                          ) =>
                                                                                              data.key !==
                                                                                              e
                                                                                                  .target
                                                                                                  .id
                                                                                      )
                                                                                  );

                                                                                  setData(
                                                                                      (
                                                                                          value
                                                                                      ) => [
                                                                                          ...value,
                                                                                          {
                                                                                              key: e
                                                                                                  .target
                                                                                                  .id,
                                                                                              name: e
                                                                                                  .target
                                                                                                  .name,
                                                                                              value: e
                                                                                                  .target
                                                                                                  .checked
                                                                                                  ? 1
                                                                                                  : 0,
                                                                                          },
                                                                                      ]
                                                                                  );

                                                                                  setError();
                                                                              }}
                                                                          />
                                                                      </Col>
                                                                  ) : (
                                                                      ""
                                                                  )
                                                          )
                                                        : ""}
                                                </Row>
                                            </div>
                                        </Form.Group>
                                        {error && (
                                            <>
                                                <Form.Text className="text-alert">
                                                    *{error}
                                                </Form.Text>
                                                <br />
                                            </>
                                        )}

                                        <button className="btn btn-primary">
                                            Simpan
                                        </button>
                                        <Link
                                            to="/lms/role"
                                            className="btn btn-clear-all display-inline-block"
                                        >
                                            <BiChevronLeft /> Kembali
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={4} xs={12}>
                                    <div className="side-block">
                                        <div className="card-title border-r20">
                                            <span className="title-block">
                                                <BsClockHistory /> Aktivitas
                                            </span>
                                        </div>
                                        <ListGroup>
                                            <ListGroup.Item>
                                                13:00 - Admin membuat user baru
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                13:20 - Admin update user role
                                                pada user A
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                13:50 - Admin menghapus user C
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default Permission;
