import { getAllCategory } from "../redux/actions/categoryAction";
import { deleteApi, getApi, postApi, putApi } from "../services/axios";
import { getToken } from "./utils";

var data = [];

export const getCategories = async (status, limit, page, search) => {
    const path = "category/list?status=";
    // (status ? status : "") +
    // "&limit=" +
    // (limit ? limit : "") +
    // "&page=" +
    // (page ? page : "") +
    // "&search=" +
    // (search ? search : "");

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const deleteCategory = async (id) => {
    const path = `category/delete/` + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await deleteApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
            getCategories();
        } else {
            data = null;
        }
    });

    return data;
};

export const getCategoryDetail = async (id) => {
    const path = "category/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const createCategory = async (data) => {
    const path = "category/create";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const updateCategory = async (data, id) => {
    const path = "category/update/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};
