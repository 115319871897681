import React, { useState } from "react";
import reset from "../../assets/images/reset.svg";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import "./forgot.scss";
import { BsKeyFill, AiOutlineLoading3Quarters, BsKey } from "react-icons/all";
import { Link } from "react-router-dom";
import { postApi } from "../../services/axios";

function Reset(props) {
    const [loading, setLoading] = useState(false);
    const password = useFormInput("");
    const confirmPassword = useFormInput("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleForgot = (event) => {
        setError(null);

        event.preventDefault();
        if (password.value === confirmPassword.value) {
            setLoading(true);

            const header = {};

            const data = {
                token: props.match.params.token,
                password: password.value,
            };

            postApi("auth/reset", data, header).then((result) => {
                setLoading(false);
                if (result.status_code !== 200) {
                    setError(
                        result.message
                            ? result.message
                            : "Terjadi kesalahan, silahkan ulangi beberapa saat lagi"
                    );
                } else {
                    setSuccess("Reset password berhasil");
                    props.history.push("/login");
                }
            });
        } else {
            setError("Password tidak sesuai");
        }
    };

    const removeAlert = (e) => {
        setError(null);
        setSuccess(null);
    };

    return (
        <>
            <Container className="padding-tb30">
                <h1 className="text-center">Reset Password</h1>
                <p className="text-center">
                    Masukkan password baru untuk dapat mengakses akun anda
                    kembali
                </p>
            </Container>
            <Container>
                <Card className="padding30 margin-b50">
                    <Row>
                        <Col lg={5} className="relative d-none d-lg-block">
                            <img alt="Content forgot" src={reset} />
                        </Col>
                        <Col lg={7} sm={12}>
                            <Form onSubmit={handleForgot}>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="password"
                                                {...password}
                                                className="input-form"
                                                placeholder="**************"
                                                onFocus={removeAlert}
                                                autoComplete="off"
                                            />
                                            <label className="input-label">
                                                Password baru
                                            </label>
                                            <BsKeyFill className="icon-btnl left" />
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-p75">
                                    <div className="input-box">
                                        <div className="padding-tb5">
                                            <input
                                                type="password"
                                                {...confirmPassword}
                                                className="input-form"
                                                placeholder="**************"
                                                onFocus={removeAlert}
                                                autoComplete="off"
                                            />
                                            <label className="input-label">
                                                Konfirmasi password
                                            </label>
                                            <BsKey className="icon-btnl left" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a
                                        onClick={handleForgot}
                                        className="btn-primary-lg btn-w100 text-center"
                                    >
                                        {loading === true ? (
                                            <div>
                                                <AiOutlineLoading3Quarters className="loading-spin" />
                                            </div>
                                        ) : (
                                            "Kirim"
                                        )}
                                    </a>
                                    {error && (
                                        <div className="text-center">
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                        </div>
                                    )}
                                    {success && (
                                        <div className="text-center">
                                            <Form.Text className="text-success">
                                                *{success}
                                            </Form.Text>
                                        </div>
                                    )}
                                </div>
                                <div className="margin-p75 text-center">
                                    <p>
                                        Sudah memiliki akun?{" "}
                                        <Link
                                            to="/login"
                                            className="btn-clear underline text-bold600"
                                        >
                                            Masuk
                                        </Link>{" "}
                                        atau{" "}
                                        <Link
                                            to="/register"
                                            className="btn-clear underline text-bold600"
                                        >
                                            Daftar disini
                                        </Link>
                                    </p>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return {
        value,
        onChange: handleChange,
    };
};

export default Reset;
