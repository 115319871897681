import { getApi } from "../services/axios";
import { getToken } from "./utils";

var data = [];

export const getLogs = async (search, feature, user_id) => {
    const path =
        "log/list?search=" +
        (search ? search : "") +
        "&feature=" +
        (feature ? feature : "") +
        "&user_id=" +
        (user_id ? user_id : "") +
        "limit=5";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getLogDetail = async (id) => {
    const path = "log/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};
