import React from 'react';


class NotFound extends React.Component {
    render(){
        return (
            <div className="blank">
                <img src={require('../../assets/images/blank.png').default}/>
                <div className="item">
                    <h4>Woops!</h4>
                    <p>Sorry, we were unable to find that page</p>
                    <a href="/" className="btn-primary">
                                Go Back Home
                    </a>
                </div>
            </div>
        );

    }
}
export default NotFound;