import React, { useState } from "react";
import { useEffect } from "react";
import {
  BsBookmark,
  BsFillBookmarkFill,
  BiHeart,
  BsHeartFill,
  AiFillLike,
  AiFillDislike
} from "react-icons/all";
import { useDispatch } from "react-redux";
import { wishlistCourse } from "../redux/actions/courseAction";
import { COURSE_ERROR, WISHLIST_COURSE } from "../redux/types";
import { courseWishlist } from "../resources/course";
import { dislike, like } from "../resources/forum";

export default function Toggle(props) {
  const [toggleState, setToggleState] = useState(false);

  const toggle = () => {
    setToggleState((previousStar) => {
      return !previousStar;
    });
  };

  return (
    <button {...props} onClick={toggle}>
      {toggleState ? <BsFillBookmarkFill /> : <BsBookmark />}
    </button>
  );
}

export function Like({ classname, id, ts }) {
  const [toggleState, setToggleState] = useState(false);

  const toggle = () => {
    setToggleState((previousStar) => {
      return !previousStar;
    });
  };

  const setWishlist = async () => {
    try {
      await courseWishlist(id);

      toggle();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setToggleState(ts);
  }, [ts]);

  return (
    <button className={classname} onClick={setWishlist}>
      {toggleState ? <BsHeartFill style={{ color: "red" }} /> : <BiHeart />}
    </button>
  );
}
