import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, ListGroup, Spinner } from "react-bootstrap";
import {
    BsLock,
    BsClockHistory,
    BsPencilSquare,
    FaPen,
    HiMinusCircle,
    BsCardChecklist,
} from "react-icons/all";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { clearRole, getAllRoles } from "../../../redux/actions/roleAction";
import { getAllUsers } from "../../../redux/actions/userActions";
import { deleteApi } from "../../../services/axios";
import { getToken } from "../../../resources/utils";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Moment from "react-moment";
import "moment-timezone";
import { clearPermission } from "../../../redux/actions/permissionAction";
import { getLogUser } from "../../../redux/actions/logAction";
import { capitalizeFirstLetter } from "../../../resources/helpers";

const Role = (props) => {
    const dispatch = useDispatch();
    const roleList = useSelector((state) => state.roles);
    const { roles } = roleList;
    const logList = useSelector((state) => state.log);
    const { logs } = logList;
    const [loading, setLoading] = useState(true);

    const createRole = () => {
        props.history.push("/lms/role/create");
    };

    useEffect(() => {
        dispatch(getAllRoles());
        dispatch(clearRole());
        dispatch(clearPermission());
        dispatch(getLogUser("role"));
    }, [dispatch]);

    const handleDelete = (id) => {
        swal({
            title: "Apakah anda yakin?",
            text: "Data yang sudah dihapus tidak dapat dikembalikan!",
            icon: "warning",
            buttons: {
                cancel: "Batal",
                danger: {
                    text: "Hapus",
                    value: true,
                },
            },
            dangerMode: true,
        }).then((value) => {
            if (value) {
                const url = `role/delete/${id}`;
                const token = "Bearer " + getToken();
                const headers = {
                    headers: {
                        Authorization: token,
                    },
                };

                deleteApi(url, headers)
                    .then((res) => {
                        if (res.status !== "error") {
                            dispatch(getAllRoles());
                            swal("Data peran berhasil dihapus!", {
                                icon: "success",
                            });
                        } else {
                            swal("Data peran gagal dihapus!", {
                                icon: "error",
                                buttons: { cancel: "Kembali" },
                            });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    const column = [
        {
            name: "Nama",
            cell: (role) => (
                <>
                    <div className="u-name">
                        {capitalizeFirstLetter(role.title)}
                    </div>
                </>
            ),
            sortable: true,
        },
        {
            name: "Jumlah",
            cell: (role) => (
                <>
                    <span>{role.users}</span>
                </>
            ),
            width: "100px",
        },
        {
            name: "Status",
            cell: (role) => (
                <>
                    <span className={`u-status ${role.status}`}>
                        {role.status}
                    </span>
                </>
            ),
            width: "120px",
        },
        {
            name: "Hak Akses",
            cell: (role) => (
                <>
                    <Link
                        className="btn btn-primary"
                        to={"/lms/permission/" + role.id}
                    >
                        <BsCardChecklist />
                    </Link>
                </>
            ),
            width: "100px",
        },
        {
            width: "80px",
            cell: (role) => (
                <>
                    <span>
                        <Link className="btn-edit" to={"role/edit/" + role.id}>
                            <FaPen />
                        </Link>{" "}
                        {role.id !== 1 ? (
                            role.id !== 2 ? (
                                role.id !== 3 ? (
                                    <>
                                        |
                                        <a
                                            className="btn-delete"
                                            onClick={() =>
                                                handleDelete(role.id)
                                            }
                                        >
                                            <HiMinusCircle />
                                        </a>
                                    </>
                                ) : (
                                    ""
                                )
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                    </span>
                </>
            ),
            right: true,
        },
    ];

    useEffect(() => {
        setTimeout(
            function () {
                setLoading(false);
            }.bind(this),
            2000
        );
    }, [roles]);

    return (
        <div className="role margin-t30">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <BsLock />
                        <h3>Peran Pengguna </h3>
                    </div>
                    <button onClick={createRole} className="btn-transform">
                        <span className="btn-icon">
                            <BsPencilSquare />
                        </span>

                        <span className="btn-text">Tambah Baru</span>
                    </button>
                </div>
                <div className="user-body">
                    <Row>
                        <Col lg={8} xs={12}>
                            <Card className="padding10 margin-b30">
                                {roles ? (
                                    <DataTable
                                        columns={column}
                                        data={roles}
                                        defaultSortFieldId={1}
                                        pagination
                                        paginationRowsPerPageOptions={[
                                            5, 10, 25, 50, 100,
                                        ]}
                                    />
                                ) : loading ? (
                                    <Spinner
                                        animation="border"
                                        className="p-absolute left50"
                                    />
                                ) : (
                                    <span className="p-absolute left50 transform-l50">
                                        Data tidak ditemukan
                                    </span>
                                )}
                            </Card>
                        </Col>
                        <Col lg={4} xs={12}>
                            <div className="side-block">
                                <Link to={"/lms/log"}>
                                    <div className="card-title border-r20">
                                        <span className="title-block">
                                            <BsClockHistory /> Aktivitas
                                        </span>
                                    </div>
                                    <ListGroup>
                                        {logs ? (
                                            logs.map((val, i) => (
                                                <ListGroup.Item>
                                                    <div className="padding-lr10">
                                                        <span className="text-label12">
                                                            <Moment
                                                                locale="id"
                                                                fromNow
                                                            >
                                                                {val.created_at}
                                                            </Moment>
                                                        </span>
                                                        <br />
                                                        {val.value}
                                                    </div>
                                                </ListGroup.Item>
                                            ))
                                        ) : (
                                            <Spinner
                                                animation="border"
                                                className="p-absolute"
                                            />
                                        )}
                                    </ListGroup>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-footer margin-b30"></div>
            </Container>
        </div>
    );
};

export default Role;
