import { getLogDetail, getLogs } from "../../resources/log";
import { userId } from "../../resources/utils";
import { GET_LOG, GET_LOG_DETAIL, LOG_ERROR } from "../types";

export const getLogUser = (feature) => async (dispatch) => {
    try {
        const res = await getLogs("", feature, userId());
        dispatch({
            type: GET_LOG,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: LOG_ERROR,
            payload: console.log(e),
        });
    }
};

export const getLogById = (id) => async (dispatch) => {
    try {
        const res = await getLogDetail(id);
        dispatch({
            type: GET_LOG_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: LOG_ERROR,
            payload: console.log(e),
        });
    }
};
