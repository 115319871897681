import React, { Component, useState } from "react";
import { useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { BiBook, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getHomeResearch } from "../redux/actions/homeAction";

export const CardScroll = (props) => {
  const dispatch = useDispatch();
  const homeList = useSelector((state) => state.home);
  const { research } = homeList;
  const [rese, setResearch] = useState([]);

  useEffect(() => {
    dispatch(getHomeResearch());
  }, [dispatch]);

  useEffect(() => {
    setResearch(research);
  }, [research]);

  return (
    <div className='scrolling-wrapper'>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {rese
          ? rese.map((card, index) => (
              <Card
                itemId={index} // NOTE: itemId is required for track items
                title={card.title}
                body={card.description}
                link={card.url}
                key={index}
              />
            ))
          : ""}
      </ScrollMenu>
    </div>
  );
};

export const HorizontalScroll = (props) => {
  return (
    <div className='scrolling-wrapper'>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {props.children}
      </ScrollMenu>
    </div>
  );
};

function Arrow({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='btn-arrow'
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none"
      }}>
      {children}
    </button>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );

  const prev = () => {
    const element = document.getElementById("scroller");

    return (element.scrollLeft -= 150);
  };
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow onClick={() => prev()}>
      <BiChevronLeft className='font-size24r' />
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  const next = () => {
    const element = document.getElementById("scroller");

    return (element.scrollLeft += 150);
  };
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow onClick={() => next()}>
      <BiChevronRight className='font-size24r' />
    </Arrow>
  );
}

function Card({ onClick, body, title, link }) {
  return (
    <div className='card-box' tabIndex={0}>
      <div className='card-icon'>
        <span className='bg-icon'>
          <BiBook size={24} />
        </span>
      </div>
      <div className='card'>
        <h5>{title}</h5>
        <div className='card-text'>
          <div
            dangerouslySetInnerHTML={{
              __html: body
            }}></div>
        </div>
        <span className='right'>
          {link && (
            <a
              href={link}
              className='p-absolute right20p bottom0'
              rel='noopener nereferrer'
              target='_blank'
              download>
              Download PDF
            </a>
          )}
        </span>
      </div>
    </div>
  );
}
