import {
    getRoles,
    getRoleDetail,
    createRole,
    updateRole,
    deleteRole,
} from "../../resources/role";
import {
    CREATE_ROLE,
    DELETE_ROLE,
    GET_ROLES,
    GET_ROLE_DETAIL,
    ROLES_ERROR,
    UPDATE_ROLE,
} from "../types";

export const getAllRoles = () => async (dispatch) => {
    try {
        const res = await getRoles();
        dispatch({
            type: GET_ROLES,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ROLES_ERROR,
            payload: console.log(e),
        });
    }
};

export const getRoleById = (id) => async (dispatch) => {
    try {
        const res = await getRoleDetail(id);
        dispatch({
            type: GET_ROLE_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ROLES_ERROR,
            payload: console.log(e),
        });
    }
};

export const addRole = (data) => async (dispatch) => {
    try {
        const res = await createRole(data);
        dispatch({
            type: CREATE_ROLE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ROLES_ERROR,
            payload: false,
        });
    }
};

export const editRole = (data, id) => async (dispatch) => {
    try {
        const res = await updateRole(data, id);
        dispatch({
            type: UPDATE_ROLE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ROLES_ERROR,
            payload: false,
        });
    }
};

export const deleteItem = (id) => async (dispatch) => {
    const res = await deleteRole(id);
    dispatch({
        type: DELETE_ROLE,
        payload: res,
    });
};

export const clearRole = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ROLE_DETAIL,
            payload: false,
        });

        dispatch({
            type: UPDATE_ROLE,
            payload: false,
        });
    } catch (e) {
        dispatch({
            type: ROLES_ERROR,
            payload: console.log(e),
        });
    }
};
