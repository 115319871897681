import { getAllCategory } from "../redux/actions/categoryAction";
import { deleteApi, getApi, postApi, putApi } from "../services/axios";
import { getToken } from "./utils";

var data = [];

export const getComments = async (status, limit, page, search) => {
    const path = "comment/list?status=";
    // (status ? status : "") +
    // "&limit=" +
    // (limit ? limit : "") +
    // "&page=" +
    // (page ? page : "") +
    // "&search=" +
    // (search ? search : "");

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getCommentsByPostId = async (postid) => {
    const path =
        "comment/list?post_id=" +
        postid +
        "&order_by=created_at&order_as=DESC&limit=9999";
    // const path = "comment/list?post_id=" + postid;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getCommentDetail = async (id) => {
    const path = "comment/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const deleteComment = async (id) => {
    const path = `comment/delete/` + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await deleteApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
            getComments();
        } else {
            data = null;
        }
    });

    return data;
};

export const createComment = async (data) => {
    const path = "comment/create";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};
