import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import {
    BiCog,
    BsEyeFill,
    CgInsights,
    GiBrain,
    GoGraph,
    MdGroup,
} from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { getTentangSettings } from "../../redux/actions/settingsAction";
import "./about.scss";

const About = (props) => {
    const dispatch = useDispatch();
    const settingList = useSelector((state) => state.settings);
    const { tentang } = settingList;
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getTentangSettings());
    }, [dispatch]);

    useEffect(() => {
        setData(tentang);
    }, [tentang]);

    return (
        <div className="about">
            <Container className="padding-tb30 header">
                <h1 className="text-center">
                    {data
                        ? data.map((val, i) =>
                              val.key === "about-title" ? val.value : ""
                          )
                        : ""}
                </h1>
                <p>
                    {data
                        ? data.map((val, i) =>
                              val.key === "about-description" ? val.value : ""
                          )
                        : ""}
                </p>
                <div className="abouts-img">
                    <div id="left"></div>
                    {data
                        ? data.map((val, i) =>
                              val.key === "image" ? (
                                  <img
                                      key={i}
                                      id="center"
                                      className="img-fit-cover img-content margin-t50 padding-tb30 about-header"
                                      src={val.value}
                                      alt="Tentang Kami"
                                  />
                              ) : (
                                  ""
                              )
                          )
                        : ""}
                    <div id="right"></div>
                </div>
            </Container>
            <div className="about-section-1 bg-grey-color">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="content">
                                <h3>MOTO KAMI</h3>
                                <h5>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "moto"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </h5>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="content">
                                <h2>Visi</h2>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "visi"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="content">
                                <h2>Misi</h2>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "misi"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="about-section-2">
                <Container>
                    <div className="content">
                        <div className="stack-top">
                            <h2>Nilai Kelas Lentera</h2>
                            <hr />
                        </div>
                        <Row>
                            <Col>
                                <div className="item">
                                    <div className="item-head">
                                        <span className="bg-icon">I</span>
                                        <span>NNOVATIVE</span>
                                    </div>
                                    <p>
                                        {data
                                            ? data.map((val, i) =>
                                                  val.key === "innovative"
                                                      ? val.value
                                                      : ""
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </Col>
                            <Col>
                                <div className="item">
                                    <div className="item-head">
                                        <span className="bg-icon">R</span>
                                        <span>ESPONSIVE</span>
                                    </div>
                                    <p>
                                        {data
                                            ? data.map((val, i) =>
                                                  val.key === "responsive"
                                                      ? val.value
                                                      : ""
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </Col>
                            <Col>
                                <div className="item">
                                    <div className="item-head">
                                        <span className="bg-icon">I</span>
                                        <span>NTEGRATIVE</span>
                                    </div>
                                    <p>
                                        {data
                                            ? data.map((val, i) =>
                                                  val.key === "integrative"
                                                      ? val.value
                                                      : ""
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="item">
                                    <div className="item-head">
                                        <span className="bg-icon">A</span>
                                        <span>PPRECIATIVE</span>
                                    </div>
                                    <p>
                                        {data
                                            ? data.map((val, i) =>
                                                  val.key === "appreciative"
                                                      ? val.value
                                                      : ""
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </Col>
                            <Col>
                                <div className="item">
                                    <div className="item-head">
                                        <span className="bg-icon">C</span>
                                        <span>OMPASSIONATE</span>
                                    </div>
                                    <p>
                                        {data
                                            ? data.map((val, i) =>
                                                  val.key === "compassionate"
                                                      ? val.value
                                                      : ""
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </Col>
                            <Col>
                                <div className="item">
                                    <div className="item-head">
                                        <span className="bg-icon">T</span>
                                        <span>ENACITY</span>
                                    </div>
                                    <p>
                                        {data
                                            ? data.map((val, i) =>
                                                  val.key === "tenacity"
                                                      ? val.value
                                                      : ""
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="banner">
                <Container>
                    <Row>
                        <div className="banner-img" onClick={()=> window.open("https://www.youtube.com/c/KuarkTV", "_blank")}>
                            <img src={require("../../assets/images/banner.jpeg").default}/>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className="about-section-3">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="item">
                                <span className="bg-icon">
                                    <BsEyeFill size={35} />
                                </span>
                                <h4>Visi yang Kuat</h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "visi-kuat"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="item">
                                <span className="bg-icon">
                                    <MdGroup size={35} />
                                </span>
                                <h4>Kolaborasi</h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "kolaborasi"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="item">
                                <span className="bg-icon">
                                    <GiBrain size={35} />
                                </span>
                                <h4>Berbasis Inkuiri</h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "inkuiri"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="item">
                                <span className="bg-icon">
                                    <GoGraph size={35} />
                                </span>
                                <h4>Berbagi Insight Praktik Baik</h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "insight"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="about-section-4">
                <Container>
                    <h2>Kelas Lentera hadir untuk..</h2>
                    <Row>
                        <Col>
                            <div className="item">
                                <h4>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "judul4"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "description4"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="grid-wrap">
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar4-1" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption4-1"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar4-2" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption4-2"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar4-3" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption4-3"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar4-4" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption4-4"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <div className="item">
                                <h4>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "judul5"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "description5"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="grid-wrap">
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar5-1" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption5-1"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar5-2" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption5-2"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <div className="item">
                                <h4>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "judul6"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "description6"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="grid-wrap">
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar6-1" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption6-1"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar6-2" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption6-2"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <div className="item">
                                <h4>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "judul7"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </h4>
                                <p>
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "description7"
                                                  ? val.value
                                                  : ""
                                          )
                                        : ""}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="grid-wrap">
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar7-1" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption7-1"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    {data
                                        ? data.map((val, i) =>
                                              val.key === "gambar7-2" ? (
                                                  <img
                                                      key={i}
                                                      alt={val.key}
                                                      src={val.value}
                                                  />
                                              ) : (
                                                  ""
                                              )
                                          )
                                        : ""}
                                </div>
                                <div className="grid-overlay">
                                    <div className="grid-text">
                                        <p>
                                            {data
                                                ? data.map((val, i) =>
                                                      val.key === "caption7-2"
                                                          ? val.value
                                                          : ""
                                                  )
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default About;
