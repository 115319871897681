import React, { Component, useEffect } from "react";
import {
    Accordion,
    ButtonGroup,
    Col,
    Container,
    Form,
    Card,
    Row,
    Button,
} from "react-bootstrap";
import {
    BsPeopleFill,
    BsCalendarFill,
    BiArrowFromTop,
    BiDownload,
    BiArrowFromBottom,
} from "react-icons/all";
import { Like } from "../../../components/Bookmark";
import {
    getCourseID,
    wishlistCourse,
} from "../../../redux/actions/courseAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getToken, userId, userPermissions } from "../../../resources/utils";
import { Link } from "react-router-dom";
import { ResponsiveEmbed } from "react-bootstrap";
import { postApi } from "../../../services/axios";
import Comment, { CommentCourse } from "../../../components/Comment";
import { createComment } from "../../../resources/comment";
import {
    COURSE_ERROR,
    CREATE_COMMENT,
    ERROR_COMMENT,
    WISHLIST_COURSE,
} from "../../../redux/types";
import Moment from "react-moment";
import "moment/locale/id";
import {
    getAllComment,
    getCommentByPost,
} from "../../../redux/actions/commentAction";
import {
    CheckReadOnly,
    CheckReadWrite,
    CompareDateNowWithType,
    CheckUserCourse,
    validURL,
} from "../../../resources/helpers";
import { userDetail } from "../../../redux/actions/userActions";

const LearningDetail = (props) => {
    const dispatch = useDispatch();
    const [isScroll, setScroll] = useState("absolute");
    const courseDetail = useSelector((state) => state.courses);
    const { course, detail, member, category } = courseDetail;
    const commentList = useSelector((state) => state.comments);
    const { comments, child } = commentList;
    const userList = useSelector((state) => state.users);
    const { joinCourse } = userList;
    const [disable, setDisable] = useState(false);
    const [wishlist, setWishlist] = useState(false);
    const [open, setOpen] = useState(false);
    const total = comments;
    const count = total ? total.length : "0";
    const [userPerm, setUserPermission] = useState([]);
    const handleOpen = () => {
        setOpen(!open);
    };
    // sort asc

    function formatDate(str, separate, format) {
        var monthNames = [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
        ];
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var form = [];

        if (format === 1) {
            form = [year, month, day];
        } else if (format === 2) {
            form = [day, month, year];
        } else if (format === 3) {
            form = [day, monthNames[d.getMonth()], year];
        }

        var dateFormat = form.join(separate);
        return dateFormat;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (event.target.description.value !== "") {
            const data = new FormData(event.target);
            data.set("title", "comment");
            data.set("post_id", props.match.params.id);
            data.set("type", "comment");
            data.set("status", "active");
            try {
                const res = await createComment(data);

                dispatch({
                    type: CREATE_COMMENT,
                    payload: res,
                });

                if (res.status === "success" || res !== undefined) {
                    dispatch(getCommentByPost(props.match.params.id));
                }
            } catch (e) {
                dispatch({
                    type: ERROR_COMMENT,
                    payload: false,
                });
            }
        }
    };

    useEffect(() => {
        // dispatch(getCommentByPost(props.match.params.id, comments.created_at, "ASC"));
        dispatch(getCommentByPost(props.match.params.id));
        dispatch(getCourseID(props.match.params.id));
        dispatch(userDetail(userId()));

        const randomBetween = (min, max) =>
            min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const background = `rgba(${r},${g},${b},.1)`;
        const border = `rgba(${r},${g},${b},.2)`;
        const text = `rgba(${r},${g},${b},1)`;
        document.getElementById("learn-header").style.background = background;
        document.getElementById("learn-header").style.borderColor = border;
        document.getElementById("category").style.color = text;
        document.getElementById("people").style.color = text;
        document.getElementById("calendar").style.color = text;
        document.getElementById("join").style.background = background;
        document.getElementById("join").style.color = text;

        window.addEventListener("scroll", () => {
            var data = window.scrollY > 50;
            setScroll(data);
        });
    }, [dispatch]);

    const joinCourses = (e) => {
        e.preventDefault();

        const url = `course/join/` + course.id;

        const token = "Bearer " + getToken();
        const headers = {
            headers: {
                Authorization: token,
            },
        };

        postApi(url, [], headers)
            .then((res) => {
                if (res.status === "error") {
                    setDisable(false);
                } else {
                    setDisable(true);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        member.length > 0
            ? member.map((item, i) => {
                  if (item.user_id.id === userId()) {
                      setDisable(true);
                  }
              })
            : setDisable(false);
        initYouTubeVideos();
        course
            ? course.wishlist_user.map((val) =>
                  val.id === userId() ? setWishlist(true) : ""
              )
            : setWishlist(false);
        setUserPermission(userPermissions());
    }, [member]);

    function changeUrl(url) {
        let domain = new URL(url);
        let path = domain.pathname.split("/");
        let result = domain.hostname;

        for (var i = 0; i < path.length; i++) {
            if (i > 0 && i < path.length - 1) {
                result += "/" + path[i];
            }
        }

        return "https://" + result + "/preview";
    }

    function downloadUrl(url) {
        let domain = new URL(url);
        let path = domain.pathname.split("/");
        let result = domain.hostname + "/u/0/uc?id=";

        if (
            path.find((val) => val === "spreadsheets") ||
            path.find((val) => val === "document") ||
            path.find((val) => val === "presentation")
        ) {
            return url;
        }

        return "https://" + result + path[path.length - 2] + "&export=download";
    }

    function getId(url) {
        var regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);

        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return "error";
        }
    }

    function labnolIframe(div) {
        var iframe = document.createElement("iframe");
        var url = "";

        if (div.dataset.source === "drive") {
            url = div.dataset.id;
        } else if (div.dataset.source === "youtube") {
            url =
                "https://www.youtube.com/embed/" +
                div.dataset.id +
                "?autoplay=1&rel=0";
        }

        iframe.setAttribute("src", url);
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "1");
        iframe.setAttribute(
            "allow",
            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        );
        div.parentNode.replaceChild(iframe, div);
    }

    function getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function initYouTubeVideos() {
        var playerElements = document.getElementsByClassName("youtube-player");
        for (var n = 0; n < playerElements.length; n++) {
            var videoId = playerElements[n].dataset.id;
            let domain = new URL(videoId);
            var div = document.createElement("div");

            if (domain.hostname === "drive.google.com") {
                div.setAttribute("data-id", changeUrl(videoId));
                div.setAttribute("data-source", "drive");
            } else if (domain.hostname === "www.youtube.com") {
                div.setAttribute("data-id", getId(videoId));
                div.setAttribute("data-source", "youtube");
            }

            var thumKey = getParameterByName("v", videoId)

            var thumbNode = document.createElement("img");
            thumbNode.src = "http://i.ytimg.com/vi/ID/maxresdefault.jpg".replace(
                "ID",
                thumKey
            );
            div.appendChild(thumbNode);
            var playButton = document.createElement("div");
            playButton.setAttribute("class", "play");
            div.appendChild(playButton);
            div.onclick = function () {
                labnolIframe(this);
            };
            playerElements[n].appendChild(div);
        }
    }

    document.addEventListener("DOMContentLoaded", initYouTubeVideos);

    return (
        <>
            <div id="learn-header" className="margin-t30 learn-header">
                <Container fluid>
                    <Row>
                        <Col lg={8}>
                            <div className="top-detail">
                                <span id="category">{category.title}</span>
                                <h2>{course.title}</h2>
                                <img
                                    className="hide-desktop"
                                    width="100%"
                                    src={
                                        require("../../../assets/images/placeholder.png")
                                            .default
                                    }
                                />
                                <p>{course.description}</p>
                                {course ? (
                                    CompareDateNowWithType(
                                        course,
                                        course.closed_date,
                                        "closed"
                                    ) ? (
                                        ""
                                    ) : (
                                        <span className={`u-status not-active`}>
                                            Closed
                                        </span>
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                            <br />
                            <div className="bot-detail">
                                <span id="people">
                                    <BsPeopleFill /> {member.length} Bergabung
                                </span>

                                <span id="calendar">
                                    <BsCalendarFill />
                                    {formatDate(course.open_date, " ", 3) +
                                        " - " +
                                        formatDate(course.closed_date, " ", 3)}
                                </span>
                            </div>
                            <div className="button-block hide-desktop">
                            {CheckReadOnly(
                                        userPerm,
                                        "access_read_course"
                                    ) ? (
                                        course ? (
                                            CompareDateNowWithType(
                                                course,
                                                course.closed_date,
                                                "closed"
                                            ) ? (
                                                disable ? (
                                                    <div id="join"></div>
                                                ) : (
                                                    <button
                                                        id="join"
                                                        className="join"
                                                        onClick={joinCourses}
                                                    >
                                                        Ikuti Kursus
                                                    </button>
                                                )
                                            ) : (
                                                <div id="join"></div>
                                            )
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        <Link
                                            id="join"
                                            className="join text-center"
                                            to={"/lms/learn/edit/" + course.id}
                                        >
                                            Ubah
                                        </Link>
                                    )}

                                <Like
                                    classname="like like1"
                                    id={props.match.params.id}
                                    ts={wishlist}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div
                                id="fixed-card"
                                className="card hide-mobile"
                                style={{
                                    position: isScroll ? "fixed" : "absolute",
                                }}
                            >
                                <img
                                    src={
                                        course.image
                                            ? course.image
                                            : require("../../../assets/images/placeholder.png")
                                                  .default
                                    }
                                />
                                <div className="detail">
                                    <span>Kursus ini termasuk:</span>
                                    <ul>
                                        {detail
                                            ? detail.map((item, i) =>
                                                  i < 3 ? (
                                                      <li key={i}>
                                                          {item.title}
                                                      </li>
                                                  ) : i === 3 ? (
                                                      <li key={i}>Lainnya</li>
                                                  ) : (
                                                      ""
                                                  )
                                              )
                                            : ""}
                                    </ul>
                                </div>
                                <div className="button-block">
                                    {CheckReadOnly(
                                        userPerm,
                                        "access_read_course"
                                    ) ? (
                                        course ? (
                                            CompareDateNowWithType(
                                                course,
                                                course.closed_date,
                                                "closed"
                                            ) ? (
                                                disable ? (
                                                    <div id="join"></div>
                                                ) : (
                                                    <button
                                                        id="join"
                                                        className="join"
                                                        onClick={joinCourses}
                                                    >
                                                        Ikuti Kursus
                                                    </button>
                                                )
                                            ) : (
                                                <div id="join"></div>
                                            )
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        <Link
                                            id="join"
                                            className="join text-center"
                                            to={"/lms/learn/edit/" + course.id}
                                        >
                                            Ubah
                                        </Link>
                                    )}
                                    <Like
                                        classname="like like2"
                                        id={props.match.params.id}
                                        ts={wishlist}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="margin-t30 learn-body">
                <Container fluid>
                    <Row>
                        <Col lg={8}>
                            <div
                                className={
                                    CompareDateNowWithType(
                                        course,
                                        course.closed_date,
                                        "closed"
                                    )
                                        ? disable
                                            ? "learn-course"
                                            : "learn-course cursor-na"
                                        : "learn-course cursor-na"
                                }
                            >
                                <Accordion
                                    defaultActiveKey="0"
                                    className={
                                        CompareDateNowWithType(
                                            course,
                                            course.closed_date,
                                            "closed"
                                        )
                                            ? disable
                                                ? ""
                                                : "cursor-na pe-none"
                                            : "cursor-na pe-none"
                                    }
                                >
                                    {detail
                                        ? detail.map((item, i) => (
                                              <Accordion.Item
                                                  key={i}
                                                  eventKey={i}
                                                  className={
                                                      course
                                                          ? CompareDateNowWithType(
                                                                course,
                                                                item.open_date,
                                                                "open"
                                                            )
                                                              ? "cursor-na"
                                                              : disable
                                                              ? ""
                                                              : "cursor-na"
                                                          : ""
                                                  }
                                              >
                                                  <Accordion.Header
                                                      className={
                                                          course
                                                              ? CompareDateNowWithType(
                                                                    course,
                                                                    item.open_date,
                                                                    "open"
                                                                )
                                                                  ? "pe-none"
                                                                  : disable
                                                                  ? ""
                                                                  : "pe-none"
                                                              : ""
                                                      }
                                                  >
                                                      <div className="relative block">
                                                          {item.title}
                                                          <br />
                                                          <Moment
                                                              className="text-label12"
                                                              locale="id"
                                                              format="dddd, D MMMM YYYY"
                                                          >
                                                              {item.open_date}
                                                          </Moment>
                                                      </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                      <p>
                                                          {item.description
                                                              ? item.description
                                                              : ""}
                                                      </p>
                                                      {item.attachment ? (
                                                          <>
                                                              {JSON.parse(
                                                                  item.attachment
                                                              ).map((val, i) =>
                                                                  val.type ===
                                                                  "video" ? (
                                                                      validURL(
                                                                          val.value
                                                                      ) ? (
                                                                          <div
                                                                              key={
                                                                                  i
                                                                              }
                                                                              className="youtube-player"
                                                                              data-id={
                                                                                  val.value
                                                                              }
                                                                          ></div>
                                                                      ) : (
                                                                          "URL tidak valid"
                                                                      )
                                                                  ) : (
                                                                      ""
                                                                  )
                                                              )}
                                                              <Row>
                                                                  {JSON.parse(
                                                                      item.attachment
                                                                  ).map(
                                                                      (
                                                                          val,
                                                                          i
                                                                      ) =>
                                                                          val.type ===
                                                                          "file" ? (
                                                                              <Col
                                                                                  xs={
                                                                                      12
                                                                                  }
                                                                                  md={
                                                                                      6
                                                                                  }
                                                                                  key={
                                                                                      i
                                                                                  }
                                                                              >
                                                                                  <Card className="padding-lr30">
                                                                                      <div
                                                                                          className="item-element padding-tb10"
                                                                                          key={
                                                                                              i
                                                                                          }
                                                                                      >
                                                                                          <span className="text-label12">
                                                                                              {
                                                                                                  val.type
                                                                                              }
                                                                                          </span>
                                                                                          <div>
                                                                                              {
                                                                                                  val.title
                                                                                              }
                                                                                          </div>
                                                                                          <a
                                                                                              href={changeUrl(
                                                                                                  val.value
                                                                                              )}
                                                                                              target="_blank"
                                                                                              className="text-label12 ellipsis"
                                                                                          >
                                                                                              {
                                                                                                  val.value
                                                                                              }
                                                                                          </a>
                                                                                          <div className="delete-container">
                                                                                              <Button
                                                                                                  className="btn btn-delete-content text-white"
                                                                                                  href={downloadUrl(
                                                                                                      val.value
                                                                                                  )}
                                                                                                  download
                                                                                                  target="_blank"
                                                                                              >
                                                                                                  <BiDownload />
                                                                                              </Button>
                                                                                          </div>
                                                                                      </div>
                                                                                  </Card>
                                                                              </Col>
                                                                          ) : (
                                                                              ""
                                                                          )
                                                                  )}
                                                              </Row>
                                                          </>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Accordion.Body>
                                              </Accordion.Item>
                                          ))
                                        : ""}
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className="comment-section">
                                <h4>Diskusi</h4>
                                <Form
                                    onSubmit={handleSubmit}
                                    className="comment-form"
                                >
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        placeholder="Tulis pesan anda disini... "
                                        disabled={
                                            course
                                                ? CompareDateNowWithType(
                                                      course,
                                                      course.closed_date,
                                                      "closed"
                                                  )
                                                    ? disable
                                                        ? false
                                                        : true
                                                    : true
                                                : ""
                                        }
                                    />
                                    <button
                                        type="submit"
                                        className={
                                            course
                                                ? CompareDateNowWithType(
                                                      course,
                                                      course.closed_date,
                                                      "closed"
                                                  )
                                                    ? disable
                                                        ? "answer  w-100"
                                                        : "answer  w-100 disabled"
                                                    : "answer  w-100 disabled"
                                                : ""
                                        }
                                    >
                                        Kirim
                                    </button>
                                </Form>

                                {comments ? (
                                    <CommentCourse
                                        data={comments}
                                        total={count}
                                        id={props.match.params.id}
                                        status={
                                            course
                                                ? CompareDateNowWithType(
                                                      course,
                                                      course.closed_date,
                                                      "closed"
                                                  )
                                                : ""
                                        }
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default LearningDetail;
