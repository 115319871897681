import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row, Image, Card, ListGroup } from "react-bootstrap";
import {
    FaEdit,
    BiUser,
    BiUserCircle,
    BsClockHistory,
    BsChatDots,
} from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import "../profile/profile.scss";
import { userDetail } from "../../../redux/actions/userActions";
import {
    getRandomColor,
    createImageFromInitials,
} from "../../../resources/utilities";

const DetailUser = (props) => {
    const dispatch = useDispatch();
    const getUserDetail = useSelector((state) => state.users);
    const { user, userCourses, userForums, joinCourse, joinForum } =
        getUserDetail;
    const [courses, setCourses] = useState([]);
    const [joinCourses, setJoinCourse] = useState([]);
    const [forums, setForums] = useState([]);
    const [joinForums, setJoinForum] = useState([]);
    const [isUser, setIsUser] = useState(false);

    const allCourse = () => {
        props.history.push("/lms/profile/activity/course");
    };

    const allForum = () => {
        props.history.push("/lms/profile/activity/forum");
    };

    const goCourse = () => {
        props.history.push("/lms/learn/create");
    };

    const goForum = () => {
        props.history.push("/lms/forum/create");
    };

    const handleClick = (e) => {
        console.log(e);
    };

    function formatDate(str, separate, format) {
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var form = [];

        if (format === 1) {
            form = [year, month, day];
        } else if (format === 2) {
            form = [day, month, year];
        }

        var dateFormat = form.join(separate);
        return dateFormat;
    }

    function compareDateNow(compareDate) {
        var dateNow = new Date().toISOString().slice(0, 10);
        compareDate = new Date(compareDate).toISOString().slice(0, 10);

        if (compareDate > dateNow) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        dispatch(userDetail(props.match.params.id));
    }, [dispatch]);

    useEffect(() => {
        setCourses(userCourses);
        setJoinCourse(joinCourse);
        setForums(userForums);
        setJoinForum(joinForum);
    }, [userCourses, userForums, joinCourse, joinForum]);

    return (
        <div className="profile">
            <Container fluid>
                <div className="margin-t30 title margin-b20">
                    <BiUserCircle />
                    <h3>User Detail</h3>
                </div>
                <div className="profile-header">
                    <Row>
                        <Col lg={8} xs={12}>
                            <Card className="padding10 margin-b30">
                                {user ? (
                                    <Row>
                                        <Col lg={5} xs={12}>
                                            <div className="img-circle-container">
                                                <img
                                                    id="preview"
                                                    src={createImageFromInitials(
                                                        500,
                                                        user.name,
                                                        getRandomColor()
                                                    )}
                                                    alt="profile-pic"
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={7}
                                            xs={12}
                                            className="relative"
                                        >
                                            <div className="p-detail display-flex center-horizontal center-vertical w100 h100">
                                                <div className="w100 profile">
                                                    <div className="title">
                                                        <h3>{user.name}</h3>
                                                        <span
                                                            className={
                                                                user.role_id ===
                                                                1
                                                                    ? "admin"
                                                                    : user.role_id ===
                                                                      2
                                                                    ? "trainer"
                                                                    : "member"
                                                            }
                                                        >
                                                            {user.role_id === 1
                                                                ? "Administrator"
                                                                : user.role_id ===
                                                                  2
                                                                ? "Trainer"
                                                                : "Member"}
                                                        </span>
                                                    </div>
                                                    <p>{user.email}</p>
                                                    <div className="">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <h5 className="font-size25r">
                                                                    {user
                                                                        .join_course
                                                                        .data
                                                                        ? user
                                                                              .join_course
                                                                              .data
                                                                              .length
                                                                        : 0}
                                                                </h5>
                                                                <span>
                                                                    Kursus
                                                                </span>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <h5 className="font-size25r">
                                                                    {user
                                                                        .join_forum
                                                                        .data
                                                                        ? user
                                                                              .join_forum
                                                                              .data
                                                                              .length
                                                                        : 0}
                                                                </h5>
                                                                <span>
                                                                    Diskusi
                                                                </span>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <h5 className="font-size25r">
                                                                    0
                                                                </h5>
                                                                <span>
                                                                    Download
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}
                                <div className="p-body margin-b30">
                                    <div className="border-r30 card-course padding30">
                                        <div className="card-title margin-b20 display-flex space-between">
                                            <h5>Kursus Saya</h5>
                                            <a onClick={allCourse}>
                                                Lihat Semua
                                            </a>
                                        </div>
                                        <div className="card-body padding0">
                                            <Row>
                                                {courses.length > 0 ? (
                                                    courses
                                                        .slice(0, 3)
                                                        .map((val, i) => (
                                                            <Col
                                                                md={6}
                                                                lg={4}
                                                                className="learn-col"
                                                                onClick={() => {
                                                                    props.history.push(
                                                                        "/lms/learn/detail/" +
                                                                            val.id
                                                                    );
                                                                }}
                                                            >
                                                                <Card className="learn-card">
                                                                    {val.image !==
                                                                    "" ? (
                                                                        <Card.Img
                                                                            className="learn-card-image"
                                                                            variant="top"
                                                                            src={
                                                                                val.image
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Card.Img
                                                                            className="learn-card-image"
                                                                            variant="top"
                                                                            src={
                                                                                require(`../../../assets/images/courses${
                                                                                    i +
                                                                                    1
                                                                                }.svg`)
                                                                                    .default
                                                                            }
                                                                        />
                                                                    )}
                                                                    <Card.Body>
                                                                        <div className="card-body-top">
                                                                            {"Author: " +
                                                                                val
                                                                                    .created_by
                                                                                    .name}
                                                                        </div>
                                                                        <div className="card-body-title">
                                                                            {val
                                                                                .title
                                                                                .lenght >
                                                                            15
                                                                                ? val.title.substring(
                                                                                      0,
                                                                                      40
                                                                                  ) +
                                                                                  "..."
                                                                                : val.title}
                                                                            <br />
                                                                        </div>
                                                                        <div className="card-body-desc">
                                                                            {compareDateNow(
                                                                                val.closed_date
                                                                            ) ? (
                                                                                <span
                                                                                    className={`u-status active`}
                                                                                >
                                                                                    Open
                                                                                </span>
                                                                            ) : (
                                                                                <span
                                                                                    className={`u-status not-active`}
                                                                                >
                                                                                    Closed
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div className="card-body-bottom">
                                                                            <div className="user">
                                                                                <BiUser />
                                                                                <span>
                                                                                    4
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                ) : (
                                                    <Col>
                                                        Belum memiliki Kursus
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-body margin-b30">
                                    <div className="border-r30 card-forum padding30">
                                        <div className="card-title display-flex space-between margin-b20">
                                            <h5>Diskusi Saya</h5>
                                            <a onClick={allForum}>
                                                Lihat Semua
                                            </a>
                                        </div>
                                        <Row>
                                            {forums.length > 0 ? (
                                                forums.map((val, i) => (
                                                    <Col
                                                        md={6}
                                                        lg={6}
                                                        className="learn-col"
                                                    >
                                                        <Card
                                                            className="learn-card clickable"
                                                            onClick={() => {
                                                                props.history.push(
                                                                    "/lms/forum/detail/" +
                                                                        val.id
                                                                );
                                                            }}
                                                        >
                                                            <Card.Body className="h100 max-h100">
                                                                <div className="card-body-top">
                                                                    <div className="display-flex">
                                                                        <Image
                                                                            src={createImageFromInitials(
                                                                                100,
                                                                                val
                                                                                    .created_by
                                                                                    .name,
                                                                                getRandomColor()
                                                                            )}
                                                                            alt="profile"
                                                                            roundedCircle
                                                                            className="w50p h50p padding0 object-fit-cover object-position-center relative"
                                                                            width="50"
                                                                            height="50"
                                                                        />
                                                                        <div className="padding5">
                                                                            <span className="display-block">
                                                                                {
                                                                                    val
                                                                                        .created_by
                                                                                        .name
                                                                                }
                                                                            </span>
                                                                            <span>
                                                                                {formatDate(
                                                                                    val.created_at,
                                                                                    "/",
                                                                                    2
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body-title padding-tb10 min-h95p">
                                                                    <span className="text-label12">
                                                                        Topik
                                                                    </span>
                                                                    <h6>
                                                                        {val
                                                                            .title
                                                                            .length >
                                                                        40
                                                                            ? val.title.substring(
                                                                                  0,
                                                                                  40
                                                                              )
                                                                            : val.title}
                                                                    </h6>
                                                                </div>
                                                                <Row>
                                                                    <Col>
                                                                        <span className="text-label12">
                                                                            Pengguna
                                                                        </span>
                                                                        <div className="relative w100 h30p">
                                                                            <span>
                                                                                {
                                                                                    val
                                                                                        .details
                                                                                        .data
                                                                                        .length
                                                                                }
                                                                            </span>
                                                                            {val
                                                                                .details
                                                                                .data
                                                                                .length >
                                                                            0
                                                                                ? val.details.data
                                                                                      .slice(
                                                                                          0,
                                                                                          3
                                                                                      )
                                                                                      .map(
                                                                                          (
                                                                                              v,
                                                                                              i
                                                                                          ) => (
                                                                                              <Image
                                                                                                  src={createImageFromInitials(
                                                                                                      100,
                                                                                                      v
                                                                                                          .created_by
                                                                                                          .name,
                                                                                                      getRandomColor()
                                                                                                  )}
                                                                                                  alt="profile"
                                                                                                  roundedCircle
                                                                                                  className="w30p h30p padding0 object-fit-cover object-position-center p-absolute z-index3 img-border-solid1p white"
                                                                                                  width="30"
                                                                                                  height="30"
                                                                                              />
                                                                                          )
                                                                                      )
                                                                                : ""}

                                                                            {val
                                                                                .details
                                                                                .length >
                                                                            3 ? (
                                                                                <span className="p-absolute left50p top50 transform-t50 padding5 font-size12">
                                                                                    +
                                                                                    {val
                                                                                        .details
                                                                                        .length -
                                                                                        3}
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <span className="text-label12">
                                                                            Aktivitas
                                                                        </span>
                                                                        <h6 className="font-size12">
                                                                            4
                                                                            hari
                                                                            yang
                                                                            lalu
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))
                                            ) : (
                                                <Col>Belum membuat diskusi</Col>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={4} xs={12}>
                            <div className="side-block">
                                <span className="title-block">
                                    <BsChatDots /> Diskusi yang diikuti
                                </span>
                                <ListGroup>
                                    {joinForums.length > 0 ? (
                                        joinForums
                                            .slice(0, 3)
                                            .map((v, i) => (
                                                <ListGroup.Item>
                                                    Bagaimana cara mangajarkan
                                                    bahasa inggris yang baik
                                                </ListGroup.Item>
                                            ))
                                    ) : (
                                        <ListGroup.Item>
                                            Belum mengikuti diskusi
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>

                                <div className="margin-t30 card-title border-r20">
                                    <span className="title-block">
                                        <BsClockHistory /> Aktivitas
                                    </span>
                                </div>
                                <ListGroup>
                                    <ListGroup.Item>
                                        13:00 - Masuk
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        13:20 - Bergabung dengan kursus ABCDE
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        13:50 - Download materi kursus ABCDE
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        14:30 - Membalas diskusi
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        15:00 - Bergabung dengan kursus Anak
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="p-footer margin-b30"></div>
            </Container>
        </div>
    );
};

export default DetailUser;
