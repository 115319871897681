import {
    GET_USERS,
    GET_USERS_DETAIL,
    CREATE_USER,
    UPDATE_USER,
    REMOVE_SELECTED_USERS,
    GET_USERS_PROFILE,
    GET_UNVERIFIED_USER,
} from "../types";

const initialState = {
    users: false,
    user: false,
    userCourses: false,
    userForums: false,
    joinCourse: false,
    joinForum: false,
    wishlist: false,
    responseUser: false,
    unverifiedCount: false,
    loading: true,
};

export const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_USERS:
            return {
                ...state,
                users: payload,
                user: false,
                userCourses: false,
                userForums: false,
                joinCourse: false,
                joinForum: false,
                responseUser: false,
                loading: false,
            };
        case GET_USERS_DETAIL:
            return {
                ...state,
                users: false,
                user: payload,
                userCourses: payload ? payload.courses.data : false,
                userForums: payload ? payload.forums.data : false,
                joinCourse: payload ? payload.join_course.data : false,
                joinForum: payload
                    ? payload.join_forum.data.filter(
                          (ele, ind) =>
                              ind ===
                              payload.join_forum.data.findIndex(
                                  (elem) => elem.forum_id === ele.forum_id
                              )
                      )
                    : false,
                wishlist: payload ? payload.wishlist : false,
                responseUser: false,
                loading: true,
            };
        case CREATE_USER:
            return {
                ...state,
                responseUser: payload,
                loading: false,
            };
        case GET_UNVERIFIED_USER:
            return {
                ...state,
                unverifiedCount: payload,
                loading: false,
            };
        case UPDATE_USER:
            return {
                ...state,
                responseUser: payload,
                loading: false,
            };
        case GET_USERS_PROFILE:
            return {
                ...state,
                users: payload,
                responseUser: false,
                loading: false,
            };
        case REMOVE_SELECTED_USERS:
            return {
                ...state,
                users: payload,
            };
        default:
            return state;
    }
};
