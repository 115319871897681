import { getAllSettings } from "../../resources/settings";
import {
    GET_BERANDA_SETTINGS,
    GET_CONTACT_SETTINGS,
    GET_GENERAL_SETTINGS,
    GET_PROGRAM_SETTINGS,
    GET_SETTINGS,
    GET_TENTANG_SETTINGS,
    SETTINGS_ERROR,
} from "../types";

export const getSettings = () => async (dispatch) => {
    try {
        const res = await getAllSettings("");
        dispatch({
            type: GET_SETTINGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: SETTINGS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getGeneralSettings = () => async (dispatch) => {
    try {
        const res = await getAllSettings("general");
        dispatch({
            type: GET_GENERAL_SETTINGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: SETTINGS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getBerandaSettings = () => async (dispatch) => {
    try {
        const res = await getAllSettings("beranda");
        dispatch({
            type: GET_BERANDA_SETTINGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: SETTINGS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getTentangSettings = () => async (dispatch) => {
    try {
        const res = await getAllSettings("tentang");
        dispatch({
            type: GET_TENTANG_SETTINGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: SETTINGS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getProgramSettings = () => async (dispatch) => {
    try {
        const res = await getAllSettings("program");
        dispatch({
            type: GET_PROGRAM_SETTINGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: SETTINGS_ERROR,
            payload: console.log(e),
        });
    }
};

export const getContactSettings = () => async (dispatch) => {
    try {
        const res = await getAllSettings("contact");
        dispatch({
            type: GET_CONTACT_SETTINGS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: SETTINGS_ERROR,
            payload: console.log(e),
        });
    }
};
