import {
    createCategory,
    deleteCategory,
    getCategories,
    getCategoryDetail,
    updateCategory,
} from "../../resources/category";
import {
    CATEGORY_ERROR,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    GET_CATEGORY,
    GET_CATEGORY_DETAIL,
    UPDATE_CATEGORY,
} from "../types";

export const getAllCategory = () => async (dispatch) => {
    try {
        const res = await getCategories();
        dispatch({
            type: GET_CATEGORY,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: console.log(e),
        });
    }
};

export const getCategoryById = (id) => async (dispatch) => {
    try {
        const res = await getCategoryDetail(id);
        dispatch({
            type: GET_CATEGORY_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: console.log(e),
        });
    }
};

export const addCategory = (data) => async (dispatch) => {
    try {
        const res = await createCategory(data);
        dispatch({
            type: CREATE_CATEGORY,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: false,
        });
    }
};

export const editCategory = (data, id) => async (dispatch) => {
    try {
        const res = await updateCategory(data, id);
        dispatch({
            type: UPDATE_CATEGORY,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: false,
        });
    }
};

export const deleteItem = (id) => async (dispatch) => {
    const res = await deleteCategory(id);
    dispatch({
        type: DELETE_CATEGORY,
        payload: res,
    });
};

export const clearCategory = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CATEGORY_DETAIL,
            payload: false,
        });

        dispatch({
            type: UPDATE_CATEGORY,
            payload: false,
        });
    } catch (e) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: console.log(e),
        });
    }
};
