import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// import logo from "../logo.png";
import { Col, Container, Row } from "react-bootstrap";
import { FiInstagram, FiYoutube } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    getGeneralSettings,
    getSettings,
} from "../redux/actions/settingsAction";

const Footer = (props) => {
    const dispatch = useDispatch();
    const settingList = useSelector((state) => state.settings);
    const { general } = settingList;
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getGeneralSettings());
    }, [dispatch]);

    useEffect(() => {
        setData(general);
    }, [general]);

    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="brand">
                            <div className="logo">
                                {data
                                    ? data.map((val, i) =>
                                          val.key === "logo" ? (
                                              <img
                                                  key={i}
                                                  alt="Content footer"
                                                  src={val.value}
                                              />
                                          ) : (
                                              ""
                                          )
                                      )
                                    : ""}
                            </div>
                            <p className="text-justify">
                                {data
                                    ? data.map((val, i) =>
                                          val.key === "footer-description"
                                              ? val.value
                                              : ""
                                      )
                                    : ""}
                            </p>
                        </div>
                    </Col>
                    <Col md={{ span: 3, offset: 1 }}>
                        <div className="item">
                            <h5>Akses Cepat</h5>
                            <ul>
                                <li>
                                    <Link to="/">Beranda</Link>
                                </li>
                                <li>
                                    <Link to="/about">Tentang Kami</Link>
                                </li>
                                <li>
                                    <Link to="/activity/all">Program</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Kontak Kami</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="item">
                            <h5>Alamat</h5>
                            <p className="text-justify">
                                {data
                                    ? data.map((val, i) =>
                                          val.key === "footer-address"
                                              ? val.value
                                              : ""
                                      )
                                    : ""}
                            </p>
                        </div>
                        <div>
                            {data
                                ? data.map((val, i) =>
                                      val.key === "instagram" ? (
                                          <a
                                              href={val.value}
                                              key={i}
                                              className="margin-r10"
                                          >
                                              <FiInstagram className="font-size20"></FiInstagram>
                                          </a>
                                      ) : (
                                          ""
                                      )
                                  )
                                : ""}
                            {data
                                ? data.map((val, i) =>
                                      val.key === "youtube" ? (
                                          <a href={val.value} key={i}>
                                              <FiYoutube className="font-size20"></FiYoutube>
                                          </a>
                                      ) : (
                                          ""
                                      )
                                  )
                                : ""}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="copy text-bold600">
                        &copy; Copyright {new Date().getFullYear()},{" "}
                        {data
                            ? data.map((val, i) =>
                                  val.key === "name" ? val.value : ""
                              )
                            : ""}
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
