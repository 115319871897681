import React, { Component, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";

import Home from "./routes/home/Home";
import Contact from "./routes/contact/Contact";
import Activity from "./routes/activity/Activity";
import Login from "./routes/login/Login";
import Register from "./routes/register/Register";
import Forgot from "./routes/forgot/Forgot";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./routes/about/About";
import NotFound from "./routes/error/NotFound";

import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Dashboard from "./routes/PrivateRoute/dashboard/Dashboard";

import Profile from "./routes/PrivateRoute/profile/Profile";
import CourseActivity from "./routes/PrivateRoute/profile/CourseActivity";
import Sidenav, { PrivateHeader } from "./components/Sidenav";
import ActivityDetail from "./routes/activity/ActivityDetail";

import Forum from "./routes/PrivateRoute/forum/Forum";
import ForumDetail from "./routes/PrivateRoute/forum/ForumDetail";
import AddForum from "./routes/PrivateRoute/forum/AddForum";
import EditForum from "./routes/PrivateRoute/forum/EditForum";
import ForumActivity from "./routes/PrivateRoute/profile/ForumActivity";

import Learning from "./routes/PrivateRoute/learning/Learning";
import AddCourse from "./routes/PrivateRoute/learning/AddCourse";
import LearningDetail from "./routes/PrivateRoute/learning/LearnDetail";

import { isRemember, isLogin, logout } from "./resources/utils";

import Category from "./routes/PrivateRoute/category/category";
import AddCategory from "./routes/PrivateRoute/category/AddCategory";
import EditCategory from "./routes/PrivateRoute/category/EditCategory";

import User from "./routes/PrivateRoute/user/User";
import DetailUser from "./routes/PrivateRoute/user/DetailUser";
import EditUser from "./routes/PrivateRoute/user/EditUser";
import AddUser from "./routes/PrivateRoute/user/AddUser";

import Role from "./routes/PrivateRoute/role/Role";
import AddRole from "./routes/PrivateRoute/role/AddRole";
import EditRole from "./routes/PrivateRoute/role/EditRole";
import EditCourse from "./routes/PrivateRoute/learning/EditCourse";

import Permission from "./routes/PrivateRoute/permisson/Permission";

import ReactGA from "react-ga";
import TableDetail from "./routes/PrivateRoute/profile/TableDetail";
import Log from "./routes/PrivateRoute/log/Log";
import Reset from "./routes/forgot/Reset";
import ChangePassword from "./routes/PrivateRoute/profile/ChangePassword";
const TRACKING_ID = "UA-205595952-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

class PublicSection extends Component {
    render() {
        return (
            <div>
                <Header />
                <Switch>
                    <PublicRoute
                        restricted={false}
                        component={Home}
                        path="/"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={About}
                        path="/about"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Activity}
                        path="/activity"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Activity}
                        path="/activity/:id"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={ActivityDetail}
                        path="/activity/detail/:id"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Contact}
                        path="/contact"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Login}
                        path="/login"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Register}
                        path="/register"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Forgot}
                        path="/forgot-password"
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Reset}
                        path="/reset/:token"
                        exact
                    />

                    <Route path="/404" component={NotFound} />
                    <Redirect from="*" to="/404" />
                </Switch>
                <Footer />
            </div>
        );
    }
}

class PrivateSection extends Component {
    render() {
        return (
            <div>
                <Sidenav />
                <div className="main">
                    <PrivateHeader />
                    <Switch>
                        <PrivateRoute
                            component={Dashboard}
                            path="/lms/dashboard"
                            exact
                        />
                        <PrivateRoute
                            component={Learning}
                            path="/lms/learn"
                            exact
                        />
                        <PrivateRoute
                            component={LearningDetail}
                            path="/lms/learn/detail/:id"
                            exact
                        />
                        <PrivateRoute
                            component={AddCourse}
                            path="/lms/learn/create"
                            exact
                        />
                        <PrivateRoute
                            component={EditCourse}
                            path="/lms/learn/edit/:id"
                            exact
                        />

                        <PrivateRoute
                            component={Profile}
                            path="/lms/profile"
                            exact
                        />
                        <PrivateRoute
                            component={TableDetail}
                            path="/lms/profile/activity/:id"
                            exact
                        />
                        <PrivateRoute
                            component={ChangePassword}
                            path="/lms/changepassword"
                            exact
                        />
                        <PrivateRoute component={User} path="/lms/user" exact />
                        <PrivateRoute
                            component={DetailUser}
                            path="/lms/user/detail/:id"
                            exact
                        />
                        <PrivateRoute
                            component={EditUser}
                            path="/lms/user/edit/:id"
                            exact
                        />
                        <PrivateRoute
                            component={AddUser}
                            path="/lms/user/create"
                            exact
                        />
                        <PrivateRoute
                            component={Forum}
                            path="/lms/forum"
                            exact
                        />
                        <PrivateRoute
                            component={ForumDetail}
                            path="/lms/forum/detail/:id"
                            exact
                        />
                        <PrivateRoute
                            component={AddForum}
                            path="/lms/forum/create"
                            exact
                        />
                        <PrivateRoute
                            component={EditForum}
                            path="/lms/forum/edit/:id"
                            exact
                        />

                        <PrivateRoute component={Role} path="/lms/role" exact />
                        <PrivateRoute
                            component={AddRole}
                            path="/lms/role/create"
                            exact
                        />
                        <PrivateRoute
                            component={EditRole}
                            path="/lms/role/edit/:id"
                            exact
                        />

                        <PrivateRoute
                            component={Category}
                            path="/lms/category"
                            exact
                        />
                        <PrivateRoute
                            component={AddCategory}
                            path="/lms/category/create"
                            exact
                        />
                        <PrivateRoute
                            component={EditCategory}
                            path="/lms/category/edit/:id"
                            exact
                        />
                        <PrivateRoute
                            component={Permission}
                            path="/lms/permission/:id"
                            exact
                        />
                        <PrivateRoute component={Log} path="/lms/log" exact />
                    </Switch>
                </div>
            </div>
        );
    }
}

function App() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        <div className="App">
            {loading ? (
                <div className="loader-center">
                    <img
                        src={require("./assets/images/favicon.svg").default}
                        alt=""
                    />
                    {/* <PropagateLoader
                        color={"#ff9819"}
                        loading={loading}
                        size={15}
                    /> */}
                </div>
            ) : (
                <Router>
                    <Switch>
                        <Route path="/lms" component={PrivateSection} />
                        <Route path="/" component={PublicSection} />
                    </Switch>
                </Router>
            )}
        </div>
    );
}

export default App;
