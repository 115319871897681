import {
    ADD_COURSE,
    ADD_COURSE_SECTION,
    COURSE_ERROR,
    GET_COURSE,
    GET_COURSE_CP,
    GET_COURSE_DETAIL,
    GET_COURSE_DETAIL_DATA,
    GET_COURSE_USER_CREATED,
    UPDATE_COURSE,
    UPDATE_COURSE_SECTION,
    WISHLIST_COURSE,
} from "../types";

const initialState = {
    courses: false,
    coursesUser: false,
    course: false,
    courseCp: false,
    detail: false,
    member: false,
    category: false,
    loading: true,
    responseCourse: false,
    wishlist: false,
    error: false,
};

export const coursesReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_COURSE:
            return {
                ...state,
                courses: payload,
                loading: false,
            };
        case GET_COURSE_USER_CREATED:
            return {
                ...state,
                coursesUser: payload,
                loading: false,
            };
        case GET_COURSE_DETAIL:
            return {
                ...state,
                course: payload,
                detail: payload ? payload.details.data : false,
                member: payload ? payload.members.data : false,
                category: payload ? payload.category_id : false,
                loading: false,
            };
        case GET_COURSE_DETAIL_DATA:
            return {
                ...state,
                detail: payload,
                member: payload ? payload.members.data : false,
                category: payload ? payload.category_id : false,
                loading: false,
            };
        case ADD_COURSE:
            return {
                ...state,
                responseCourse: payload,
                loading: false,
            };
        case UPDATE_COURSE:
            return {
                ...state,
                responseCourse: payload,
                loading: false,
            };
        case ADD_COURSE_SECTION:
            return {
                ...state,
                responseCourseSection: payload,
                loading: false,
            };
        case UPDATE_COURSE_SECTION:
            return {
                ...state,
                responseCourseSection: payload,
                loading: false,
            };
        case WISHLIST_COURSE:
            return {
                ...state,
                wishlist: payload,
                loading: false,
            };
        case COURSE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_COURSE_CP:
            return {
                ...state,
                courseCp: payload,
                loading: false,
            };
        default:
            return state;
    }
};
