import { getApi, putApi } from "../services/axios";
import { getToken, roleId } from "./utils";

var data = [];
export const getPermissions = async (status, limit, page, search) => {
    const path =
        "permission/list?status=" +
        (status ? status : "") +
        "&limit=" +
        (limit ? limit : "1000") +
        "&page=" +
        (page ? page : "") +
        "&search=" +
        (search ? search : "");

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getPermissionsGroup = async () => {
    const path = "permission/group";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getPermissionById = async (status) => {
    const path =
        "permission/detail-by-role-id/" + roleId() + "?status=" + status;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getPermissionByRole = async (id) => {
    const path = "permission/detail-by-role-id/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const getPermissionDetail = async (id) => {
    const path = "permission/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const setupPermission = async (data, id) => {
    const path = "permission/setup/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};
