import React, { createRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Tab,
  Tabs,
  Carousel,
  Card
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { isLogin } from "../../resources/utils";
import {
  BsCalendar,
  BiChevronDown,
  BiChevronRight,
  BiChevronLeft,
  BiImage,
  BsEyeFill,
  GiBrain,
  GoGraph,
  MdGroup,
  BiBook
} from "react-icons/all";
import "./home.scss";
import CountUp, { startAnimation } from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { CardScroll } from "../../components/CardScroll";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBerandaSettings,
  getTentangSettings
} from "../../redux/actions/settingsAction";
import {
  getHomePartner,
  getHomeCat,
  getHomeProduct,
  getHomeResearch,
  getHomeTimeline
} from "../../redux/actions/homeAction";
import { getAllCourse, getAllCourseCp } from "../../redux/actions/courseAction";
import { capitalizeFirstLetter } from "../../resources/helpers";

const Home = (props) => {
  const dispatch = useDispatch();
  const courseList = useSelector((state) => state.courses);
  const { courseCp } = courseList;
  const settingList = useSelector((state) => state.settings);
  const { beranda, tentang } = settingList;
  const homeList = useSelector((state) => state.home);
  const { partner, category, product, timeline } = homeList;
  const [cat, setCat] = useState([]);
  const [part, setPart] = useState([]);
  const [prod, setProduct] = useState([]);
  const [tl, setTimeline] = useState([]);
  const [data, setData] = useState([]);
  const [tentangData, setTentang] = useState([]);
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [id, setId] = useState([1, 2, 3, 4, 5, 6]);
  const [res, setRes] = useState([]);
  const [rowsToDisplay, setRowsToDisplay] = useState(7);
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState();
  const myRef = createRef();
  const history = useHistory();
  const showMore = () => {
    setExpanded(!expanded);
  };

  const onChangeVisibility = (isActive) => {
    setActive(isActive);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(getAllCourseCp());
    dispatch(getBerandaSettings());
    dispatch(getTentangSettings());
    dispatch(getHomeCat());
    dispatch(getHomePartner());
    dispatch(getHomeProduct());
    dispatch(getHomeTimeline());
    window.addEventListener("resize", handleWindowSizeChange);
    window.removeEventListener("resize", handleWindowSizeChange);
    document
      .getElementsByClassName(
        "react-horizontal-scrolling-menu--scroll-container"
      )[0]
      .setAttribute("id", "scroller");
  }, [dispatch]);

  useEffect(() => {
    setData(beranda);
    setCat(category);
    setPart(partner);
    setProduct(product);
    setTimeline(timeline);
    setTentang(tentang);
  }, [beranda]);

  return (
    <>
      <Carousel>
        <Carousel.Item className='parallax1 h100vh' interval={8000}>
          {data
            ? data.map((val, i) =>
                val.key === "slider1-image" ? (
                  <img
                    className='d-block w-100'
                    src={val.value}
                    alt='First slide'
                    key={i}
                  />
                ) : (
                  ""
                )
              )
            : ""}
          <div className='overlay'></div>
          <Carousel.Caption>
            <h1 className='text-center text-white font-size35r letter-spacing02r padding-lr50'>
              {data
                ? data.map((val, i) =>
                    val.key === "slider1-title" ? val.value : ""
                  )
                : ""}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='parallax1 h100vh' interval={8000}>
          {data
            ? data.map((val, i) =>
                val.key === "slider2-image" ? (
                  <img
                    className='d-block w-100'
                    src={val.value}
                    alt='First slide'
                    key={i}
                  />
                ) : (
                  ""
                )
              )
            : ""}
          <div className='overlay'></div>
          <Carousel.Caption>
            <h1 className='text-center text-white font-size35r letter-spacing02r padding-lr50'>
              {data
                ? data.map((val, i) =>
                    val.key === "slider2-title" ? val.value : ""
                  )
                : ""}
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className='section-1 padding-b30'>
        <Container>
          <div className='item-block'>
            <div className='item'>
              <span className='bg-icon'>
                <BsEyeFill size={65} />
              </span>
              <h5>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "visi-kuat" ? val.title : ""
                    )
                  : ""}
              </h5>
              <p className='text-elipsis-multi line4 text-c'>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "visi-kuat" ? val.value : ""
                    )
                  : ""}
              </p>
            </div>
            <div className='item'>
              <span className='bg-icon'>
                <GiBrain size={65} />
              </span>
              <h5>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "inkuiri" ? val.title : ""
                    )
                  : ""}
              </h5>
              <p className='text-elipsis-multi line4 text-c'>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "inkuiri" ? val.value : ""
                    )
                  : ""}
              </p>
            </div>
            <div className='item'>
              <span className='bg-icon'>
                <MdGroup size={65} />
              </span>
              <h5>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "kolaborasi" ? val.title : ""
                    )
                  : ""}
              </h5>
              <p className='text-elipsis-multi line4 text-c'>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "kolaborasi" ? val.value : ""
                    )
                  : ""}
              </p>
            </div>
            <div className='item'>
              <span className='bg-icon'>
                <GoGraph size={65} />
              </span>
              <h5>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "insight" ? val.title : ""
                    )
                  : ""}
              </h5>
              <p className='text-elipsis-multi line4 text-c'>
                {tentangData
                  ? tentangData.map((val, i) =>
                      val.key === "insight" ? val.value : ""
                    )
                  : ""}
              </p>
            </div>
          </div>
          <div className='button relative w100 h50p margin-tb10'>
            <Link
              to='/about'
              className='btn btn-primary none p-absolute transform-center left50 top50'>
              Selengkapnya
            </Link>
          </div>
        </Container>
      </div>
      <div className='section-2'>
        <div className='img-bg'>
          {data
            ? data.map((val, i) =>
                val.key === "counting-bg" ? (
                  <img src={val.value} alt={val.title} key={i} />
                ) : (
                  ""
                )
              )
            : ""}
        </div>
        <Container>
          <Row>
            <Col>
              <div className='item-main margin-b30'>
                <h2 className='font-size35r line-height35r'>
                  {data
                    ? data.map((val, i) =>
                        val.key === "counting-title" ? val.value : ""
                      )
                    : ""}
                </h2>
              </div>
            </Col>
            <Col>
              <div className='item-aside'>
                <div className='item'>
                  {data
                    ? data.map((val, i) =>
                        val.key === "seminar" ? (
                          <CountUp
                            key={i}
                            start={0}
                            duration={2.75}
                            end={parseInt(val.value)}
                            separator='.'
                            redraw={true}>
                            {({ countUpRef }) => (
                              <VisibilitySensor
                                onChange={onChangeVisibility}
                                active={!active}>
                                <span
                                  className='countUp font-size25r'
                                  ref={countUpRef}
                                />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <span className='font-size15r'> Peserta</span>
                  <p>Seminar</p>
                </div>
                <div className='item'>
                  {data
                    ? data.map((val, i) =>
                        val.key === "pelatihan-reg" ? (
                          <CountUp
                            key={i}
                            start={0}
                            end={val.value}
                            duration={3}
                            separator='.'
                            redraw={true}>
                            {({ countUpRef }) => (
                              <VisibilitySensor
                                onChange={onChangeVisibility}
                                active={!active}>
                                <span
                                  className='countUp font-size25r'
                                  ref={countUpRef}
                                />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <span className='font-size15r'> Peserta</span>
                  <p>Pelatihan Reguler</p>
                </div>
                <div className='item'>
                  {data
                    ? data.map((val, i) =>
                        val.key === "pelatihan-int-guru" ? (
                          <CountUp
                            key={i}
                            className='countUp font-size25r'
                            start={0}
                            end={val.value}
                            duration={3.25}
                            separator='.'
                            redraw={true}>
                            {({ countUpRef }) => (
                              <VisibilitySensor
                                onChange={onChangeVisibility}
                                active={!active}>
                                <span
                                  className='countUp font-size25r'
                                  ref={countUpRef}
                                />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <span className='font-size15r'> Guru &</span>
                  <br />
                  {data
                    ? data.map((val, i) =>
                        val.key === "pelatihan-int-sekolah" ? (
                          <CountUp
                            key={i}
                            className='countUp font-size25r'
                            start={0}
                            end={val.value}
                            duration={3.25}
                            separator='.'
                            redraw={true}>
                            {({ countUpRef }) => (
                              <VisibilitySensor
                                onChange={onChangeVisibility}
                                active={!active}>
                                <span
                                  className='countUp font-size25r'
                                  ref={countUpRef}
                                />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <span className='font-size15r'> Sekolah</span>
                  <p>Pelatihan Intensif</p>
                </div>
                <div className='item'>
                  {data
                    ? data.map((val, i) =>
                        val.key === "konfrensi-presenter" ? (
                          <CountUp
                            key={i}
                            className='countUp font-size25r'
                            start={0}
                            end={val.value}
                            duration={3.25}
                            separator='.'
                            redraw={true}>
                            {({ countUpRef }) => (
                              <VisibilitySensor
                                onChange={onChangeVisibility}
                                active={!active}>
                                <span
                                  className='countUp font-size25r'
                                  ref={countUpRef}
                                />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <span className='font-size15r'> Presenter &</span>
                  <br />
                  {data
                    ? data.map((val, i) =>
                        val.key === "konfrensi-riset" ? (
                          <CountUp
                            key={i}
                            className='countUp font-size25r'
                            start={0}
                            end={val.value}
                            duration={3.25}
                            separator='.'
                            redraw={true}>
                            {({ countUpRef }) => (
                              <VisibilitySensor
                                onChange={onChangeVisibility}
                                active={!active}>
                                <span
                                  className='countUp font-size25r'
                                  ref={countUpRef}
                                />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  <span className='font-size15r'> Riset</span>
                  <p>Konferensi PAUD</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id='timeline' name='scroll-to-element' className='section-3'>
        <VerticalTimeline animate={width <= 750 ? false : true}>
          {tl
            ? tl.map((val, i) => {
                const randomBetween = (min, max) =>
                  min + Math.floor(Math.random() * (max - min + 1));
                const r = randomBetween(100, 200);
                const g = randomBetween(100, 200);
                const b = randomBetween(100, 200);
                const background = `rgb(${r},${g},${b})`;
                return (
                  <VerticalTimelineElement
                    className='vertical-timeline-element--work timeline-text-red-color'
                    contentStyle={{
                      background: "#fff",
                      color: "#333333",
                      borderTop: `5px solid ${background}`
                    }}
                    key={i}
                    animate={false}
                    date={val.title}
                    iconStyle={{
                      background: background,
                      color: "#fff"
                    }}
                    icon={<BsCalendar />}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: val.description
                      }}></div>
                  </VerticalTimelineElement>
                );
              })
            : ""}
        </VerticalTimeline>
      </div>
      <div className='section-4'>
        <div className='relative margin-b10 h100p'>
          <h2 className='text-center text-white'>
            Riset yang Telah Dihasilkan
          </h2>
          <hr className='p-absolute left50 transform-l50' />
        </div>
        <CardScroll />
      </div>
      <div className='section-6'>
        <Container>
          <div className='relative margin-b10 h100p'>
            <h2 className='text-center'>Produk Pedagogi yang Dihasilkan</h2>
            <hr className='p-absolute left50 transform-l50' />
          </div>
          <Row>
            {prod
              ? prod.map((val, i) => (
                  <Col md={4} xs={12} key={i}>
                    <div className='card min-h230p'>
                      <img
                        className='img-center-parent'
                        src={val.image}
                        alt='buku-inkuiri-abcd'
                      />
                      <div className='overlay'>
                        <div className='card-title'>{val.title}</div>
                      </div>
                    </div>
                  </Col>
                ))
              : ""}
          </Row>
        </Container>
      </div>
      <div className='section-7'>
        <Container>
          <div className='relative margin-b10 h100p'>
            <h2 className='text-center'>Kursus yang Tersedia</h2>
            <hr className='p-absolute left50 transform-l50' />
          </div>
          <Row>
            {courseCp
              ? courseCp.map((val, i) =>
                  i < 3 ? (
                    <Col sm={12} md={4}>
                      <div
                        className='relative card'
                        style={{
                          height: "100px",
                          background: "#FFF"
                        }}>
                        <div
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "50%",
                            transform: "translate(-50%, -50%)"
                          }}>
                          <img
                            src={
                              require(`../../assets/images/shape1.svg`).default
                            }
                            alt=''
                            style={{
                              width: "400px",
                              height: "400px",
                              left: "-140px"
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: "9"
                          }}>
                          {val.title.length > 20 ? (
                            <>
                              <span className='text-label'>
                                {val.category_id.title}
                              </span>
                              <h5>
                                {capitalizeFirstLetter(
                                  val.title.substr(0, 20) + "..."
                                )}
                              </h5>
                              <p>
                                {val.description.length > 50
                                  ? capitalizeFirstLetter(
                                      val.description.substr(0, 70)
                                    ) + "..."
                                  : capitalizeFirstLetter(val.description)}
                              </p>
                            </>
                          ) : (
                            <>
                              <span className='text-label12'>
                                {val.category_id.title}
                              </span>
                              <h3>{capitalizeFirstLetter(val.title)}</h3>
                              <p>
                                {val.description.length > 50
                                  ? capitalizeFirstLetter(
                                      val.description.substr(0, 70)
                                    ) + "..."
                                  : capitalizeFirstLetter(val.description)}
                              </p>
                            </>
                          )}
                        </div>
                        <div className='overlay' style={{ zIndex: "10" }}>
                          <button
                            onClick={() =>
                              isLogin()
                                ? props.history.push("/lms/dashboard")
                                : props.history.push("/login")
                            }
                            className='btn-primary center'>
                            Bergabung
                          </button>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )
                )
              : ""}
          </Row>
          {/* <Row>
                        {courseCp
                            ? courseCp.map((val, i) =>
                                  i === 0 ? (
                                      <Col md={6} xs={12} key={i}>
                                          <div
                                              className={`card card${+1} h-100`}
                                          >
                                              <img
                                                  className="img-center-parent"
                                                  src={
                                                      val.image
                                                          ? val.image
                                                          : require(`../../assets/images/courses${
                                                                i + 1
                                                            }.svg`).default
                                                  }
                                                  alt={val.title}
                                              />
                                              <div className="card-title big">
                                                  {val.title}
                                              </div>
                                              <div className="overlay">
                                                  <button
                                                      onClick={() =>
                                                          isLogin()
                                                              ? props.history.push(
                                                                    "/lms/dashboard"
                                                                )
                                                              : props.history.push(
                                                                    "/login"
                                                                )
                                                      }
                                                      className="btn-primary center"
                                                  >
                                                      Bergabung
                                                  </button>
                                              </div>
                                          </div>
                                      </Col>
                                  ) : (
                                      ""
                                  )
                              )
                            : ""}
                        <Col md={6}>
                            <Row className="gap">
                                {courseCp
                                    ? courseCp.map((val, i) =>
                                          i > 0 ? (
                                              <Col md={6} xs={12} key={i}>
                                                  <div
                                                      className={`card card${
                                                          i + 1
                                                      } h-100`}
                                                  >
                                                      <img
                                                          className="img-center-parent padding50"
                                                          src={
                                                              val.image
                                                                  ? val.image
                                                                  : require(`../../assets/images/courses${
                                                                        i + 1
                                                                    }.svg`)
                                                                        .default
                                                          }
                                                          alt={val.title}
                                                      />
                                                      <div className="card-title ">
                                                          {val.title}
                                                      </div>
                                                      <div className="overlay">
                                                          <button
                                                              onClick={() =>
                                                                  isLogin()
                                                                      ? props.history.push(
                                                                            "/lms/dashboard"
                                                                        )
                                                                      : props.history.push(
                                                                            "/login"
                                                                        )
                                                              }
                                                              className="btn-primary center"
                                                          >
                                                              Bergabung
                                                          </button>
                                                      </div>
                                                  </div>
                                              </Col>
                                          ) : (
                                              ""
                                          )
                                      )
                                    : ""}
                            </Row>
                        </Col>
                    </Row> */}
        </Container>
      </div>
      <div className='section-5'>
        <Container>
          <div className='relative margin-b10 h100p'>
            <h2 className='text-center'>Partner Kami</h2>
            <hr className='p-absolute left50 transform-l50' />
          </div>
          <Tabs
            defaultActiveKey='1'
            transition={false}
            id='noanim-tab-example'
            className='mb-3 tab-content margin-t30'>
            {cat
              ? cat.map((val, i) => {
                  var count = 0;
                  return (
                    <Tab key={i} eventKey={i + 1} title={val.title}>
                      <Row>
                        {part
                          ? part.map((value, i) => {
                              if (value.category_id === val.id) {
                                count += 1;
                                if (!expanded) {
                                  if (count < 8) {
                                    return (
                                      <Col key={i} md={3} xs={6}>
                                        <div className='card min-h230p'>
                                          <img
                                            src={value.image}
                                            alt={value.title}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  }
                                } else {
                                  return (
                                    <Col key={i} md={3} xs={6}>
                                      <div className='card min-h230p'>
                                        <img
                                          src={value.image}
                                          alt={value.title}
                                        />
                                      </div>
                                    </Col>
                                  );
                                }
                              }
                            })
                          : ""}
                        {count > 7 ? (
                          <Col md={3} xs={6} key={i}>
                            <div onClick={showMore} className='card min-h230p'>
                              {expanded ? (
                                <BiChevronLeft size={70} color='#ff9819' />
                              ) : (
                                <BiChevronRight size={70} color='#ff9819' />
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Tab>
                  );
                })
              : ""}
            {/* <Tab eventKey="1" title="Government Institution ">
                            <Row>
                                {partner1.map((item, index) => (
                                    <Col md={3} xs={6}>
                                        <div className="card min-h230p">
                                            <img
                                                src={
                                                    require(`../../assets/images/contents/${item}`)
                                                        .default
                                                }
                                                alt={`Partner ${index + 1}`}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                {partner1.length > 7 && (
                                    <Col md={3} xs={6}>
                                        <div
                                            onClick={showMore}
                                            className="card min-h230p"
                                        >
                                            {expanded ? (
                                                <BiChevronLeft
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            ) : (
                                                <BiChevronRight
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Tab>
                        <Tab eventKey="2" title="National Partners">
                            <Row>
                                {partner2
                                    .slice(0, expanded ? 99 : 7)
                                    .map((item, index) => (
                                        <Col md={3} xs={6}>
                                            <div className="card min-h230p">
                                                <img
                                                    src={
                                                        require(`../../assets/images/contents/${item}`)
                                                            .default
                                                    }
                                                    alt={`Partner ${index + 1}`}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                {partner2.length > 7 && (
                                    <Col md={3} xs={6}>
                                        <div
                                            onClick={showMore}
                                            className="card min-h230p"
                                        >
                                            {expanded ? (
                                                <BiChevronLeft
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            ) : (
                                                <BiChevronRight
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Tab>
                        <Tab eventKey="3" title="International Partners">
                            <Row>
                                {partner3.map((item, index) => (
                                    <Col md={3} xs={6}>
                                        <div className="card min-h230p">
                                            <img
                                                src={
                                                    require(`../../assets/images/contents/${item}`)
                                                        .default
                                                }
                                                alt={`Partner ${index + 1}`}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                {partner3.length > 7 && (
                                    <Col md={3} xs={6}>
                                        <div
                                            onClick={showMore}
                                            className="card min-h230p"
                                        >
                                            {expanded ? (
                                                <BiChevronLeft
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            ) : (
                                                <BiChevronRight
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Tab>
                        <Tab eventKey="4" title="University Partners">
                            <Row>
                                {partner4.map((item, index) => (
                                    <Col md={3} xs={6}>
                                        <div className="card min-h230p">
                                            <img
                                                src={
                                                    require(`../../assets/images/contents/${item}`)
                                                        .default
                                                }
                                                alt={`Partner ${index + 1}`}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                {partner4.length > 7 && (
                                    <Col md={3} xs={6}>
                                        <div
                                            onClick={showMore}
                                            className="card min-h230p"
                                        >
                                            {expanded ? (
                                                <BiChevronLeft
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            ) : (
                                                <BiChevronRight
                                                    size={70}
                                                    color="#ff9819"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Tab> */}
          </Tabs>
        </Container>
      </div>
    </>
  );
};

export default Home;
