import { useSSRSafeId } from "@react-aria/ssr";
import React, { useEffect, useState } from "react";
import { ButtonGroup, Form } from "react-bootstrap";
import {
  BiArrowFromBottom,
  BiArrowFromTop,
  AiFillLike,
  AiFillDislike
} from "react-icons/all";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllComment,
  getCommentByPost
} from "../redux/actions/commentAction";
import {
  getForumsID,
  getResponse,
  updateDislikes,
  updateLikes
} from "../redux/actions/forumAction";
import { userDetail } from "../redux/actions/userActions";
import {
  CREATE_COMMENT,
  ERROR_COMMENT,
  ERROR_RESPONSE,
  UPDATE_LIKES
} from "../redux/types";
import { createComment } from "../resources/comment";
import { dislike, like } from "../resources/forum";
import {
  createImageFromInitials,
  getRandomColor
} from "../resources/utilities";
import { userId } from "../resources/utils";
import { Vote } from "./Bookmark";

export function CommentForum({ total, list, id }) {
  const dispatch = useDispatch();
  const responseList = useSelector((state) => state.forum);
  const { response } = responseList;
  const [resp, setResponse] = useState([]);

  const handleLike = async (event) => {
    event.preventDefault();
    try {
      const res = await like(event.target.id);

      dispatch({
        type: UPDATE_LIKES,
        payload: res
      });

      if (res.status === "success" || res !== undefined) {
        dispatch(getResponse(id));
        setResponse(response);
      }
    } catch (e) {
      dispatch({
        type: ERROR_RESPONSE,
        payload: false
      });
    }
  };

  const handleDislike = async (event) => {
    event.preventDefault();
    try {
      const res = await dislike(event.target.id);

      dispatch({
        type: UPDATE_LIKES,
        payload: res
      });

      if (res.status === "success" || res !== undefined) {
        dispatch(getResponse(id));
        setResponse(response);
      }
    } catch (e) {
      dispatch({
        type: ERROR_RESPONSE,
        payload: false
      });
    }
  };
  const sortA = () => {
    const sorted = [...resp].sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });
    setResponse(sorted);
    //alert(sorted[0].name);
  };

  const sortD = () => {
    const sorted = [...resp].sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    setResponse(sorted);
    //alert(sorted[0].name);
  };

  useEffect(() => {
    setResponse(list);
  }, [list]);

  useEffect(() => {}, [resp]);
  return (
    <div className='detail-list'>
      <div className='detail-info'>
        <span>{total} Balasan</span>
        <ButtonGroup aria-label='Basic example'>
          <button onClick={sortD} className='btn btn-clear'>
            <BiArrowFromTop />
          </button>
          <button onClick={sortA} className='btn btn-clear'>
            <BiArrowFromBottom />
          </button>
        </ButtonGroup>
      </div>
      {resp.length > 0 &&
        resp.map((item, i) => {
          return (
            <div key={i} className='detail-content comment'>
              <div className='detail-top'>
                <img
                  className='rounded-img'
                  id='preview'
                  src={createImageFromInitials(
                    500,
                    item.created_by ? item.created_by.name : "Unknown",
                    getRandomColor()
                  )}
                  alt='profile-pic'
                />
                <div className='detail-inside'>
                  <span className='name'>{item.created_by.name}</span>
                  <span>
                    <Moment locale='id' fromNow>
                      {item.created_at}
                    </Moment>
                  </span>
                  <div className='content'>
                    <div className='text'>
                      <p>{item.response}</p>
                    </div>
                  </div>
                  {/* <h1>Pembelajaran terhadap anak usia 5 sampai 10 tahun</h1> */}
                </div>
              </div>

              <div className='detail-bottom'>
                <div className='btn-group'>
                  <button
                    id={item.id}
                    onClick={handleLike}
                    className='btn-vote'>
                    <AiFillLike /> {item.like}
                  </button>
                  <button
                    id={item.id}
                    onClick={handleDislike}
                    className='btn-vote'>
                    <AiFillDislike /> {item.dislike}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function CommentCourse({ data, id, total, child, status }) {
  const dispatch = useDispatch();
  const commentList = useSelector((state) => state.comments);
  const { comments } = commentList;
  const [list, setList] = useState([]);
  const [more, setMore] = useState(false);
  const [show, setShow] = useState(false);
  const [replyText, setReplyText] = useState("");

  const sortA = () => {
    const sorted = [...list].sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });
    setList(sorted);
    //alert(sorted[0].name);
  };

  const sortD = () => {
    const sorted = [...list].sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    setList(sorted);
    //alert(sorted[0].name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    data.set("title", "title");
    data.set("post_id", id);
    data.set("type", "reply");
    data.set("status", "active");

    try {
      const res = await createComment(data);

      dispatch({
        type: CREATE_COMMENT,
        payload: res
      });

      if (res.status === "success" || res !== undefined) {
        dispatch(getCommentByPost(id));
        setList(comments);
        setReplyText("");
      }
    } catch (e) {
      dispatch({
        type: ERROR_COMMENT,
        payload: false
      });
    }
  };

  const showMore = () => {
    setMore(!more);
  };

  const toggleComment = (id) => {
    setShow((prevShownComments) => ({
      ...prevShownComments,
      [id]: !prevShownComments[id]
    }));
  };

  useEffect(() => {
    setList(data);
  }, [data]);

  return (
    <div className='comment-list'>
      <div className='comments-all'>
        <span>Semua pertanyaan ({total})</span>

        <ButtonGroup aria-label='Basic example'>
          <button onClick={sortD} className='btn btn-clear'>
            <BiArrowFromTop />
          </button>
          <button onClick={sortA} className='btn btn-clear'>
            <BiArrowFromBottom />
          </button>
        </ButtonGroup>
      </div>
      {list.length > 0 &&
        list.slice(0, more ? 9999 : 5).map((item, index) => {
          return item.post_id == id ? (
            <div key={index} className='comment-card'>
              <div className='comment-top'>
                <img src={require("../assets/images/profile.jpg").default} />
              </div>
              <div className='comment-detail'>
                <div className='title'>
                  <span>{item.created_by.name}</span>
                  <span className='date'>
                    <Moment locale='id' fromNow>
                      {item.created_at}
                    </Moment>
                  </span>
                </div>
                <p>{item.description}</p>
                <div className='comment-bottom'>
                  <a
                    className={status ? "" : "disabled"}
                    onClick={() => (status ? toggleComment(index) : "")}>
                    Reply({item.reply.data.length}){" "}
                  </a>
                  {child}
                  {item.reply
                    ? item.reply.data.map((child, i) =>
                        show[index] ? (
                          <div key={i} className='comment-child'>
                            <div className='comment-top'>
                              <img
                                src={
                                  require("../assets/images/profile.jpg")
                                    .default
                                }
                              />
                            </div>
                            <div className='comment-detail'>
                              <div className='title'>
                                <span>{child.created_by.name}</span>
                                <span className='date'>
                                  <Moment locale='id' fromNow>
                                    {child.created_at}
                                  </Moment>
                                </span>
                              </div>
                              <p>{child.description}</p>
                            </div>
                          </div>
                        ) : null
                      )
                    : " "}

                  {show[index] ? (
                    <Form onSubmit={handleSubmit} className='comment-form'>
                      <Form.Control
                        style={{ display: "none" }}
                        name='reply_to'
                        value={item.id}
                      />
                      <Form.Control
                        as='textarea'
                        name='description'
                        className='w-100'
                        value={replyText}
                        onChange={(e) => {
                          setReplyText(e.target.value);
                        }}
                        placeholder='Tulis pesan anda disini... '
                      />
                      <button type='submit' className='answer'>
                        Kirim
                      </button>
                    </Form>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            ""
          );
        })}
      <span className='more-btn' onClick={showMore}>
        {more ? "Show Less" : "Show More"}
      </span>
    </div>
  );
}
