import firebase from "firebase";
var firebaseConfig = {
    apiKey: "AIzaSyA4KxdxKw6Poj8Vp0TG2_M3GDQWRa1R97E",
    authDomain: "kelas-lentera.firebaseapp.com",
    projectId: "kelas-lentera",
    storageBucket: "kelas-lentera.appspot.com",
    messagingSenderId: "379600315045",
    appId: "1:379600315045:web:bbed47c6aa77fb1c77a34c",
    measurementId: "G-1ZS0BQ7HLS",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const google = new firebase.auth.GoogleAuthProvider();
const facebook = new firebase.auth.FacebookAuthProvider();

export { auth, google, facebook };
