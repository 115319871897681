import { deleteApi, getApi, postApi, putApi } from "../services/axios";
import { getToken, roleId } from "./utils";

var data = [];

export const getRoles = async (status, limit, page, search) => {
    const path =
        "role/list?status=" +
        (status ? status : "") +
        "&limit=" +
        (limit ? limit : "") +
        "&page=" +
        (page ? page : "") +
        "&search=" +
        (search ? search : "");

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const deleteRole = async (id) => {
    const path = `role/delete/` + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await deleteApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
            getRoles();
        } else {
            data = null;
        }
    });

    return data;
};

export const getRoleDetail = async (id) => {
    const path = "role/detail/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await getApi(path, headers).then((result) => {
        if (result.status_code === 200) {
            data = result.data;
        } else {
            data = null;
        }
    });

    return data;
};

export const createRole = async (data) => {
    const path = "role/create";

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await postApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};

export const updateRole = async (data, id) => {
    const path = "role/update/" + id;

    const token = "Bearer " + getToken();
    const headers = {
        headers: {
            Authorization: token,
        },
    };

    await putApi(path, data, headers).then((result) => {
        data = result;
    });

    return data;
};
