import {
    getPermissionByRole,
    getPermissionDetail,
    getPermissions,
    getPermissionsGroup,
} from "../../resources/permission";
import {
    ERROR_PERMISSION,
    GET_PERMISSION,
    GET_PERMISSION_DETAIL,
    GET_PERMISSION_DETAIL_ROLE_ID,
    GET_PERMISSION_GROUP,
    UPDATE_PERMISSION,
} from "../types";

export const getAllPermissions = () => async (dispatch) => {
    try {
        const res = await getPermissions();
        dispatch({
            type: GET_PERMISSION,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_PERMISSION,
            payload: console.log(e),
        });
    }
};

export const getGroupPermissions = () => async (dispatch) => {
    try {
        const res = await getPermissionsGroup();
        dispatch({
            type: GET_PERMISSION_GROUP,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_PERMISSION,
            payload: console.log(e),
        });
    }
};

export const getDetailPermission = (id) => async (dispatch) => {
    try {
        const res = await getPermissionDetail(id);
        dispatch({
            type: GET_PERMISSION_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: ERROR_PERMISSION,
            payload: console.log(e),
        });
    }
};

export const getDetailPermissionById = (id) => async (dispatch) => {
    try {
        const res = await getPermissionByRole(id);

        var data = false;
        res.length === 0 ? (data = false) : (data = res);

        dispatch({
            type: GET_PERMISSION_DETAIL_ROLE_ID,
            payload: data,
        });
    } catch (e) {
        dispatch({
            type: ERROR_PERMISSION,
            payload: console.log(e),
        });
    }
};

export const clearPermission = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_PERMISSION_DETAIL_ROLE_ID,
            payload: false,
        });

        dispatch({
            type: GET_PERMISSION_DETAIL,
            payload: false,
        });

        dispatch({
            type: UPDATE_PERMISSION,
            payload: false,
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: ERROR_PERMISSION,
            payload: console.log(e),
        });
    }
};
