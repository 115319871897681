import React from "react";
import { Col, Container, Row, Card, Spinner } from "react-bootstrap";
import {
    BiMessageSquareDetail,
    BiUser,
    BiStar,
    BsListUl,
    BsPencilSquare,
    HiMinusCircle,
    FaPen,
} from "react-icons/all";
import { connect, useDispatch, useSelector } from "react-redux";
import { CategoryList } from "../../../components/Color";
import {
    getToken,
    roleId,
    userId,
    userPermissions,
} from "../../../resources/utils";
import { clearCourse, getAllCourse } from "../../../redux/actions/courseAction";
import "./learning.scss";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useState } from "react";
import { deleteApi } from "../../../services/axios";
import swal from "sweetalert";
import { getDetailPermissionById } from "../../../redux/actions/permissionAction";
import { clearComment } from "../../../redux/actions/commentAction";
import { getAllCategory } from "../../../redux/actions/categoryAction";
import {
    capitalizeFirstLetter,
    CheckAdmin,
    CheckReadOnly,
    CheckReadWrite,
    CompareDateNow,
} from "../../../resources/helpers";

const Learning = (props) => {
    const dispatch = useDispatch();
    const courseList = useSelector((state) => state.courses);
    const categoryList = useSelector((state) => state.category);
    const { categories } = categoryList;
    const { courses } = courseList;
    const { isUser, setUser } = useState(false);
    const [loading, setLoading] = useState(true);
    const [course, setCourse] = useState([]);
    const [userPerm, setUserPermission] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        dispatch(getAllCategory());
        dispatch(getAllCourse());
        dispatch(clearCourse());
    }, [dispatch]);

    const goCourse = () => {
        props.history.push("/lms/learn/create");
    };

    const handleDelete = (id) => {
        swal({
            title: "Apakah anda yakin?",
            text: "Data yang sudah dihapus tidak dapat dikembalikan!",
            icon: "warning",
            buttons: {
                cancel: "Batal",
                danger: {
                    text: "Hapus",
                    value: true,
                },
            },
            dangerMode: true,
        }).then((value) => {
            if (value) {
                const url = `course/delete/${id}`;
                const token = "Bearer " + getToken();
                const headers = {
                    headers: {
                        Authorization: token,
                    },
                };

                deleteApi(url, headers)
                    .then((res) => {
                        if (res.status !== "error") {
                            dispatch(getAllCourse());
                            swal("Data kursus berhasil dihapus!", {
                                icon: "success",
                            });
                        } else {
                            swal("Data kursus gagal dihapus!", {
                                icon: "error",
                                buttons: { cancel: "Kembali" },
                            });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    function formatDate(str, separate, format) {
        var d = new Date(str),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        var form = [];

        if (format === 1) {
            form = [year, month, day];
        } else if (format === 2) {
            form = [day, month, year];
        }

        var dateFormat = form.join(separate);
        return dateFormat;
    }

    function checkAdmin() {
        let status;
        userPerm.map((val) =>
            val === "access_admin_course" ? (status = true) : ""
        );
        return status;
    }

    const filter = (id, index) => {
        console.log(id, index);
        const filterCourse = courses.filter((val) => val.category_id.id === id);
        if (id === "All") {
            setCourse(courses);
        } else {
            setCourse(filterCourse);
        }
        setActiveIndex(index);
    };

    const filterDraft = () => {
        const filterD = courses
            ? courses.filter((val) => val.status !== "draft")
            : "";

        return filterD;
    };

    const filterUser = () => {
        const filterU = courses
            ? courses.filter((val) => val.created_by.id === userId())
            : "";

        return filterU;
    };

    useEffect(() => {
        setTimeout(
            function () {
                setLoading(false);
            }.bind(this),
            3000
        );
    }, []);

    useEffect(() => {
        CheckAdmin(userPermissions(), "access_admin_course")
            ? setCourse(courses)
            : CheckReadWrite(
                  userPermissions(),
                  "access_read_course",
                  "access_write_course"
              )
            ? setCourse(filterUser())
            : setCourse(filterDraft());
        setUserPermission(userPermissions());
    }, [courses]);

    const columns = [
        {
            name: "Judul",
            cell: (course) => (
                <>
                    <Link to={"/lms/learn/detail/" + course.id}>
                        {capitalizeFirstLetter(course.title)}
                    </Link>
                </>
            ),
            sortable: true,
            reorder: true,
        },
        {
            name: "Tanggal Buka",
            selector: (course) => formatDate(course.open_date, "/", 2),
            sortable: true,
            reorder: true,
        },
        {
            name: "Tanggal Tutup",
            selector: (course) => formatDate(course.closed_date, "/", 2),
            sortable: true,
            reorder: true,
        },
        {
            name: "Kategori",
            cell: (course) =>
                course.category_id.title ? course.category_id.title : "-",
            sortable: true,
            reorder: true,
        },
        {
            name: "Status",
            cell: (course) => (
                <>
                    <span className={`u-status ${course.status}`}>
                        {course.status === "active"
                            ? "Aktif"
                            : course.status === "draft"
                            ? "Draft"
                            : "Tidak Aktif"}
                    </span>
                </>
            ),
            sortable: true,
            width: "120px",
        },
        {
            width: "80px",
            cell: (course) => (
                <>
                    <span>
                        <Link
                            className="btn-edit"
                            to={"/lms/learn/edit/" + course.id}
                        >
                            <FaPen />
                        </Link>{" "}
                        |{" "}
                        <a
                            className="btn-delete"
                            onClick={() => handleDelete(course.id)}
                        >
                            <HiMinusCircle />
                        </a>
                    </span>
                </>
            ),
            right: true,
        },
    ];

    return (
        <div className="margin-t30 learning">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div className="top-header margin-b30">
                            <div className="title">
                                <BiMessageSquareDetail />
                                <h3>Kursus</h3>
                            </div>
                            {userPerm
                                ? userPerm.map((val, i) =>
                                      val === "access_write_course" ? (
                                          <button
                                              key={i}
                                              onClick={goCourse}
                                              className="btn-transform"
                                          >
                                              <span className="btn-icon">
                                                  <BsPencilSquare />
                                              </span>

                                              <span className="btn-text">
                                                  Tambah Baru
                                              </span>
                                          </button>
                                      ) : (
                                          ""
                                      )
                                  )
                                : ""}
                        </div>
                    </Col>
                </Row>
                <div className="">
                    <Row>
                        <Col xs={12} lg={8}>
                            {CheckReadWrite(
                                userPermissions(),
                                "access_read_course",
                                "access_write_course"
                            ) ? (
                                <Card className="min-h95p  padding10 margin-b30">
                                    {course ? (
                                        <DataTable
                                            columns={columns}
                                            data={course}
                                            defaultSortFieldId={1}
                                            pagination
                                            paginationRowsPerPageOptions={[
                                                5, 10, 25, 50, 100,
                                            ]}
                                        />
                                    ) : loading ? (
                                        <Spinner
                                            animation="border"
                                            className="p-absolute left50"
                                        />
                                    ) : (
                                        <span className="p-absolute left50 transform-l50">
                                            Data tidak ditemukan
                                        </span>
                                    )}
                                </Card>
                            ) : (
                                <Row>
                                    {course ? (
                                        course.slice(0, 9).map((courses, i) => (
                                            <Col
                                                key={i}
                                                md={6}
                                                lg={4}
                                                className="learn-col"
                                                onClick={() => {
                                                    props.history.push(
                                                        "/lms/learn/detail/" +
                                                            courses.id
                                                    );
                                                }}
                                            >
                                                <Card className="learn-card">
                                                    {courses.image !== "" ? (
                                                        <Card.Img
                                                            className="learn-card-image no-padding"
                                                            variant="top"
                                                            src={courses.image}
                                                        />
                                                    ) : (
                                                        <Card.Img
                                                            className="learn-card-image"
                                                            variant="top"
                                                            src={
                                                                require(`../../../assets/images/courses${
                                                                    i + 1
                                                                }.svg`).default
                                                            }
                                                        />
                                                    )}
                                                    <Card.Body>
                                                        <div className="card-body-top">
                                                            {"Author: " +
                                                                courses
                                                                    .created_by
                                                                    .name}
                                                        </div>
                                                        <div className="card-body-title">
                                                            {courses.title.substring(
                                                                0,
                                                                40
                                                            ) + "..."}
                                                        </div>
                                                        <div className="card-body-desc">
                                                            {CompareDateNow(
                                                                courses.closed_date
                                                            ) ? (
                                                                <span
                                                                    className={`u-status active`}
                                                                >
                                                                    Open
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className={`u-status not-active`}
                                                                >
                                                                    Closed
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="card-body-bottom">
                                                            <div className="user">
                                                                <BiUser />
                                                                <span>4</span>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))
                                    ) : loading ? (
                                        <Col
                                            lg={12}
                                            className="learn-col cursor pointer relative"
                                        >
                                            <span className="p-absolute left50 transform-l50">
                                                <Spinner animation="border" />
                                            </span>
                                        </Col>
                                    ) : (
                                        <Col
                                            lg={12}
                                            className="learn-col cursor pointer relative"
                                        >
                                            <span className="p-absolute left50 transform-l50">
                                                Data tidak ditemukan
                                            </span>
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="side-block">
                                <div className="category-block margin-b30">
                                    <span className="title-block">
                                        <BsListUl /> Kategori
                                    </span>
                                    <div className="category-item">
                                        <CategoryList
                                            className={activeIndex === 0}
                                            click={() => filter("All", 0)}
                                            name="Semua"
                                        />
                                        {categories
                                            ? categories.map((val, i) => (
                                                  <CategoryList
                                                      className={
                                                          activeIndex === i + 1
                                                      }
                                                      click={() =>
                                                          filter(val.id, i + 1)
                                                      }
                                                      key={i}
                                                      name={val.title}
                                                  />
                                              ))
                                            : ""}
                                    </div>
                                </div>
                                <div className="hr"></div>
                                <div className="learn-top margin-t30">
                                    <span className="title-block">
                                        <BiStar /> Kursus Terbaru
                                    </span>
                                    <Row>
                                        {courses ? (
                                            courses
                                                .slice(0, 3)
                                                .map((courses, i) => (
                                                    <Col
                                                        key={i}
                                                        lg={12}
                                                        className="learn-col cursor pointer"
                                                        onClick={() => {
                                                            props.history.push(
                                                                "/lms/learn/detail/" +
                                                                    courses.id
                                                            );
                                                        }}
                                                    >
                                                        <div className="learn-card-side">
                                                            {courses.image !==
                                                            "" ? (
                                                                <Card.Img
                                                                    className="learn-card-image no-padding"
                                                                    variant="top"
                                                                    src={
                                                                        courses.image
                                                                    }
                                                                />
                                                            ) : (
                                                                <Card.Img
                                                                    className="learn-card-image"
                                                                    variant="top"
                                                                    src={
                                                                        require(`../../../assets/images/courses${
                                                                            i +
                                                                            1
                                                                        }.svg`)
                                                                            .default
                                                                    }
                                                                />
                                                            )}
                                                            <div className="body">
                                                                <div className="card-body-top">
                                                                    {
                                                                        courses
                                                                            .created_by
                                                                            .name
                                                                    }
                                                                </div>
                                                                <div className="card-body-title">
                                                                    {courses.title.substring(
                                                                        0,
                                                                        15
                                                                    ) + "..."}
                                                                </div>
                                                                <div className="card-body-bottom">
                                                                    {formatDate(
                                                                        courses.created_at,
                                                                        "/",
                                                                        2
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))
                                        ) : loading ? (
                                            <Col
                                                lg={12}
                                                className="learn-col cursor pointer"
                                            >
                                                <Spinner animation="border" />
                                            </Col>
                                        ) : (
                                            <Col
                                                lg={12}
                                                className="learn-col cursor pointer"
                                            >
                                                <span>
                                                    Data tidak ditemukan
                                                </span>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default Learning;
