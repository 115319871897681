import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { BiChevronLeft, FiEdit3 } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { getCategoryById } from "../../../redux/actions/categoryAction";
import {
    CATEGORY_ERROR,
    GET_CATEGORY_DETAIL,
    UPDATE_CATEGORY,
} from "../../../redux/types";
import { getCategoryDetail, updateCategory } from "../../../resources/category";
import { getToken } from "../../../resources/utils";
import { putApi } from "../../../services/axios";
import "./category";

const EditCategory = (props) => {
    const dispatch = useDispatch();
    const catList = useSelector((state) => state.category);
    const { category } = catList;
    const [cat, setCat] = useState([]);
    const [error, setError] = useState("");
    const [isUser, setIsUser] = useState(false);

    const handleChange = (event) => {
        setCat({
            ...cat,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            title: cat.title,
            description: cat.description,
            status: cat.status,
        };

        try {
            const res = await updateCategory(data, props.match.params.id);

            dispatch({
                type: UPDATE_CATEGORY,
                payload: res,
            });

            if (res.status !== "success" || res === undefined) {
                setError(
                    "Terjadi kesalahan, silahkan dicoba beberapa saat lagi."
                );
            } else {
                swal({
                    title: "Sukses!",
                    text: "Data kategori berhasil diubah!",
                    icon: "success",
                    button: "Kembali!",
                }).then(() => {
                    dispatch({
                        type: GET_CATEGORY_DETAIL,
                        payload: false,
                    });
                    props.history.goBack();
                });
            }
        } catch (error) {
            dispatch({
                type: CATEGORY_ERROR,
                payload: false,
            });
        }
    };

    useEffect(() => {
        dispatch(getCategoryById(props.match.params.id));
    }, [dispatch, props]);

    useEffect(() => {
        setCat(category);
    }, [category]);

    return (
        <div className="margin-t30 forum">
            <Container fluid>
                <div className="top-header margin-b30">
                    <div className="title">
                        <FiEdit3 />
                        <h3>Ubah Kategori</h3>
                        {cat ? "" : <Spinner animation="border" />}
                    </div>
                </div>
            </Container>
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <Container fluid>
                        <Row>
                            <Col lg={8}>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Judul</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            placeholder="Masukkan judul kategori..."
                                            onChange={handleChange}
                                            value={cat.title}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Deskripsi</Form.Label>
                                        <Form.Control
                                            name="description"
                                            as="textarea"
                                            placeholder="Masukkan deskripsi kategori..."
                                            onChange={handleChange}
                                            value={cat.description}
                                        />
                                    </Form.Group>

                                    {error && (
                                        <>
                                            <Form.Text className="text-alert">
                                                *{error}
                                            </Form.Text>
                                            <br />
                                        </>
                                    )}

                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Simpan
                                    </button>
                                    <Link
                                        to="/lms/category"
                                        className="btn btn-clear-all display-inline-block"
                                    >
                                        <BiChevronLeft /> Kembali
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="card-form">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            name="status"
                                            size="sm"
                                            onChange={handleChange}
                                            value={cat.status || "active"}
                                        >
                                            <option value="" disabled>
                                                Pilih...
                                            </option>
                                            <option value="active">
                                                Aktif
                                            </option>
                                            <option value="not-active">
                                                Tidak Aktif
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
};

export default EditCategory;
