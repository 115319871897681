import React, { useEffect, useState } from "react";
import logo from "../logo.png";
import { Button, Container, Nav, Navbar, Accordion } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BiHomeAlt, BiMessageSquareDetail, BiX, BiUser } from "react-icons/all";
import { isLogin, isRemember, userName } from "../resources/utils";
import { Link as ScrollLink, scroller } from "react-scroll";
import $ from "jquery";
import { getAPI } from "../resources/utilities";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralSettings } from "../redux/actions/settingsAction";

const Header = (props) => {
    const dispatch = useDispatch();
    const settingList = useSelector((state) => state.settings);
    const { general } = settingList;
    const [data, setData] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categories, getCategories] = useState([]);

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0px";
    }

    const getCategory = () => {
        const url = `front/category/list?type=post&limit=100&sort=asc`;
        const token = "Bearer " + sessionStorage.getItem("token");
        const headers = {
            headers: {
                Authorization: token,
            },
        };

        getAPI(url, headers).then((data) => {
            data.data ? getCategories(data.data) : getCategories([]);
            setLoading(false);
        });
    };

    useEffect(() => {
        // Update the document title using the browser API
        getCategory();
        dispatch(getGeneralSettings());

        // const toggle = document.getElementById("toggle");
        // const sidebar = document.getElementById("mySidenav");
        // document.onclick = function (e) {
        //   console.log(e.target.id);
        //   if (
        //     e.target.id !== "mySidenav" &&
        //     e.target.id !== "toggle" &&
        //     e.target.id !== "dropdown"
        //   ) {
        //     sidebar.style.width = "0";
        //   }
        // };
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, [dispatch]);

    useEffect(() => {
        setData(general);
    }, [general]);

    return (
        <>
            <Navbar
                id="navbar"
                className={scroll ? "sticky" : null}
                collapseOnSelect
                expand="lg"
                variant="light"
            >
                <Container>
                    <div className="left">
                        <div
                            id="toggle"
                            className="closebtn"
                            onClick={() => openNav()}
                        ></div>

                        <div id="mySidenav" className="sidenav hide-desktop">
                            <button
                                className="openbtn"
                                style={{ height: "80px" }}
                                onClick={() => closeNav()}
                            >
                                <BiX size={30} />
                            </button>
                            <Nav className="flex-column">
                                <NavLink
                                    to="/"
                                    className="nav_link"
                                    exact
                                    activeClassName="active"
                                    onClick={() => closeNav()}
                                >
                                    Beranda
                                    <span className="dot-bottom hide-mobile"></span>
                                </NavLink>
                                <NavLink
                                    to="/about"
                                    className="nav_link"
                                    activeClassName="active"
                                    onClick={() => closeNav()}
                                >
                                    Tentang Kami
                                    <span className="dot-bottom hide-mobile"></span>
                                </NavLink>
                                <Accordion
                                    id="dropdown"
                                    defaultActiveKey="0"
                                    flush
                                >
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <NavLink
                                                to="/activity/all"
                                                onClick={() => closeNav()}
                                            >
                                                Program
                                            </NavLink>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion
                                                defaultActiveKey="0"
                                                flush
                                            >
                                                {categories.map((item, i) => {
                                                    return item.is_parent ==
                                                        1 ? (
                                                        <Accordion.Item
                                                            eventKey={i}
                                                        >
                                                            <Accordion.Header>
                                                                {" "}
                                                                <NavLink
                                                                    to={
                                                                        "/activity/" +
                                                                        item.id
                                                                    }
                                                                    onClick={() =>
                                                                        closeNav()
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </NavLink>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <ul>
                                                                    {categories.map(
                                                                        (
                                                                            sub,
                                                                            index
                                                                        ) => {
                                                                            return item.id ==
                                                                                sub.parent_id ? (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <NavLink
                                                                                        to={
                                                                                            "/activity/" +
                                                                                            sub.id
                                                                                        }
                                                                                        activeClassName="active"
                                                                                        onClick={() =>
                                                                                            closeNav()
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            sub.title
                                                                                        }
                                                                                    </NavLink>
                                                                                </li>
                                                                            ) : null;
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ) : (
                                                        ""
                                                    );
                                                })}
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                {/* <div className='dropdown'>
                  <NavLink
                    to='/activity/all'
                    spy={true}
                    smooth={true}
                    className='nav_link'
                    activeClassName='active'>
                    Program
                    <div className='dropdown-content'>
                      <ul>
                        {categories.map((item, i) => {
                          return item.is_parent == 1 ? (
                            <li key={i} className='has-submenu'>
                              <NavLink to={"/activity/" + item.id}>
                                {item.title}
                              </NavLink>
                              <ul className='submenu'>
                                {categories.map((sub, index) => {
                                  return item.id == sub.parent_id ? (
                                    <li key={index}>
                                      <NavLink
                                        to={"/activity/" + sub.id}
                                        className='sub-submenu-link'
                                        activeClassName='active'>
                                        {sub.title}
                                      </NavLink>
                                    </li>
                                  ) : null;
                                })}
                              </ul>
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </div>
                  </NavLink>
                  <span className='dot-bottom hide-mobile'></span>
                </div> */}
                                <NavLink
                                    to="/contact"
                                    className="nav_link"
                                    activeClassName="active"
                                    onClick={() => closeNav()}
                                >
                                    Kontak Kami
                                    <span className="dot-bottom hide-mobile"></span>
                                </NavLink>
                            </Nav>
                        </div>
                        <div className="brand hide-mobile">
                            <a href="/">
                                {data
                                    ? data.map((val, i) =>
                                          val.key === "logo" ? (
                                              <img
                                                  key={i}
                                                  alt="Content brand"
                                                  src={val.value}
                                              />
                                          ) : (
                                              ""
                                          )
                                      )
                                    : ""}
                            </a>
                        </div>
                    </div>
                    <div className="middle">
                        <div className="brand hide-desktop">
                            <a href="/">
                                {data
                                    ? data.map((val, i) =>
                                          val.key === "logo" ? (
                                              <img
                                                  key={i}
                                                  alt="Content brand"
                                                  src={val.value}
                                              />
                                          ) : (
                                              ""
                                          )
                                      )
                                    : ""}
                            </a>
                        </div>
                        <Nav className="me-auto hide-mobile">
                            <NavLink
                                to="/"
                                className="nav_link hide-mobile"
                                exact
                                activeClassName="active"
                            >
                                Beranda
                                <span className="dot-bottom hide-mobile"></span>
                            </NavLink>
                            <NavLink
                                to="/about"
                                className="nav_link hide-mobile"
                                activeClassName="active"
                            >
                                Tentang Kami
                                <span className="dot-bottom hide-mobile"></span>
                            </NavLink>
                            {/* <a href='/#timeline'>blah</a> */}

                            {/* <a
                id='goto'
                href='/#goto'
                className='nav_link hide-mobile'
                // onClick={() => scrollTo(-100)}>
              >
                Scroll To 100!
              </a> */}
                            <div className="dropdown">
                                <NavLink
                                    to="/activity/all"
                                    spy={true}
                                    smooth={true}
                                    className="nav_link hide-mobile"
                                    activeClassName="active"
                                >
                                    Program
                                    <div className="dropdown-content">
                                        <ul>
                                            {categories.map((item, i) => {
                                                return item.is_parent == 1 ? (
                                                    <li
                                                        key={i}
                                                        className="has-submenu"
                                                    >
                                                        <NavLink
                                                            to={
                                                                "/activity/" +
                                                                item.id
                                                            }
                                                        >
                                                            {item.title}
                                                        </NavLink>
                                                        <ul className="submenu">
                                                            {categories.map(
                                                                (
                                                                    sub,
                                                                    index
                                                                ) => {
                                                                    return item.id ==
                                                                        sub.parent_id ? (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to={
                                                                                    "/activity/" +
                                                                                    sub.id
                                                                                }
                                                                                className="sub-submenu-link hide-mobile"
                                                                                activeClassName="active"
                                                                            >
                                                                                {
                                                                                    sub.title
                                                                                }
                                                                            </NavLink>
                                                                        </li>
                                                                    ) : null;
                                                                }
                                                            )}
                                                        </ul>
                                                    </li>
                                                ) : null;
                                            })}
                                        </ul>
                                    </div>
                                </NavLink>
                                <span className="dot-bottom hide-mobile"></span>
                            </div>
                            <NavLink
                                to="/contact"
                                className="nav_link hide-mobile"
                                activeClassName="active"
                            >
                                Kontak Kami
                                <span className="dot-bottom hide-mobile"></span>
                            </NavLink>
                        </Nav>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            {isRemember() ? (
                                <NavLink
                                    to="/lms/dashboard"
                                    className="btn-primary"
                                    activeClassName="active"
                                >
                                    {userName()}
                                </NavLink>
                            ) : (
                                <>
                                    <NavLink
                                        to="/login"
                                        className="nav_link"
                                        activeClassName="active"
                                    >
                                        Masuk
                                    </NavLink>
                                    <NavLink
                                        to="/register"
                                        className="btn-primary register-btn"
                                        activeClassName="active"
                                    >
                                        Daftar
                                    </NavLink>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};
export default Header;
