import { getHomeCategory, getHomePost } from "../../resources/home";
import {
    GET_HOME_CATEGORY,
    GET_PARTNER,
    GET_PRODUCT,
    GET_RESEARCH,
    GET_TIMELINE,
    HOME_ERROR,
} from "../types";

export const getHomeTimeline = () => async (dispatch) => {
    try {
        const res = await getHomePost("timeline");
        dispatch({
            type: GET_TIMELINE,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: HOME_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeResearch = () => async (dispatch) => {
    try {
        const res = await getHomePost("research");
        dispatch({
            type: GET_RESEARCH,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: HOME_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeProduct = () => async (dispatch) => {
    try {
        const res = await getHomePost("product");
        dispatch({
            type: GET_PRODUCT,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: HOME_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomePartner = () => async (dispatch) => {
    try {
        const res = await getHomePost("partner");
        dispatch({
            type: GET_PARTNER,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: HOME_ERROR,
            payload: console.log(e),
        });
    }
};

export const getHomeCat = () => async (dispatch) => {
    try {
        const res = await getHomeCategory("partner");
        dispatch({
            type: GET_HOME_CATEGORY,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: HOME_ERROR,
            payload: console.log(e),
        });
    }
};
