import { GET_LOG, GET_LOG_DETAIL } from "../types";

const initialState = {
    logs: false,
    log: false,
    loading: true,
};

export const logReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_LOG:
            return {
                ...state,
                logs: payload,
                loading: false,
            };
        case GET_LOG_DETAIL:
            return {
                ...state,
                log: payload,
                loading: false,
            };
        default:
            return state;
    }
};
