import {
    GET_HOME_CATEGORY,
    GET_PARTNER,
    GET_PRODUCT,
    GET_RESEARCH,
    GET_TIMELINE,
} from "../types";

const initialState = {
    partner: false,
    product: false,
    timeline: false,
    research: false,
    category: false,
    loading: true,
};

export const homeReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PARTNER:
            return {
                ...state,
                partner: payload,
                loading: false,
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: payload,
                loading: false,
            };
        case GET_TIMELINE:
            return {
                ...state,
                timeline: payload,
                loading: false,
            };
        case GET_RESEARCH:
            return {
                ...state,
                research: payload,
                loading: false,
            };
        case GET_HOME_CATEGORY:
            return {
                ...state,
                category: payload,
                loading: false,
            };
        default:
            return state;
    }
};
